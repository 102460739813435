import React, { useState } from "react";
import { useFormik } from "formik";
import {
  Grid,
  Box,
  Typography,
  List,
  ListItemButton,
  ListItemText,
  ListItem,
} from "@mui/material";
import ResetPassword from "./ResetPassword";
import Profile from "./Profile";

// icons
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

const ListData = ["Profile", "Reset Password"];

export default function Settings() {
  const [selectedMenu, setSelectedMenu] = useState("Profile");

  const formik = useFormik({
    initialValues: {
      name: "",
    },
  });

  const handleSubmit = (menu) => {
    setSelectedMenu(menu);
  };

  return (
    <>
      <Grid container sx={{ height: "90%" }}>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          p={2}
          sx={{
            borderRight: "1px solid #e8e0e0",
          }}
        >
          <Box
            sx={{
              mb: 1,
            }}
          >
            <Typography
              sx={{
                fontSize: "25px",
                fontWeight: 600,
              }}
            >
              Settings
            </Typography>
            {ListData.map((itemName, i) => (
              <List
                key={i}
                sx={{ width: "100%" }}
                component="nav"
                aria-labelledby="nested-list-subheader"
              >
                <ListItem
                  selected={selectedMenu === itemName}
                  disablePadding={true}
                  sx={{
                    borderRadius: "5px",
                    "&:hover": {
                      borderRight: "3px solid #5CACFA",
                    },
                  }}
                  secondaryAction={
                    <ChevronRightIcon color="action" edge="end" />
                  }
                >
                  <ListItemButton onClick={() => handleSubmit(itemName)}>
                    <ListItemText primary={itemName} />
                  </ListItemButton>
                </ListItem>
              </List>
            ))}
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} p={2}>
          {selectedMenu === "Reset Password" ? <ResetPassword /> : null}
          {selectedMenu === "Profile" ? <Profile /> : null}
        </Grid>
      </Grid>
    </>
  );
}
