import { Box, Grid, Paper, Typography, styled } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { get } from "../../services/apiMethods";
import URLS from "../../services/urlConstants";
import WarehouseContext from "../../context/WarehouseContext";
import PieChart from "./PieChart";

const splitArrayIntoPairs = (array) => {
  const pairs = [];
  for (let i = 0; i < array.length; i += 2) {
    pairs.push([array[i], array[i + 1]]);
  }
  return pairs;
};

const Title = styled(Typography)(() => ({
  fontSize: "14px",
  fontWeight: "bold",
  textAlign: "center",
}));

const DataHeader = styled(Typography)(({}) => ({
  fontWeight: "bold",
  fontSize: "18px",
}));

const DataHeaderMain = styled(Typography)(({}) => ({
  color: "gray",
  fontSize: "16px",
}));

const DataContainer = styled(Paper)(({}) => ({
  textAlign: "center",
  padding: "20px",
  width: "95%",
  boxShadow:
    "0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)",
}));

export default function BundleSummary() {
  const { selectedOwnerCode, selectedWarehouse } = useContext(WarehouseContext);
  const [bundlesData, setBundlesData] = useState([]);

  const getBundles = async () => {
    try {
      const { data } = await get(URLS.bundle.list, {
        params: {
          search: { inStock: true },
          warehouseId: selectedWarehouse,
          ownerCode: selectedOwnerCode,
        },
      });
      let newBundles = [];
      for (let bundle of data.result) {
        if (newBundles.find((b) => b.name === bundle.bundleType.code)) {
          newBundles = newBundles.map((b) =>
            b._id === bundle.bundleType._id
              ? { ...b, count: b.count + bundle.items.length }
              : b
          );
        } else {
          let newData = {
            _id: bundle.bundleType._id,
            name: bundle.bundleType.code,
            description: bundle.bundleType.description,
            count: bundle.items.length,
          };
          newBundles.push(newData);
        }
      }
      setBundlesData(newBundles);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (selectedOwnerCode && selectedWarehouse) {
      getBundles();
    }
  }, [selectedOwnerCode, selectedWarehouse]);

  return (
    <Grid
      rowGap={2}
      sx={{
        mb: 5,
        border: "1px solid lightgray",
        borderRadius: "5px",
        padding: 2,
        background: "whitesmoke",
      }}
      container
    >
      <Grid
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        item
        xs={12}
        sm={12}
        md={5}
        lg={4}
      >
        <Box
          component={Paper}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            rowGap: "10px",
            padding: 2,
            width: 350,
          }}
        >
          <PieChart
            title="Bundle Summary"
            data={bundlesData.map((b) => ({ name: b.name, value: b.count }))}
          />
        </Box>
      </Grid>
      <Grid
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        item
        xs={12}
        sm={12}
        md={7}
        lg={8}
      >
        <Grid spacing={1} container>
          {splitArrayIntoPairs(bundlesData).map((b, i) => (
            <Grid
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              key={b._id}
              item
              xs={12}
              sm={6}
              md={4}
              lg={3}
            >
              <Grid gap={2} container>
                {b.map((d, i) => (
                  <Grid key={i} item xs={12}>
                    {d && (
                      <DataContainer>
                        <DataHeaderMain>{d.name}</DataHeaderMain>
                        <DataHeader>{d.count}</DataHeader>
                      </DataContainer>
                    )}
                  </Grid>
                ))}
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}
