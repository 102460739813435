import React, { useContext, useEffect, useState } from "react";
import TabList from "../../components/Tabs/TabList";

import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
} from "@mui/material";

import PageHeader from "../../components/PageHeader";
import CustomSelect from "../../components/SecondaryForms/CustomSelect";

import URLS from "../../services/urlConstants";
import { get, put, del } from "../../services/apiMethods";
import TabPanel from "../../components/Tabs/TabPanel";
import { unbundleTableKeys } from "../../data/TableData/unbundle";
import CustomTable from "../../components/Tables/CustomTable";
import { LoadingButton } from "@mui/lab";
import { hasAllValues } from "../../utils/utilities";
import ShowComponent from "../../components/ShowComponent";
import DashBoardPageHeaderPage from "../../components/DashBoard/DashBoardHeaderPage";
import WarehouseContext from "../../context/WarehouseContext";
import { Delete } from "@mui/icons-material";
import ConfirmationModal from "../../forms/ConfirmationModal";
import DeleteModal from "../../components/DeleteModal";

const StyledTableCell = styled(TableCell)(() => ({
  minWidth: "200px",
}));

export default function Unbundle() {
  const [formData, setFormData] = useState([]);
  const [stockType, setStockType] = useState([]);
  const [storageCode, setStorageCode] = useState([]);
  const [palletCode, setPalletCode] = useState([]);
  const [value, setSelectValue] = useState(0);
  const [data, setData] = useState([]);
  const [bundleData, setBundleData] = useState([]);
  const [showTabs, setShowTabs] = useState(false);
  const [addingSave, setAddingSave] = useState(false);
  const { selectedWarehouse, selectedOwnerCode } = useContext(WarehouseContext);
  const [deletingBundle, setDeletingBundle] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const handleCloseDeleteModal = () => setOpenDeleteModal(false);

  const [dataToEdit, setDataToEdit] = useState(null);

  const getBundleData = async () => {
    try {
      const { data } = await get(URLS.bundle.list, {
        params: {
          warehouseId: selectedWarehouse,
          ownerCode: selectedOwnerCode,
          search: { inStock: true },
        },
      });
      setBundleData(
        data.result
          ?.filter((b) => b.items.length)
          .map((b) => ({
            ...b,
            createdAt: new Date(b.createdAt).toLocaleString(),
          })) || []
      );
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    const formData = [];

    if (dataToEdit?.items?.length) {
      for (let item of dataToEdit.items) {
        formData.push({
          _id: item._id,
          msn: item.msn,
          itemDescription: item.itemDescription,
          itemCode: item.itemCode,
          newPalletCode: item.palletCode?._id,
          newStockType: item.stockType?._id,
          newStorageCode: item.storageCode?._id,
        });
      }
    }

    setFormData(formData);
  }, [dataToEdit]);

  const getData = async () => {
    try {
      const stockPromise = get(URLS.stockType.list);
      const storageCodePromise = get(URLS.storageCode.list, {
        params: {
          warehouseId: selectedWarehouse,
          ownerCode: selectedOwnerCode,
        },
      });
      const palletCodePromise = get(URLS.palletCode.list, {
        params: {
          warehouseId: selectedWarehouse,
          ownerCode: selectedOwnerCode,
        },
      });

      const [stockTypeData, storageCodeData, palletCodeData] =
        await Promise.all([
          stockPromise,
          storageCodePromise,
          palletCodePromise,
        ]);

      setStockType(
        stockTypeData?.data?.result?.map((s) => ({
          ...s,
          label: s.name,
          value: s._id,
        }))
      );

      setStorageCode(
        storageCodeData.data.result.map((s) => ({
          ...s,
          label: s.code,
          value: s._id,
        }))
      );
      setPalletCode(
        palletCodeData.data.result.map((s) => ({
          ...s,
          label: s.code,
          value: s._id,
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getData();
    getBundleData();
  }, [selectedWarehouse, selectedOwnerCode]);

  const handleTabChange = (e, newValue) => {
    setSelectValue(newValue);
    if (newValue === 0) {
      setShowTabs(false);
    }
  };

  const handleChange = (e, index) => {
    const { name, value } = e.target;

    setFormData(
      formData.map((fd, i) => (i === index ? { ...fd, [name]: value } : fd))
    );
  };

  const handleUnbundleClick = (data) => {
    setDataToEdit(data);
    setShowTabs(true);
    setSelectValue(1);
  };
  const handlePrevious = () => {
    setShowTabs(false);
    setSelectValue(0);
  };

  const handleUpdate = async (item) => {
    try {
      if (!hasAllValues(item)) return;
      setAddingSave((prv) => ({
        ...prv,
        [item._id]: true,
      }));

      let payload = {
        stockType: item.newStockType,
        palletCode: item.newPalletCode,
        storageCode: item.newStorageCode,
      };

      const { data } = await put(URLS.items.update + "/" + item._id, payload);
      getBundleData();
      // setSelectValue(0);
      // setShowTabs(false);
    } catch (error) {
      console.log(error);
    }
    setAddingSave((prv) => ({
      ...prv,
      [item._id]: false,
    }));
  };

  const handleDeleteBundle = async () => {
    setDeletingBundle(true);
    try {
      const { data } = await del(URLS.bundle.delete + "/" + dataToEdit._id);
      setOpenDeleteModal(false);
      setDeletingBundle(false);
      getBundleData();
      setSelectValue(0);
      setShowTabs(false);
    } catch (error) {
      console.log(error);
      setDeletingBundle(false);
    }
  };

  return (
    <>
      <DashBoardPageHeaderPage title="All Bundles" />

      {showTabs ? (
        <TabList
          onChange={handleTabChange}
          value={value}
          labels={["List", "View"]}
        />
      ) : (
        <TabList onChange={handleTabChange} value={value} labels={["List"]} />
      )}

      <TabPanel index={0} value={value}>
        <CustomTable
          actions={["view"]}
          bodyData={bundleData}
          bodyDataModal="unbundle"
          tableKeys={unbundleTableKeys}
          onViewClick={handleUnbundleClick}
          module="Unbundle"
        />
      </TabPanel>

      {showTabs && (
        <TabPanel index={1} value={value}>
          <TableContainer component={Paper} sx={{ marginTop: "20px" }}>
            <Table>
              <TableHead
                sx={{
                  backgroundColor: (theme) =>
                    theme.palette.mode === "dark"
                      ? theme.palette.primary.dark
                      : theme.palette.primary.light,
                }}
              >
                <TableRow>
                  <TableCell sx={{ minWidth: "80px" }} align="center">
                    S.No
                  </TableCell>
                  <StyledTableCell align="center">MSN</StyledTableCell>

                  <StyledTableCell align="center">
                    Item Description
                  </StyledTableCell>
                  <StyledTableCell align="center">Item Code</StyledTableCell>
                  <StyledTableCell align="center">
                    New Stock Type
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    New Storage code
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    New Pallet code
                  </StyledTableCell>

                  <StyledTableCell align="center">Action</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {formData.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell sx={{ minWidth: "80px" }} align="center">
                      {index + 1}
                    </TableCell>
                    <StyledTableCell align="center">{item.msn}</StyledTableCell>

                    <StyledTableCell align="center">
                      {item.itemDescription}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {item.itemCode}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <CustomSelect
                        options={stockType}
                        name="newStockType"
                        label="New Stock Type"
                        value={item.newStockType}
                        required={true}
                        onChange={(e) => handleChange(e, index)}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <CustomSelect
                        options={storageCode}
                        name="newStorageCode"
                        label="New Storage Code"
                        value={item.newStorageCode}
                        required={true}
                        onChange={(e) => handleChange(e, index)}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <CustomSelect
                        options={palletCode}
                        name="newPalletCode"
                        label="New Pallet Code"
                        value={item.newPalletCode}
                        required={true}
                        onChange={(e) => handleChange(e, index)}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <LoadingButton
                        loading={addingSave[item._id]}
                        size="small"
                        variant="contained"
                        onClick={() => handleUpdate(item)}
                      >
                        Update
                      </LoadingButton>
                    </StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              gap: "20px",
              mt: 2,
            }}
          >
            <Button
              size="small"
              onClick={handlePrevious}
              variant="contained"
              color="error"
            >
              Cancel
            </Button>
            <LoadingButton
              size="small"
              onClick={() => setOpenDeleteModal(true)}
              variant="contained"
              color="warning"
              loading={deletingBundle}
              startIcon={<Delete fontSize="small" />}
            >
              Delete
            </LoadingButton>
          </Box>
        </TabPanel>
      )}
      <DeleteModal
        isLoading={deletingBundle}
        open={openDeleteModal}
        onClose={handleCloseDeleteModal}
        onDeleteClick={handleDeleteBundle}
        description="Are you sure you want to delete this bundle!"
      />
    </>
  );
}
