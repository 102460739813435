import React, { useEffect, useState } from "react";
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
} from "@mui/material";
import CustomSelect from "../SecondaryForms/CustomSelect";
import CustomInput from "../SecondaryForms/CustomInput";
import { get } from "../../services/apiMethods";
import URLS from "../../services/urlConstants";
import PageHeader from "../PageHeader";
import { Link } from "react-router-dom";

const StyledTableCell = styled(TableCell)(() => ({
  minWidth: "200px",
}));
export default function UpdateInventory() {
  const [formData, setFormData] = useState([]);
  const [stockTypes, setStockTypes] = useState([]);
  const [families, setFamilies] = useState([]);
  const [subFamilies, setSubFamilies] = useState([]);
  const [storageCodes, setStorageCodes] = useState([]);
  const [palletCodes, setPalletCodes] = useState([]);
  const [currentConditionCodes, setCurrentConditionCodes] = useState([]);
  const [gradingScale, setGradingScale] = useState([]);
  const [packaging, setPackaging] = useState([]);
  const [brand, setBrand] = useState([]);

  useEffect(() => {
    const formData = [];
    formData.push({
      _id: "",
      stockType: "",
      msn: "",
      subFamilyName: "",
      itemDescription: "",
      itemCode: "",
      manufacturerPartNumber: "",
      brandName: "",
      storageCode: "",
      palletCode: "",
      currentConditionCode: "",
      gradingScale: "",
      packaging: "",
      isn: "",
      configurationOs: "",
      familyName: "",
      remarks: "",
      images: [],
    });

    setFormData(formData);
  }, []);

  const handleChange = (e, index, repeat) => {
    const { name, value } = e.target;
    if (repeat) {
      setFormData(
        formData.map((fd, i) => (i >= index ? { ...fd, [name]: value } : fd))
      );
    } else {
      setFormData(
        formData.map((fd, i) => (i === index ? { ...fd, [name]: value } : fd))
      );
    }
  };

  const getData = async () => {
    try {
      const stockPromise = get(URLS.stockType.list);
      const familyPromise = get(URLS.family.list);
      const subFamilyPromise = get(URLS.subFamily.list);
      const storageCodePromise = get(URLS.storageCode.list);
      const palletCodeCodePromise = get(URLS.palletCode.list);
      const currentConditionCodePromise = get(URLS.conditionCode.list);
      const gradingScalePromise = get(URLS.gradingScale.list);
      const packagingPromise = get(URLS.packaging.list);
      const brandNamePromise = get(URLS.brand.list);

      const [
        stockTypeData,
        familyData,
        subFamilyData,
        storageCodeData,
        palletCodeData,
        currentConditionCodeData,
        gradingScaleData,
        packagingData,
        brandNameData,
      ] = await Promise.all([
        stockPromise,
        familyPromise,
        subFamilyPromise,
        storageCodePromise,
        palletCodeCodePromise,
        currentConditionCodePromise,
        gradingScalePromise,
        packagingPromise,
        brandNamePromise,
      ]);
      setStockTypes(
        stockTypeData?.data?.result?.map((s) => ({
          ...s,
          label: s.name,
          value: s._id,
        }))
      );
      setFamilies(
        familyData.data.result.map((s) => ({
          ...s,
          label: s.name,
          value: s._id,
        }))
      );
      setSubFamilies(
        subFamilyData.data.result.map((s) => ({
          ...s,
          label: s.name,
          value: s._id,
        }))
      );
      setStorageCodes(
        storageCodeData.data.result.map((s) => ({
          ...s,
          label: s.code,
          value: s._id,
        }))
      );
      setPalletCodes(
        palletCodeData.data.result.map((s) => ({
          ...s,
          label: s.code,
          value: s._id,
        }))
      );
      setCurrentConditionCodes(
        currentConditionCodeData.data.result.map((s) => ({
          ...s,
          label: s.code,
          value: s._id,
        }))
      );
      setGradingScale(
        gradingScaleData.data.result.map((s) => ({
          ...s,
          label: `${s.name}-${s.description}`,
          value: s._id,
        }))
      );
      setPackaging(
        packagingData.data.result.map((s) => ({
          ...s,
          label: s.title,
          value: s._id,
        }))
      );
      setBrand(
        brandNameData.data.result.map((s) => ({
          ...s,
          label: s.name,
          value: s._id,
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <PageHeader
        title="Item Details"
        showAdd={false}
        showAddField={false}
        onFieldAdd={false}
        module="Inventory"
      />
      <TableContainer component={Paper}>
        <Table>
          <TableHead
            sx={{
              backgroundColor: (theme) =>
                theme.palette.mode === "dark"
                  ? theme.palette.primary.dark
                  : theme.palette.primary.light,
            }}
          >
            <TableRow>
              <TableCell sx={{ minWidth: "80px" }} align="center">
                S.No
              </TableCell>

              <StyledTableCell align="center">Stock Type</StyledTableCell>
              <StyledTableCell align="center">MSN</StyledTableCell>
              <StyledTableCell align="center">Sub-family name</StyledTableCell>
              <StyledTableCell align="center">Item description</StyledTableCell>
              <StyledTableCell align="center">Item code</StyledTableCell>
              <StyledTableCell align="center">
                Manufacturer part number
              </StyledTableCell>
              <StyledTableCell align="center">Brand name</StyledTableCell>
              <StyledTableCell align="center">Storage code</StyledTableCell>
              <StyledTableCell align="center">Pallet code</StyledTableCell>
              <StyledTableCell align="center">
                Current condition code
              </StyledTableCell>
              <StyledTableCell align="center">Grading Scale</StyledTableCell>
              <StyledTableCell align="center">Packaging</StyledTableCell>
              <StyledTableCell align="center">ISN</StyledTableCell>
              <StyledTableCell align="center">Configuration OS</StyledTableCell>
              <StyledTableCell align="center">Family name</StyledTableCell>
              <StyledTableCell align="center">Remarks</StyledTableCell>
              <StyledTableCell align="center">Image</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {formData.map((fd, i) => (
              <TableRow key={i}>
                <TableCell sx={{ minWidth: "80px" }} align="center">
                  {i + 1}
                </TableCell>

                <StyledTableCell align="center">
                  <CustomSelect
                    options={stockTypes}
                    name="stockType"
                    label="Stock Type"
                    value={fd.stockType}
                    required={true}
                    onChange={(e) => handleChange(e, i, "repeat")}
                  />
                </StyledTableCell>
                <StyledTableCell align="center">
                  <CustomInput
                    name="msn"
                    value={fd.msn}
                    label="MSN"
                    required={true}
                    onChange={(e) => handleChange(e, i)}
                  />
                </StyledTableCell>
                <StyledTableCell align="center">
                  <CustomSelect
                    onChange={(e) => handleChange(e, i, "repeat")}
                    options={subFamilies}
                    name="subFamilyName"
                    label="Sub-Family Name"
                    value={fd.subFamilyName}
                    required={true}
                  />
                </StyledTableCell>
                <StyledTableCell align="center">
                  <CustomInput
                    name="itemDescription"
                    value={fd.itemDescription}
                    label="Item Description"
                    required={true}
                    onChange={(e) => handleChange(e, i, "repeat")}
                  />
                </StyledTableCell>
                <StyledTableCell align="center">
                  <CustomInput
                    name="itemCode"
                    value={fd.itemCode}
                    label="Item Code"
                    required={true}
                    onChange={(e) => handleChange(e, i, "repeat")}
                  />
                </StyledTableCell>
                <StyledTableCell align="center">
                  <CustomInput
                    name="manufacturerPartNumber"
                    value={fd.manufacturerPartNumber}
                    label="Manufacturer Part Number"
                    required={true}
                    onChange={(e) => handleChange(e, i, "repeat")}
                  />
                </StyledTableCell>
                <StyledTableCell align="center">
                  <CustomSelect
                    options={brand}
                    name="brandName"
                    label="Brand Name"
                    value={fd.brandName}
                    required={true}
                    onChange={(e) => handleChange(e, i, "repeat")}
                  />
                </StyledTableCell>
                <StyledTableCell align="center">
                  <CustomSelect
                    options={storageCodes}
                    name="storageCode"
                    label="Storage Code"
                    value={fd.storageCode}
                    required={true}
                    onChange={(e) => handleChange(e, i, "repeat")}
                  />
                </StyledTableCell>
                <StyledTableCell align="center">
                  <CustomSelect
                    options={palletCodes}
                    name="palletCode"
                    label="Pallet Code"
                    value={fd.palletCode}
                    required={true}
                    onChange={(e) => handleChange(e, i, "repeat")}
                  />
                </StyledTableCell>
                <StyledTableCell align="center">
                  <CustomSelect
                    options={currentConditionCodes}
                    name="currentConditionCode"
                    label="Current Condition Code"
                    value={fd.currentConditionCode}
                    required={true}
                    onChange={(e) => handleChange(e, i, "repeat")}
                  />
                </StyledTableCell>
                <StyledTableCell align="center">
                  <CustomSelect
                    options={gradingScale}
                    name="gradingScale"
                    label="Grading Scale"
                    value={fd.gradingScale}
                    required={true}
                    onChange={(e) => handleChange(e, i, "repeat")}
                  />
                </StyledTableCell>
                <StyledTableCell align="center">
                  <CustomSelect
                    options={packaging}
                    name="packaging"
                    label="Packaging"
                    value={fd.packaging}
                    required={true}
                    onChange={(e) => handleChange(e, i, "repeat")}
                  />
                </StyledTableCell>
                <StyledTableCell align="center">
                  <CustomInput
                    name="isn"
                    value={fd.isn}
                    label="ISN"
                    required={true}
                    onChange={(e) => handleChange(e, i, "repeat")}
                  />
                </StyledTableCell>
                <StyledTableCell align="center">
                  <CustomInput
                    name="configurationOS"
                    value={fd.configurationOS}
                    label="Configuration OS"
                    // required={true}
                    onChange={(e) => handleChange(e, i, "repeat")}
                  />
                </StyledTableCell>
                <StyledTableCell align="center">
                  <CustomSelect
                    options={families}
                    name="familyName"
                    label="Family Name"
                    value={fd.familyName}
                    required={true}
                    onChange={(e) => handleChange(e, i, "repeat")}
                  />
                </StyledTableCell>
                <StyledTableCell align="center">
                  <CustomInput
                    name="remarks"
                    value={fd.remarks}
                    label="Remarks"
                    required={true}
                    onChange={(e) => handleChange(e, i, "repeat")}
                  />
                </StyledTableCell>
                <StyledTableCell align="center">
                  <CustomInput
                    type="file"
                    name="images"
                    value={fd.images}
                    label="Images"
                  />
                </StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Button variant="contained" sx={{ m: 3, position: "fixed", right: 0 }}>
        Save
      </Button>
    </>
  );
}
