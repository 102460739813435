import {
  Autocomplete,
  FormControl,
  InputLabel,
  MenuItem,
  TextField,
} from "@mui/material";
import ThemeModeContext from "../../context/ThemeModeContext";
import React, { memo, useContext } from "react";

function CustomAutoComplete({
  value,
  name = "",
  label = "",
  options = [],
  onChange = () => {},
  disabled = false,
  required = false,
}) {
  console.log(options, "options");
  const { isDarkMode } = useContext(ThemeModeContext);
  const labelColor =
    disabled && !isDarkMode
      ? "gray"
      : !disabled && isDarkMode
      ? "white"
      : !disabled && !isDarkMode
      ? "black"
      : disabled && isDarkMode
      ? "gray"
      : "inherit";

  return (
    <Autocomplete
      disabled={disabled}
      options={options}
      sx={{
        ":MuiInputBase-input": {
          height: "30px",
        },
      }}
      value={{ label: value || "", value: value || "" }}
      onChange={(event, newValue) => {
        console.log(newValue, "new value");
        onChange({
          target: {
            name: name,
            value: newValue?.value,
          },
        });
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          inputProps={{
            ...params.inputProps,
            autoComplete: "new-password", // disable autocomplete and autofill
          }}
        />
      )}
      getOptionLabel={(option) => option.label}
    />
  );
}

export default memo(CustomAutoComplete);
