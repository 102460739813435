export const inboundEntriesTableKeys = [
  {
    name: "Reference Code",
    key: "gmsReferenceCode",
  },
  {
    name: "Received Date",
    key: "receivedDate",
    isDate: true,
  },

  {
    name: "Incoming Date",
    key: "incomingDate",
    isDate: true,
  },

  {
    name: "Warehouse code",
    path: "warehouse",
    key: "code",
  },
];
