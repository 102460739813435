import React, { useContext } from "react";
import { Box, Button, Grid, Paper, Typography, styled } from "@mui/material";

import { useFormik } from "formik";

// img
import ManBoxes from "../assets/images/login.Img/ManBoxes.png";
import logo from "../assets/images/gmslogo.png";
import ThemeModeContext from "../context/ThemeModeContext";
import UserContext from "../context/UserContext";
import { Link, useNavigate } from "react-router-dom";
import FormInput from "../forms/FormInput";
import { post } from "../services/apiMethods";
import URLS from "../services/urlConstants";
import { jwtDecode } from "jwt-decode";

const LeftComponent = styled(Grid)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const RightComponent = styled(Grid)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const Item = styled(Paper)(() => ({
  display: "flex",
  textAlign: "center",
  justifyContent: "center",
  alignItems: "center",
}));

const MuiForm = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
});

const MuiButton = styled(Button)({
  borderRadius: "8px",
  marginTop: "20px",
  padding: "15px",
  width: "90%",
});

export default function Login() {
  const { setUser } = useContext(UserContext);
  const { isDarkMode } = useContext(ThemeModeContext);
  const navigate = useNavigate();

  const handleLogin = async (values) => {
    try {
      const { data } = await post(URLS.auth.login, values);
      const decodedToken = jwtDecode(data.result.access_token);

      setUser(data.result.user);
      window.localStorage.setItem(
        "current_inventory_user",
        JSON.stringify(data.result.user)
      );

      if (decodedToken.data.userType === "employee") {
        const role = data.result.user.role;
        window.localStorage.setItem("role", JSON.stringify(role));
      }

      window.localStorage.setItem("access_token", data.result.access_token);
      navigate("/dashboard");
    } catch (error) {
      console.log(error);
    }
  };

  const formik = useFormik({
    initialValues: {
      userName: "",
      password: "",
    },
    onSubmit: async (values) => {
      handleLogin(values);
    },
    validateOnBlur: false,
  });

  return (
    <>
      <Grid container>
        <LeftComponent
          item
          xs={12}
          md={6}
          sx={{
            display: { xs: "none", md: "flex" },
            height: "100vh",
            backgroundColor: isDarkMode ? "white" : "black",
          }}
        >
          <img src={ManBoxes} alt="loading..." />
        </LeftComponent>

        <RightComponent
          item
          xs={12}
          md={6}
          sx={{
            height: "100vh",
            backgroundColor: isDarkMode ? "black" : "white",
          }}
        >
          <Item
            elevation={2}
            sx={{
              width: "100%",
              maxWidth: "500px",
              marginRight: "15px",
              marginLeft: "15px",
              justifyContent: "center",
            }}
          >
            <MuiForm
              component="form"
              onSubmit={formik.handleSubmit}
              sx={{
                maxWidth: { xs: "100%", sm: 450, md: 460, lg: 650 },
                width: "100%",
                padding: { xs: "50px", md: "60px" },
              }}
            >
              <img
                src={logo}
                alt="loading.."
                width={250}
                style={{ marginBottom: "20px", objectFit: "contain" }}
              />

              <FormInput
                formik={formik}
                name="userName"
                label="Username"
                type="text"
                required={true}
              />
              <FormInput
                formik={formik}
                name="password"
                label="Password"
                type="password"
                required={true}
              />

              {/* <Link to={"/forgot-password"}>
                <Typography sx={{ textDecoration: "none", mt: 1 }}>
                  Forgot Password?
                </Typography>
              </Link> */}
              <MuiButton type="submit" variant="contained">
                Login
              </MuiButton>
            </MuiForm>
          </Item>
        </RightComponent>
      </Grid>
    </>
  );
}
