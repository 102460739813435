import {
  Box,
  Grid,
  IconButton,
  Paper,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { get } from "../../services/apiMethods";
import URLS from "../../services/urlConstants";
import WarehouseContext from "../../context/WarehouseContext";
import PieChart from "./PieChart";
import FormDatePicker from "../../forms/FormDatePicker";
import { useFormik } from "formik";
import { Restore } from "@mui/icons-material";

function separateAtCapital(word) {
  // Initialize an empty array to store separated parts
  const separatedParts = [];

  // Initialize variables to keep track of the start and end indices of the current word
  let startIndex = 0;

  // Iterate through each character of the word
  for (let i = 1; i < word.length; i++) {
    // Check if the current character is a capital letter
    if (word[i] === word[i].toUpperCase()) {
      // If it's a capital letter, extract the substring from startIndex to the current index
      separatedParts.push(word.substring(startIndex, i));
      // Update the startIndex to the current index for the next word
      startIndex = i;
    }
  }

  // Add the remaining part of the word after the last capital letter
  separatedParts.push(word.substring(startIndex));

  // Join the separated parts with a space and return the result
  return separatedParts.join(" ");
}

const Title = styled(Typography)(() => ({
  fontSize: "14px",
  fontWeight: "bold",
  textAlign: "center",
}));

const DataHeader = styled(Typography)(({}) => ({
  fontWeight: "bold",
  fontSize: "18px",
}));

const DataHeaderMain = styled(Typography)(({}) => ({
  color: "gray",
  fontSize: "16px",
}));

const DataContainer = styled(Paper)(({}) => ({
  textAlign: "center",
  padding: "20px",
  width: "95%",
  boxShadow:
    "0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)",
}));

export default function CurrentDateDetails() {
  const { selectedOwnerCode, selectedWarehouse } = useContext(WarehouseContext);
  const [stats, setStats] = useState([]);

  const getData = async () => {
    try {
      const filter = {
        ownerCode: selectedOwnerCode,
        warehouseId: selectedWarehouse,
      };
      if (formik.values.selectedDate) {
        filter["selectedDate"] = formik.values.selectedDate;
      }
      const { data } = await get(URLS.items.getDashboardSelectedDateStats, {
        params: filter,
      });

      setStats(
        Object.keys(data.result).map((k) => ({
          name: separateAtCapital(k).toUpperCase(),
          value: data.result[k],
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  const formik = useFormik({
    initialValues: {
      selectedDate: null,
    },
  });

  useEffect(() => {
    if (selectedOwnerCode && selectedWarehouse) {
      getData();
    }
  }, [formik.values.selectedDate, selectedOwnerCode, selectedWarehouse]);

  return (
    <Grid
      rowGap={2}
      sx={{
        mb: 5,
        border: "1px solid lightgray",
        borderRadius: "5px",
        padding: 2,
        background: "lightgray",
      }}
      container
    >
      <Grid
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        item
        xs={12}
        sm={12}
        md={5}
        lg={4}
      >
        <Box
          component={Paper}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            rowGap: "10px",
            padding: 2,
            width: 350,
          }}
        >
          <Stack spacing={1} direction="row">
            <FormDatePicker
              formik={formik}
              label="Select date"
              name="selectedDate"
            />
            <IconButton onClick={() => formik.resetForm()} size="small">
              <Restore fontSize="small" />
            </IconButton>
          </Stack>

          <PieChart
            title="Current Stats"
            data={stats.map((b) => ({ name: b.name, value: b.value }))}
          />
        </Box>
      </Grid>
      <Grid
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        item
        xs={12}
        sm={12}
        md={7}
        lg={8}
      >
        <Grid spacing={1} container>
          {stats.map((b, i) => (
            <Grid
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              key={b.name}
              item
              xs={12}
              sm={6}
              md={4}
              lg={3}
            >
              <DataContainer>
                <DataHeaderMain>{b.name}</DataHeaderMain>
                <DataHeader>{b.value}</DataHeader>
              </DataContainer>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}
