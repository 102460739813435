import React, { useState } from "react";
import { useFormik } from "formik";
import { Grid, Box, Paper, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import FormInput from "../forms/FormInput";
import { styled } from "@mui/system";
import { post } from "../services/apiMethods";
import URLS from "../services/urlConstants";

const MuiBox = styled(Box)({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignContent: "center",
  alignItems: "center",
  alignSelf: "center",
});

export default function ResetPassword() {
  const [loading, setLoading] = useState(false);

  const handleUpdatePassword = async (values) => {
    try {
      setLoading(true);
      const { data } = await post(URLS.auth.updatePassword, values);
      setLoading(false);
      formik.resetForm();
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    onSubmit: handleUpdatePassword,
  });

  return (
    <>
      <MuiBox>
        <Paper
          component="form"
          onSubmit={formik.handleSubmit}
          sx={{
            width: "100%",
            p: 3,
            flexWrap: "wrap",
          }}
        >
          <Grid container rowSpacing={1} columnSpacing={2}>
            <Typography
              sx={{
                margin: "auto",
                fontSize: "25px",
                fontWeight: 700,
                textAlign: "center",
              }}
            >
              Reset Password
            </Typography>
            <Grid item xs={12} sm={12} md={12} lg={12} mt={2}>
              <FormInput
                required={true}
                label="Old Password"
                formik={formik}
                name="oldPassword"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} mt={2}>
              <FormInput
                required={true}
                label="New Password"
                formik={formik}
                name="newPassword"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} mt={2}>
              <FormInput
                required={true}
                label="Confirm Password"
                formik={formik}
                name="confirmPassword"
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              display="flex"
              sx={{ justifyContent: "flex-end" }}
              mt={2}
            >
              <LoadingButton
                variant="contained"
                size="small"
                type="submit"
                loading={loading}
              >
                Submit
              </LoadingButton>
            </Grid>
          </Grid>
        </Paper>
      </MuiBox>
    </>
  );
}
