import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { Grid } from "@mui/material";

// custom imports
import PageHeader from "../../components/PageHeader";
import CustomTable from "../../components/Tables/CustomTable";
import FormDialog from "../../forms/FormDialog";
import CustomInput from "../../components/SecondaryForms/CustomInput";
import FormInput from "../../forms/FormInput";

// services
import URLS from "../../services/urlConstants";
import { get, put, post, del } from "../../services/apiMethods";

// data
import { conditionCodeKeys } from "../../data/TableData/conditionCode";

export default function ConditionCode() {
  const [open, setOpen] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [dataToEdit, setDataToEdit] = useState(null);
  const [data, setData] = useState([]);
  const [formFields, setFormFields] = useState(null);
  const [loading, setLoading] = useState(false);

  const getData = async () => {
    try {
      const { data } = await get(URLS.conditionCode.list);
      setData(data.result);
    } catch (error) {
      console.log(error);
    }
    setTimeout(() => {
      setIsReady(true);
    }, 300);
  };

  const getFormDetails = async () => {
    try {
      const { data } = await get(URLS.forms.read, {
        params: { collectionName: "conditionCode" },
      });
      setFormFields(data.result ? data.result.fields : null);
    } catch (error) {
      console.log(error);
    }
  };

  // get data on page load
  useEffect(() => {
    getData();
    getFormDetails();
  }, []);

  const handleOnFieldAdd = () => {
    getData();
    getFormDetails();
  };

  // create || update actions
  const handleCreateOrUpdate = async (values) => {
    try {
      const payload = {
        ...values,
        meta: formFields,
      };
      setLoading(true);

      if (dataToEdit) {
        const { data } = await put(
          URLS.conditionCode.update + "/" + dataToEdit._id,
          payload
        );
      } else {
        const { data } = await post(URLS.conditionCode.create, payload);
      }
      getData();
      handleModalClose();
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
    setLoading(false);
  };

  const formik = useFormik({
    initialValues: {
      code: dataToEdit?.code || "",
      description: dataToEdit?.description || "",
    },
    onSubmit: (values) => handleCreateOrUpdate(values),
    enableReinitialize: true,
  });

  // modal actionss
  const openDataAddOrUpdateModal = () => setOpen(true);

  const handleModalClose = () => {
    setOpen(false);
    setTimeout(() => {
      setDataToEdit(null);
      setFormFields(
        formFields?.map((f) => ({
          ...f,
          value: "",
        }))
      );
      formik.resetForm();
    }, 200);
  };

  // edit data actions
  const handleCustomInputChange = (e) => {
    const { name, value } = e.target;
    setFormFields(
      formFields.map((f) => (f.inputLabel === name ? { ...f, value } : f))
    );
  };

  const handleSetDataToEdit = (data) => {
    setDataToEdit(data);
    const fields = data?.meta;
    setFormFields(
      formFields?.map((f) => ({
        ...f,
        value:
          fields?.find((fi) => fi.inputLabel === f.inputLabel)?.value || "",
      }))
    );
    setOpen(true);
  };

  const handleDelete = async (id) => {
    try {
      const response = await del(URLS.conditionCode.delete + "/" + id);
      const filtered = data.filter((item) => item._id !== id);
      setData(filtered);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <PageHeader
        collection="Condition Code"
        onAddClick={openDataAddOrUpdateModal}
        title="Condtion Code"
        modelName="conditionCode"
        onFieldAdd={handleOnFieldAdd}
        module="Condition Code"
      />

      <CustomTable
        actions={["edit", "delete"]}
        bodyDataModal="condition code"
        isDataReady={isReady}
        bodyData={data}
        tableKeys={conditionCodeKeys}
        onEditClick={handleSetDataToEdit}
        onDeleteClick={handleDelete}
        module="Condition Code"
        formFields={formFields}
      />

      <FormDialog
        open={open}
        onClose={handleModalClose}
        submitButtonTitle={dataToEdit ? "Update" : "Add"}
        formik={formik}
        formTitle={dataToEdit ? "Update condition code" : "Add condition code"}
        adding={loading}
      >
        <Grid container rowSpacing={1} columnSpacing={2}>
          <Grid item xs={12} sm={6}>
            <FormInput
              required={true}
              name="code"
              type="text"
              formik={formik}
              label="Code"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormInput
              required={true}
              name="description"
              type="text"
              formik={formik}
              label="Description"
            />
          </Grid>
          {formFields
            ? formFields?.map((f, i) => (
                <Grid key={i} item xs={12} sm={6}>
                  <CustomInput
                    onChange={handleCustomInputChange}
                    name={f.inputLabel}
                    value={f.value}
                    required={f.isRequired}
                    label={f.inputLabel}
                    type={f.inputType}
                  />
                </Grid>
              ))
            : null}
        </Grid>
      </FormDialog>
    </>
  );
}
