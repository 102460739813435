import { Stack } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import CustomSelect from "./SecondaryForms/CustomSelect";
import { get } from "../services/apiMethods";
import URLS from "../services/urlConstants";
import { LoadingButton } from "@mui/lab";
import { Download } from "@mui/icons-material";
import { downloadExcel } from "../utils/utilities";
import WarehouseContext from "../context/WarehouseContext";

export default function ItemHistoryReport() {
  const { selectedWarehouse, selectedOwnerCode } = useContext(WarehouseContext);
  const [downloading, setDownloading] = useState(false);
  const [msnList, setMsnList] = useState([]);
  const [allMsns, setAllMsns] = useState([]);

  const getAllItemHistory = async () => {
    try {
      const { data } = await get(URLS.itemHistory.list, {
        params: {
          warehouseId: selectedWarehouse,
          ownerCode: selectedOwnerCode,
        },
      });
      let initialValues = data.result.map((d) => ({
        label: d.msn,
        value: d.msn,
      }));
      let finalList = [];
      for (let val of initialValues) {
        if (!finalList.find((l) => l.value === val.value)) {
          finalList.push(val);
        }
      }
      setAllMsns(finalList);
    } catch (error) {
      console.log(error);
    }
  };

  const handleMsnChange = (e) => {
    setMsnList(e.target.value);
  };

  useEffect(() => {
    getAllItemHistory();
  }, [selectedOwnerCode, selectedWarehouse]);

  const handleDownloadHistory = async () => {
    try {
      if (!msnList.length) return;
      setDownloading(true);
      const { data } = await get(URLS.items.donwloadMultipleItemHistory, {
        params: {
          msnList,
          warehouseId: selectedWarehouse,
          ownerCode: selectedOwnerCode,
        },
        responseType: "blob",
      });
      setDownloading(false);

      downloadExcel(data, `Item-history.xlsx`);
    } catch (error) {
      console.log(error);
      setDownloading(false);
    }
  };

  return (
    <Stack
      component="form"
      direction="row"
      spacing={2}
      sx={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        maxWidth: "500px",
        marginBottom: "20px",
      }}
    >
      <CustomSelect
        onChange={handleMsnChange}
        label="MSN"
        value={msnList}
        required={true}
        multiple={true}
        options={[{ label: "All", value: "all" }, ...allMsns]}
      />
      {msnList.length ? (
        <LoadingButton
          fullWidth
          sx={{ maxWidth: "150px" }}
          loading={downloading}
          size="small"
          onClick={handleDownloadHistory}
          variant="contained"
          startIcon={<Download fontSize="small" />}
        >
          History
        </LoadingButton>
      ) : null}
    </Stack>
  );
}
