export const packagingTableKeys = [
  {
    name: "Title",
    key: "title",
  },

  {
    name: "Description",
    key: "description",
  },
];
