export const itemCategoryTableKeys = [
  {
    name: "Name",
    key: "name",
  },
  {
    name: "Description",
    key: "description",
  },
];
