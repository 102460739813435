import { Box, Grid } from "@mui/material";
import React, { useEffect, useState, useContext } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";

// icons
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { get } from "../services/apiMethods";
import URLS from "../services/urlConstants";
import { LoadingButton } from "@mui/lab";
import { downloadExcel, separateAtCapital } from "../utils/utilities";
import ShowComponent from "../components/ShowComponent";
import WarehouseContext from "../context/WarehouseContext";
import DashBoardPageHeaderPage from "../components/DashBoard/DashBoardHeaderPage";
import ShipmentReport from "../components/ShipmentReport";
import ItemHistoryReport from "../components/ItemHistoryReport";
const REPORTS = ["Storage", "Shipment", "Item History"];

export default function Reports() {
  const [selectedMenu, setSelectedMenu] = useState("Storage");
  const [itemKeys, setItemKeys] = useState([]);
  const { selectedWarehouse, selectedOwnerCode } = useContext(WarehouseContext);

  const handleToggle = (key) => {
    setItemKeys(
      itemKeys.map((i) => (i.key === key ? { ...i, checked: !i.checked } : i))
    );
  };

  const getItemKeys = async () => {
    try {
      const { data } = await get(URLS.items.getKeys);
      const itemKeys = data.result.itemKeys.map((i) => ({
        key: i,
        checked: true,
        keyOf: "item",
      }));
      const inboundKeys = data.result.inboundKeys.map((i) => ({
        key: i,
        checked: true,
        keyOf: "inbound",
      }));
      console.log(data, "data");
      setItemKeys([...itemKeys, ...inboundKeys]);
    } catch (error) {}
  };

  useEffect(() => {
    getItemKeys();
  }, []);

  const handleSubmit = (menu) => {
    setSelectedMenu(menu);
  };

  const handleDownloadReport = async () => {
    try {
      const { data } = await get(URLS.items.downloadSelectFields, {
        params: {
          ...itemKeys,
          warehouseId: selectedWarehouse,
          ownerCode: selectedOwnerCode,
        },
        responseType: "blob",
      });
      downloadExcel(data, "Storage_Report");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <DashBoardPageHeaderPage title="Reports" />
      <Grid container sx={{ height: "90%" }}>
        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          lg={4}
          p={2}
          sx={{
            borderRight: "1px solid #e8e0e0",
          }}
        >
          <Box
            sx={{
              mb: 1,
            }}
          >
            {REPORTS.map((itemName, i) => (
              <List
                key={i}
                sx={{ width: "100%" }}
                component="nav"
                aria-labelledby="nested-list-subheader"
              >
                <ListItem
                  selected={selectedMenu === itemName}
                  disablePadding={true}
                  sx={{
                    borderRadius: "5px",
                    "&:hover": {
                      borderRight: "3px solid #5CACFA",
                    },
                  }}
                  secondaryAction={
                    <ChevronRightIcon color="action" edge="end" />
                  }
                >
                  <ListItemButton onClick={() => handleSubmit(itemName)}>
                    <ListItemText primary={itemName} />
                  </ListItemButton>
                </ListItem>
              </List>
            ))}
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={8} lg={8} p={2}>
          {selectedMenu === "Storage" ? (
            <Box>
              <List
                sx={{
                  width: "100%",
                  // maxWidth: 360,
                  bgcolor: "background.paper",
                }}
              >
                <Grid container>
                  {itemKeys.map((value, index) => {
                    const labelId = `checkbox-list-label-${value.key}`;

                    return (
                      <Grid
                        key={value.key + index.toString()}
                        item
                        xs={12}
                        sm={6}
                      >
                        <ListItem disablePadding>
                          <ListItemButton
                            role={undefined}
                            onClick={() => handleToggle(value.key)}
                            // dense
                          >
                            {/* <ListItemIcon> */}
                            <Checkbox
                              edge="start"
                              checked={value.checked}
                              tabIndex={-1}
                              disableRipple
                              inputProps={{ "aria-labelledby": labelId }}
                            />
                            {/* </ListItemIcon> */}
                            <ListItemText
                              id={labelId}
                              primary={`${separateAtCapital(value.key || "")
                                .toUpperCase()
                                .replace(" ", "_")}`}
                            />
                          </ListItemButton>
                        </ListItem>
                      </Grid>
                    );
                  })}
                </Grid>
              </List>

              {itemKeys.length ? (
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  <ShowComponent module={"Reports"} action={"view"}>
                    {" "}
                    <LoadingButton
                      onClick={handleDownloadReport}
                      variant="contained"
                      size="small"
                    >
                      Download Excel
                    </LoadingButton>
                  </ShowComponent>
                </Box>
              ) : null}
            </Box>
          ) : null}

          {selectedMenu === "Shipment" ? <ShipmentReport /> : null}
          {selectedMenu === "Item History" ? <ItemHistoryReport /> : null}
        </Grid>
      </Grid>
    </>
  );
}
