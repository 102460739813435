import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
// icons
import { Close, Delete, Download } from "@mui/icons-material";
import { post, put } from "../services/apiMethods";
import URLS from "../services/urlConstants";

const MuiBox = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  // border: "1px solid lightgray",
  borderRadius: "5px",
  padding: "10px",
  marginBottom: "20px",
  alignItems: "center",
  background: " #5cacfa42",
}));

export default function UploadFiles({
  open,
  actions = ["edit", "delete", "view"],
  onClose,
  fullScreen,
  styles = {},
  docId = "",
  filesList = [],
  onUpdate = () => {},
}) {
  //const [files, setFiles] = useState(filesList);
  const [files, setFiles] = useState([]);

  useEffect(() => {
    setFiles(filesList);
  }, [filesList]);

  const handleRemoveImage = async (file) => {
    try {
      const { data } = await put(URLS.inboundEntry.removeFile + "/" + docId, {
        file,
      });
      const updatedFiles = files.filter((f) => f !== file);
      onUpdate();
      setFiles(updatedFiles);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: "100%",
          maxWidth: 550,
          ...styles,
        },
      }}
    >
      <DialogTitle>Uploaded files</DialogTitle>
      <Divider />
      <DialogContent
        sx={{
          padding: "10px",
        }}
      >
        {files.map((f, i) => (
          <MuiBox key={i}>
            <Typography>{f.split("/")[f.split("/").length - 1]}</Typography>
            <Stack direction="row" spacing={2}>
              {actions.includes("delete") && (
                <Tooltip title="delete">
                  <IconButton
                    size="small"
                    color="error"
                    onClick={() => handleRemoveImage(f)}
                  >
                    <Delete fontSize="small" />
                  </IconButton>
                </Tooltip>
              )}

              <Tooltip title="Download">
                <IconButton
                  size="small"
                  onClick={() => window.open(f, "_blank")}
                >
                  <Download fontSize="small" />
                </IconButton>
              </Tooltip>
            </Stack>
          </MuiBox>
        ))}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          size="small"
          color="error"
          variant="contained"
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
