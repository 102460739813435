export const unbundleTableKeys = [
  {
    name: "Bundle Reference code",
    key: "bundleReferenceNumber",
  },
  {
    name: "Added On",
    key: "createdAt",
  },
  {
    name: "Bundle Type",
    path: "bundleType",
    key: "code",
  },
  {
    name: "Stock Type",
    path: "stockType",
    key: "name",
  },
  {
    name: "Pallet Code",
    path: "palletCode",
    key: "code",
  },
  {
    name: "Storage Code",
    path: "storageCode",
    key: "code",
  },
];
