import { toast } from "react-toastify";
import { saveAs } from "file-saver";

export function downloadExcel(data, filename) {
  // Convert the buffer data to a Blob
  const blob = new Blob([data], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });

  // Save the Blob as a file using FileSaver.js
  saveAs(blob, filename);
}

const generatedNumbers = new Set();

export function generateRandomNumber(digits) {
  if (digits <= 0) {
    console.error("Number of digits must be greater than zero");
    return null;
  }

  let min = Math.pow(10, digits - 1);
  let max = Math.pow(10, digits) - 1;

  let randomNumber;
  do {
    randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
  } while (generatedNumbers.has(randomNumber));

  generatedNumbers.add(randomNumber);
  return randomNumber;
}

export function capitalizeFirstLetter(word) {
  return word?.charAt(0).toUpperCase() + word?.slice(1);
}

export function hasAllValues(obj, exceptions = []) {
  let allValuesPresent = true;
  for (const key in obj) {
    if (!exceptions.includes(key) && !obj[key]) {
      allValuesPresent = false;
      toast.error(`Value for "${key}" is missing!`);
    }
  }
  return allValuesPresent;
}

export function getRandomSubset(array, number) {
  // Shuffle the array to randomize the elements
  // const shuffledArray = array.sort(() => Math.random() - 0.5);

  // Return the first 'number' elements of the shuffled array
  return array.slice(0, number);
}

export function separateAtCapital(word) {
  // Initialize an empty array to store separated parts
  const separatedParts = [];

  // Initialize variables to keep track of the start and end indices of the current word
  let startIndex = 0;

  // Iterate through each character of the word
  for (let i = 1; i < word.length; i++) {
    // Check if the current character is a capital letter
    if (word[i] === word[i].toUpperCase()) {
      // If it's a capital letter, extract the substring from startIndex to the current index
      separatedParts.push(word.substring(startIndex, i));
      // Update the startIndex to the current index for the next word
      startIndex = i;
    }
  }

  // Add the remaining part of the word after the last capital letter
  separatedParts.push(word.substring(startIndex));

  // Join the separated parts with a space and return the result
  return separatedParts.join(" ");
}

export function hasDuplicates(array) {
  return new Set(array).size !== array.length;
}

export function multiplyStringNumbers(str) {
  // Split the string by asterisks to get an array of numbers
  const numbers = str.split("*").map(Number);

  // Initialize the result to 1 for multiplication
  let result = 1;

  // Multiply each number in the array
  for (let num of numbers) {
    // Check if the number is valid (not NaN)
    if (!isNaN(num)) {
      result *= num;
    }
  }

  // Return the result of multiplication
  return result;
}

// export function convertToISOString(dateString) {
//   // Split the date string into date and time components
//   const [datePart, timePart] = dateString.split(", ");
//   const [day, month, year] = datePart.split("/").map(Number);
//   const [hours, minutes, seconds] = timePart.split(":").map(Number);

//   // Construct a Date object using the components
//   const dateObject = new Date(year, month - 1, day, hours, minutes, seconds);

//   // Convert the Date object to an ISO string
//   const isoString = dateObject.toISOString();

//   return isoString;
// }

export function convertToISOString(dateString) {
  const [datePart, timePart] = dateString.split(", ");
  const [day, month, year] = datePart.split("/").map(Number);

  // Remove AM/PM part from the time string
  const cleanTimePart = timePart.replace(/\s[AaPp][Mm]$/, "");
  const [hours, minutes, seconds] = cleanTimePart.split(":").map(Number);

  // Construct a Date object using the components
  const dateObject = new Date(year, month - 1, day, hours, minutes, seconds);

  // Convert the Date object to an ISO string
  const isoString = dateObject.toISOString();

  return isoString;
}

export function hasDatePartChanged(dateString1, dateString2) {
  // Split the date strings to extract the date part
  const datePart1 = dateString1.split(", ")[0];
  const datePart2 = dateString2.split(", ")[0];

  // Compare the date parts
  return datePart1 !== datePart2;
}
