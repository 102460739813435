export const ROLE_PERMISSIONS = [
  {
    module: "Roles and Permission",
    subModule: [
      {
        module: "Roles and Permission",
        permissions: [],
      },
    ],
  },
  {
    module: "Inbound Process",
    subModule: [
      {
        module: "Inbound process",
        permissions: [],
      },
    ],
  },

  {
    module: "Inventory",
    subModule: [
      {
        module: "Inventory",
        permissions: [],
      },
    ],
  },

  {
    module: "Item History",
    subModule: [
      {
        module: "Item History",
        permissions: [],
      },
    ],
  },

  {
    module: "Master Data",
    subModule: [
      { module: "Employee", permissions: [] },
      // { module: "Item", permissions: [] },
      { module: "Warehouse", permissions: [] },
      { module: "Stock Type", permissions: [] },
      { module: "Item Category", permissions: [] },
      { module: "Storage code", permissions: [] },
      { module: "Pallet code", permissions: [] },
      { module: "Brand", permissions: [] },
      { module: "Condition Code", permissions: [] },
      { module: "Damage code", permissions: [] },
      { module: "Workflow code", permissions: [] },
      { module: "Activity code", permissions: [] },
      { module: "Grading scale", permissions: [] },
      { module: "Owner code", permissions: [] },
      { module: "Owner group", permissions: [] },
      { module: "Family", permissions: [] },
      { module: "Sub-family", permissions: [] },
      { module: "Courier mode", permissions: [] },
      { module: "Courier partner", permissions: [] },
      { module: "Packaging", permissions: [] },
      { module: "Update status", permissions: [] },
      { module: "Custom Fields", permissions: [] },
    ],
  },
  {
    module: "Bundling",
    subModule: [
      { module: "Bundle Type", permissions: [] },
      { module: "Bundle", permissions: [] },
      { module: "Unbundle", permissions: [] },
    ],
  },
  {
    module: "Outbound Process",
    subModule: [
      { module: "Pick-list", permissions: [] },
      { module: "Outbound", permissions: [] },
    ],
  },
  {
    module: "Shipment",
    subModule: [{ module: "Shipment", permissions: [] }],
  },
  {
    module: "Reports",
    subModule: [{ module: "Reports", permissions: [] }],
  },
];
