import React, { useContext, useEffect, useState } from "react";
import DashBoardPageHeaderPage from "../components/DashBoard/DashBoardHeaderPage";
import {
  Stack,
  Table,
  styled,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Paper,
  TableRow,
  Typography,
  TablePagination,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { get } from "../services/apiMethods";
import URLS from "../services/urlConstants";
import WarehouseContext from "../context/WarehouseContext";
import CustomSelect from "../components/SecondaryForms/CustomSelect";
import ViewOrUpdateItemFiles from "../components/ViewOrUpdateItemFiles";
import { toast } from "react-toastify";
import UpdateFaultyStock from "../components/Inventory/UpdateFaultyStock";
import { downloadExcel } from "../utils/utilities";
import { Download } from "@mui/icons-material";

const Value = styled(Typography)(() => ({
  fontWeight: "bold",
  fontSize: "14px",
}));

const StyledTableCell = styled(TableCell)(() => ({
  minWidth: "200px",
}));

export default function ItemHistory() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const { selectedWarehouse, selectedOwnerCode } = useContext(WarehouseContext);
  const [msn, setMsn] = useState("");
  const [allMsns, setAllMsns] = useState([]);
  const [gettingHistory, setGettingHistory] = useState(false);
  const [formData, setFormData] = useState([]);
  const [itemFormFields, setItemFormFields] = useState(null);
  const [downloading, setDownloading] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getItemFormDetails = async () => {
    try {
      const { data } = await get(URLS.forms.read, {
        params: { collectionName: "item" },
      });
      setItemFormFields(data.result ? data.result.fields : null);
    } catch (error) {
      console.log(error);
    }
  };

  const handleMsnChange = (e) => {
    setMsn(e.target.value);
  };

  const handleFindHistory = async (e) => {
    if (e) {
      e.preventDefault();
    }
    try {
      setGettingHistory(true);
      const { data } = await get(URLS.itemHistory.list, {
        params: { search: { msn } },
        warehouseId: selectedWarehouse,
        ownerCode: selectedOwnerCode,
      });
      setGettingHistory(false);

      if (!data.result?.length) {
        setFormData([]);
        return toast.error("No history found for given item");
      }

      const formData = [];
      for (let item of data.result) {
        formData.push({
          itemCategory: item.entryMenu,
          receivedAt: new Date(item.receivedAt).toLocaleString(),
          stockType: item.stockType?.name || "NA",
          msn: item.msn || "NA",
          subFamily: item.subFamily?.name || "NA",
          itemDescription: item.itemDescription || "NA",
          itemCode: item.itemCode || "NA",
          manufacturerPartNumber: item.manufacturerPartNumber || "NA",
          brandName: item.brandName?.name || "NA",
          storageCode: item.storageCode?.code || "NA",
          activityCode: item.activityCode?.code || "NA",
          palletCode: item.palletCode?.code || "NA",
          conditionCode: item.conditionCode?.code || "NA",
          gradingScale:
            `${item.gradingScale?.name}-${item.gradingScale?.description}` ||
            "NA",
          damageCode:
            `${item.damageCode?.code}-${item.damageCode?.description}` || "NA",
          isn: item.isn || "NA",
          packaging: item.packaging?.title || "NA",
          configurationOs: item.configurationOs || "NA",
          family: item.family?.name || "NA",
          meta: itemFormFields?.map((f) => ({
            ...f,
            value:
              item.meta?.find((fi) => fi.inputLabel === f.inputLabel)?.value ||
              "",
          })),
          remarks: item.remarks || "NA",
          images: item.images,
          _id: item._id,
        });
      }
      setFormData(formData);
    } catch (error) {
      console.log(error);
      setGettingHistory(false);
    }
  };

  useEffect(() => {
    if (msn) {
      handleFindHistory();
    }
  }, [msn]);

  // const getAllItemHistories = async() => {
  //   try {
  //     const {data} = await get(URLS.itemHistory.list, { params: { warehouseId:  selectedWarehouse}});
  //   } catch (error) {

  //   }
  // }

  const getAllItems = async () => {
    try {
      const { data } = await get(URLS.items.list, {
        params: {
          warehouseId: selectedWarehouse,
          ownerCode: selectedOwnerCode,
        },
      });

      setAllMsns(
        data.result
          .filter((i) => i.itemCategory.name.toLowerCase() === "faulty")
          .map((d) => ({ label: d.msn, value: d.msn }))
      );
    } catch (error) {}
  };

  useEffect(() => {
    setFormData([]);
    getAllItems();
    getItemFormDetails();
  }, [selectedWarehouse, selectedOwnerCode]);

  const handleDownloadHistory = async () => {
    try {
      if (!msn) return;
      setDownloading(true);
      const { data } = await get(URLS.items.donwloadItemHistory, {
        params: { msn },
        responseType: "blob",
      });
      setDownloading(false);

      downloadExcel(data, `Item-history-${msn}.xlsx`);
    } catch (error) {
      console.log(error);
      setDownloading(false);
    }
  };

  return (
    <>
      <DashBoardPageHeaderPage title="Items History" />

      <Stack
        component="form"
        onSubmit={handleFindHistory}
        direction="row"
        spacing={2}
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          maxWidth: "400px",
          marginBottom: "20px",
        }}
      >
        <CustomSelect
          onChange={handleMsnChange}
          label="MSN"
          value={msn}
          required={true}
          options={allMsns}
        />
        {msn ? (
          <LoadingButton
            fullWidth
            sx={{ maxWidth: "150px" }}
            loading={downloading}
            size="small"
            onClick={handleDownloadHistory}
            variant="contained"
            startIcon={<Download fontSize="small" />}
          >
            History
          </LoadingButton>
        ) : null}
      </Stack>

      {formData.length ? (
        <UpdateFaultyStock
          showBack={false}
          onItemUpdate={handleFindHistory}
          msn={msn}
          showSearch={false}
        />
      ) : null}

      {formData.length ? (
        <TableContainer sx={{ mt: 10 }} component={Paper}>
          <Table>
            <TableHead
              sx={{
                backgroundColor: (theme) =>
                  theme.palette.mode === "dark"
                    ? theme.palette.primary.dark
                    : theme.palette.primary.light,
              }}
            >
              <TableRow>
                <TableCell align="center" sx={{ minWidth: "80px" }}>
                  S.No
                </TableCell>
                <StyledTableCell align="center">
                  Received Category
                </StyledTableCell>
                <StyledTableCell align="center">
                  Last Updated On
                </StyledTableCell>
                <StyledTableCell align="center">Stock Type</StyledTableCell>
                <StyledTableCell align="center">MSN</StyledTableCell>
                <StyledTableCell align="center">
                  Sub-family name
                </StyledTableCell>
                <StyledTableCell align="center">
                  Item description
                </StyledTableCell>
                <StyledTableCell align="center">Item code</StyledTableCell>
                <StyledTableCell align="center">
                  Manufacturer part number
                </StyledTableCell>
                <StyledTableCell align="center">Brand name</StyledTableCell>
                <StyledTableCell align="center">Storage code</StyledTableCell>
                <StyledTableCell align="center">Pallet code</StyledTableCell>
                <StyledTableCell align="center">
                  Current condition code
                </StyledTableCell>
                <StyledTableCell align="center">Grading Scale</StyledTableCell>
                <StyledTableCell align="center">Activity Code</StyledTableCell>
                <StyledTableCell align="center">Damage code</StyledTableCell>
                <StyledTableCell align="center">ISN</StyledTableCell>
                <StyledTableCell align="center">Packaging</StyledTableCell>
                <StyledTableCell align="center">
                  Configuration OS
                </StyledTableCell>
                <StyledTableCell align="center">Family name</StyledTableCell>
                {itemFormFields?.map((i, index) => (
                  <StyledTableCell align="center" key={index}>
                    {i.inputLabel}
                  </StyledTableCell>
                ))}
                <StyledTableCell align="center">Remarks</StyledTableCell>
                <TableCell align="center">Image</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {formData.map((fd, i) => (
                <TableRow key={i}>
                  <TableCell align="center" sx={{ minWidth: "80px" }}>
                    {i + 1}
                  </TableCell>
                  <StyledTableCell align="center">
                    <Value>{fd.itemCategory}</Value>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <Value>{fd.receivedAt}</Value>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <Value>{fd.stockType}</Value>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <Value>{fd.msn}</Value>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <Value>{fd.subFamily}</Value>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <Value>{fd.itemDescription}</Value>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <Value>{fd.itemCode}</Value>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <Value>{fd.manufacturerPartNumber}</Value>
                  </StyledTableCell>

                  <StyledTableCell align="center">
                    <Value>{fd.brandName}</Value>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <Value>{fd.storageCode}</Value>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <Value>{fd.palletCode}</Value>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <Value>{fd.conditionCode}</Value>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <Value>{fd.gradingScale}</Value>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <Value>{fd.activityCode}</Value>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <Value>{fd.damageCode}</Value>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <Value>{fd.isn}</Value>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <Value>{fd.packaging}</Value>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <Value>{fd.configurationOs}</Value>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <Value>{fd.family}</Value>
                  </StyledTableCell>
                  {itemFormFields
                    ? fd.meta?.map((f, index) => (
                        <StyledTableCell align="center" key={index}>
                          <Value>{f.value}</Value>
                        </StyledTableCell>
                      ))
                    : null}
                  <StyledTableCell align="center">
                    <Value>{fd.remarks}</Value>
                  </StyledTableCell>
                  <StyledTableCell sx={{ minWidth: "80px" }} align="center">
                    <ViewOrUpdateItemFiles
                      key={fd._id}
                      onlyView={true}
                      item={fd}
                    />
                  </StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : null}
      {formData.length ? (
        <TablePagination
          fullWidth
          component="div"
          count={formData ? (formData.length ? formData.length : 0) : 0}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      ) : null}
    </>
  );
}
