import React from "react";
import { Box, Button, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Lottie from "react-lottie";
import animationData from "../assets/Lottie/PageNotFound.json";

function PageNotFound() {
  const navigate = useNavigate();
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <>
      <Grid container>
        <Grid
          item
          xs={12}
          sx={{
            alignItems: "center",
            display: "flex",
            flex: 1,
            justifyContent: "center",
            flexDirection: "column",
            mt: 3,
          }}
        >
          <Box
            sx={{
              width: "300px",
              height: "300px",
              backgroundColor: "#fff",
            }}
          >
            <Lottie options={defaultOptions} />
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mt: 3,
          }}
        >
          <Button onClick={() => navigate(-1)} size="medium" variant="outlined">
            Go back
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

export default PageNotFound;
