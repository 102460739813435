import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { Grid } from "@mui/material";

// custom imports
import PageHeader from "../../components/PageHeader";
import FormDialog from "../../forms/FormDialog";
import FormInput from "../../forms/FormInput";
import CustomInput from "../../components/SecondaryForms/CustomInput";
import CustomTable from "../../components/Tables/CustomTable";

// services
import URLS from "../../services/urlConstants";
import { del, get, post, put } from "../../services/apiMethods";

// data
import { itemCategoryTableKeys } from "../../data/TableData/itemCategory";

export default function ItemCategory() {
  const [isReady, setIsReady] = useState(false);
  const [open, setOpen] = useState(false);
  const [dataToEdit, setDataToEdit] = useState(null);
  const [data, setData] = useState([]);
  const [formFields, setFormFields] = useState(null);
  const [loading, setLoading] = useState(false);

  const getData = async () => {
    try {
      const { data } = await get(URLS.itemCategory.list);
      setData(data.result);
    } catch (error) {
      console.log(error);
    }
    setTimeout(() => {
      setIsReady(true);
    }, 300);
  };
  const getFormDetails = async () => {
    try {
      const { data } = await get(URLS.forms.read, {
        params: { collectionName: "itemCategory" },
      });
      setFormFields(data.result ? data.result.fields : null);
    } catch (error) {
      console.log(error);
    }
  };

  // get data on page load
  useEffect(() => {
    getData();
    getFormDetails();
  }, []);

  const handleOnFieldAdd = () => {
    getData();
    getFormDetails();
  };

  // create || update actions
  const handleCreateOrUpdate = async (values) => {
    try {
      const payload = {
        ...values,
        meta: formFields,
      };

      setLoading(true);
      if (dataToEdit) {
        const { data } = await put(
          URLS.itemCategory.update + "/" + dataToEdit._id,
          payload
        );
      } else {
        const { data } = await post(URLS.itemCategory.create, payload);
      }

      getData();
      handleModalClose();
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const formik = useFormik({
    initialValues: {
      name: dataToEdit?.name || "",
      description: dataToEdit?.description || "",
    },
    onSubmit: (values) => handleCreateOrUpdate(values),
    enableReinitialize: true,
  });

  // edit data actions
  const handleCustomInputChange = (e) => {
    const { name, value } = e.target;
    setFormFields(
      formFields?.map((f) => (f.inputLabel === name ? { ...f, value } : f))
    );
  };
  const handleSetDataToEdit = (category) => {
    setDataToEdit(category);
    const fields = category?.meta;
    setFormFields(
      formFields?.map((f) => ({
        ...f,
        value:
          fields?.find((fi) => fi.inputLabel === f.inputLabel)?.value || "",
      }))
    );

    setOpen(true);
  };

  // modal actions
  const openDataAddOrUpdateModal = () => setOpen(true);

  const handleModalClose = () => {
    setOpen(false);
    setTimeout(() => {
      setDataToEdit(null);
      setFormFields(
        formFields?.map((f) => ({
          ...f,
          value: "",
        }))
      );
      formik.resetForm();
    }, 200);
  };

  const handleDelete = async (id) => {
    try {
      const response = await del(URLS.itemCategory.delete + "/" + id);
      const filtered = data.filter((item) => item._id !== id);
      setData(filtered);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <PageHeader
        title="Item Category"
        collection="Item Category"
        modelName="itemCategory"
        onAddClick={openDataAddOrUpdateModal}
        onFieldAdd={handleOnFieldAdd}
        module="Item Category"
      />
      <CustomTable
        actions={["edit", "delete"]}
        bodyDataModal="item category"
        bodyData={data}
        tableKeys={itemCategoryTableKeys}
        onEditClick={handleSetDataToEdit}
        onDeleteClick={handleDelete}
        isDataReady={isReady}
        module="Item Category"
        formFields={formFields}
      />
      <FormDialog
        adding={loading}
        open={open}
        onClose={handleModalClose}
        submitButtonTitle={dataToEdit ? "Update" : "Add"}
        formik={formik}
        formTitle={dataToEdit ? "Update item category" : "Add item category"}
      >
        <Grid container rowSpacing={1} columnSpacing={2}>
          <Grid item xs={12} sm={6}>
            <FormInput
              required={true}
              name="name"
              type="text"
              formik={formik}
              label="Name"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormInput
              required={true}
              name="description"
              type="text"
              formik={formik}
              label="Description"
            />
          </Grid>

          {formFields
            ? formFields?.map((f, i) => (
                <Grid key={i} item xs={12} sm={6}>
                  <CustomInput
                    onChange={handleCustomInputChange}
                    name={f.inputLabel}
                    value={f.value}
                    required={f.isRequired}
                    label={f.inputLabel}
                    type={f.inputType}
                  />
                </Grid>
              ))
            : null}
        </Grid>
      </FormDialog>
    </>
  );
}
