import {
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  styled,
  tableCellClasses,
} from "@mui/material";
import React, { useContext } from "react";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import ModeEditOutlineRoundedIcon from "@mui/icons-material/ModeEditOutlineRounded";
import ShowComponent from "../ShowComponent";
import { Link } from "react-router-dom";
import URLS from "../../services/urlConstants";
import { get } from "../../services/apiMethods";
import { downloadExcel } from "../../utils/utilities";
import { useNavigate } from "react-router-dom";
import WarehouseContext from "../../context/WarehouseContext";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: 13,
  },
  [`&.${tableCellClasses.head}`]: {
    fontSize: 14,
    fontWeight: "bold",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const DATA = [
  {
    _id: "1",
    name: "DAAS",
    description: "1 headphone and 1 headset",
    count: 10,
  },
  {
    _id: "2",
    name: "WAAS",
    description: "1 headphone and 1 headset",
    count: 20,
  },
];

function sumValues(data) {
  let sum = data.reduce((total, current) => total + current.count, 0);

  return sum;
}

export default function BundledStock({ data, warehouseId }) {
  const { selectedOwnerCode } = useContext(WarehouseContext);
  const navigate = useNavigate();

  const handleDownloadReport = async (bundleType) => {
    try {
      let filter = {};
      if (bundleType) {
        filter["bundleType"] = bundleType;
      }
      const { data } = await get(URLS.items.donwloadBundledStock, {
        params: { ...filter, warehouseId, ownerCode: selectedOwnerCode },
        responseType: "blob",
      });
      downloadExcel(data, "BUNDLED_STOCK.xlsx");
    } catch (error) {
      console.log(error);
    }
  };

  const handleEdit = async (k) => {
    try {
      navigate(`/edit/bundle/stock`, { state: { itemData: k } });
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead
            sx={{
              backgroundColor: (theme) =>
                theme.palette.mode === "dark"
                  ? theme.palette.primary.dark
                  : theme.palette.primary.light,
            }}
          >
            <TableRow>
              <StyledTableCell>Bundled Stock Inventory</StyledTableCell>
              <StyledTableCell></StyledTableCell>
              <StyledTableCell></StyledTableCell>
              <StyledTableCell></StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell align="center">Bundle Name</StyledTableCell>
              <StyledTableCell align="center">
                Bundle Description
              </StyledTableCell>
              <StyledTableCell align="center">Quantity</StyledTableCell>
              <StyledTableCell align="center">Action</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((d, k) => (
              <StyledTableRow key={d._id}>
                <StyledTableCell align="center">{d.name}</StyledTableCell>
                <StyledTableCell align="center">
                  {d.description}
                </StyledTableCell>
                <StyledTableCell align="center">{d.count}</StyledTableCell>
                <StyledTableCell align="center">
                  <ShowComponent action={"view"} module="Inventory">
                    <IconButton
                      onClick={() => handleDownloadReport(d._id)}
                      disabled={!d.count}
                    >
                      <DownloadRoundedIcon fontSize="small" />
                    </IconButton>
                  </ShowComponent>
                  <ShowComponent action={"update"} module="Inventory">
                    <IconButton size="small" onClick={() => handleEdit(d._id)}>
                      <ModeEditOutlineRoundedIcon fontSize="small" />
                    </IconButton>
                  </ShowComponent>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
          <TableFooter>
            <StyledTableRow>
              <StyledTableCell sx={{ fontWeight: "bold" }}>
                Total bundled stock
              </StyledTableCell>
              <StyledTableCell></StyledTableCell>
              <StyledTableCell></StyledTableCell>

              <StyledTableCell
                sx={{ fontWeight: "bold", paddingRight: 2 }}
                align="right"
              >
                {sumValues(data)}
              </StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell sx={{ fontWeight: "bold" }}>
                Download total bundled stock
              </StyledTableCell>
              <StyledTableCell></StyledTableCell>
              <StyledTableCell></StyledTableCell>

              <StyledTableCell sx={{ fontWeight: "bold" }} align="right">
                <ShowComponent action={"view"} module="Inventory">
                  <IconButton
                    disabled={!sumValues(data)}
                    onClick={() => handleDownloadReport()}
                  >
                    <DownloadRoundedIcon fontSize="small" />
                  </IconButton>
                </ShowComponent>
              </StyledTableCell>
            </StyledTableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </div>
  );
}
