import React, { useContext, useEffect, useState } from "react";
import { useFormik } from "formik";
import { Grid } from "@mui/material";

// custom imports
import CustomInput from "../../components/SecondaryForms/CustomInput";
import FormInput from "../../forms/FormInput";
import FormDialog from "../../forms/FormDialog";
import FormSelect from "../../forms/FormSelect";
import CustomTable from "../../components/Tables/CustomTable";
import PageHeader from "../../components/PageHeader";

// services
import { del, get, post, put } from "../../services/apiMethods";
import URLS from "../../services/urlConstants";

// data
import { employeeTableKeys } from "../../data/TableData/employee";
import WarehouseContext from "../../context/WarehouseContext";
import { toast } from "react-toastify";

export default function Employee() {
  const { selectedWarehouse } = useContext(WarehouseContext);
  const [isReady, setIsReady] = useState(false);
  const [open, setOpen] = useState(false);
  const [dataToEdit, setDataToEdit] = useState(null);
  const [data, setData] = useState([]);
  const [formFields, setFormFields] = useState(null);
  const [loading, setLoading] = useState(false);
  const [roles, setRoles] = useState([]);
  const [ownerCodes, setOwnerCodes] = useState([]);
  const [warehouses, setWarehouses] = useState([]);

  const getOwnerCodes = async () => {
    try {
      const { data } = await get(URLS.ownerCode.list);
      setOwnerCodes(
        data.result.map((d) => ({ ...d, label: d.name, value: d._id }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  const getWarehouses = async () => {
    try {
      const { data } = await get(URLS.warehouse.list);
      setWarehouses(
        data.result.map((d) => ({ ...d, label: d.code, value: d._id }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  const getData = async () => {
    try {
      const { data } = await get(URLS.employee.list);
      setData(data.result);
    } catch (error) {
      console.log(error);
    }
    setTimeout(() => {
      setIsReady(true);
    }, 300);
  };

  const getFormDetails = async () => {
    try {
      const { data } = await get(URLS.forms.read, {
        params: { collectionName: "employee" },
      });
      setFormFields(data.result ? data.result.fields : null);
    } catch (error) {
      console.log(error);
    }
  };

  // get data on page load
  useEffect(() => {
    getData();
    getOwnerCodes();
    getFormDetails();
    getWarehouses();
  }, []);

  const handleOnFieldAdd = () => {
    getData();
    getFormDetails();
  };

  // create || update actions
  const handleCreateOrUpdate = async (values) => {
    try {
      const payload = {
        ...values,
        meta: formFields,
      };

      if (payload.customerGroup.length > 1) {
        return toast.error(
          "For now only one customer can be assigned to a single employee!"
        );
      }

      setLoading(true);
      if (dataToEdit) {
        const { data } = await put(
          URLS.employee.update + "/" + dataToEdit._id,
          payload
        );
      } else {
        const { data } = await post(URLS.employee.create, payload);
      }

      getData();
      handleModalClose();
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const formik = useFormik({
    initialValues: {
      name: dataToEdit?.name || "",
      contactNumber: dataToEdit?.contactNumber || "",
      email: dataToEdit?.email || "",
      username: dataToEdit?.username || "",
      password: dataToEdit?.password || "",
      role: dataToEdit?.role?._id || "",
      address: dataToEdit?.address || "",
      customerGroup: dataToEdit?.customerGroup?.map((c) => c._id) || [],
      warehouse: dataToEdit?.warehouse?._id || "",
    },
    onSubmit: handleCreateOrUpdate,
    enableReinitialize: true,
  });

  const getRolesList = async () => {
    try {
      const { data } = await get(URLS.roles.list);
      setRoles(data.result.map((r) => ({ label: r.name, value: r._id })));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getRolesList();
  }, []);

  // modal actions
  const openDataAddOrUpdateModal = () => setOpen(true);

  const handleModalClose = () => {
    setOpen(false);
    setTimeout(() => {
      setDataToEdit(null);
      setFormFields(
        formFields?.map((f) => ({
          ...f,
          value: "",
        }))
      );
      formik.resetForm();
    }, 200);
  };

  // edit data actions
  const handleCustomInputChange = (e) => {
    const { name, value } = e.target;
    setFormFields(
      formFields.map((f) => (f.inputLabel === name ? { ...f, value } : f))
    );
  };

  const handleSetDataToEdit = (data) => {
    setDataToEdit(data);
    const fields = data?.meta;
    setFormFields(
      formFields?.map((f) => ({
        ...f,
        value:
          fields?.find((fi) => fi.inputLabel === f.inputLabel)?.value || "",
      }))
    );

    setOpen(true);
  };

  const handleDelete = async (id) => {
    try {
      const res = await del(URLS.employee.delete + "/" + id);
      const filtered = data.filter((item) => item._id !== id);
      setData(filtered);
    } catch (error) {
      console.error(error);
    }
  };

  const handleToggleActiveStatus = async (employee) => {
    try {
      const { data } = await put(
        URLS.employee.toggleActiveStatus + "/" + employee._id
      );
      getData();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <PageHeader
        collection="Employee"
        onAddClick={openDataAddOrUpdateModal}
        title="Employee"
        modelName="employee"
        onFieldAdd={handleOnFieldAdd}
        module="Employee"
      />
      <CustomTable
        actions={["edit", "switch"]}
        bodyDataModal="employee"
        bodyData={data}
        tableKeys={employeeTableKeys}
        onDeleteClick={handleDelete}
        onEditClick={handleSetDataToEdit}
        isDataReady={isReady}
        module="Employee"
        formFields={formFields}
        onToggleSwitch={handleToggleActiveStatus}
      />
      <FormDialog
        open={open}
        adding={loading}
        onClose={handleModalClose}
        submitButtonTitle={dataToEdit ? "Update" : "Add"}
        formik={formik}
        formTitle={dataToEdit ? "Update Employee" : "Add Employee"}
      >
        <Grid container rowSpacing={1} columnSpacing={2}>
          <Grid item xs={12} sm={6}>
            <FormInput
              required={true}
              name="name"
              type="text"
              formik={formik}
              label="Name"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormInput
              required={true}
              name="contactNumber"
              type="number"
              formik={formik}
              label="Contact No."
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormInput
              required={true}
              name="email"
              type="email"
              formik={formik}
              label="Email"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormInput
              required={true}
              name="username"
              type="text"
              formik={formik}
              label="Username"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormInput
              required={true}
              name="password"
              type="text"
              formik={formik}
              label="Password"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormInput
              required={false}
              name="address"
              type="text"
              formik={formik}
              label="Address"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormSelect
              required={true}
              name="role"
              formik={formik}
              label="Select Role"
              options={roles}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormSelect
              disabled={dataToEdit?.warehouse?._id ? true : false}
              required={true}
              name="warehouse"
              formik={formik}
              label="Select warehouse"
              options={warehouses}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormSelect
              multiple={true}
              required={true}
              name="customerGroup"
              formik={formik}
              label="Select Customer groups"
              options={ownerCodes}
            />
          </Grid>
          {/* <Grid item xs={12} sm={6}>
            <FormInput
              required={true}
              name="id_card"
              type="file"
              formik={formik}
              label="Id Card"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormInput
              required={false}
              name="image"
              type="file"
              formik={formik}
              label="Profile Image"
            />
          </Grid> */}
          {formFields
            ? formFields?.map((f, i) => (
                <Grid key={i} item xs={12} sm={6}>
                  <CustomInput
                    onChange={handleCustomInputChange}
                    name={f.inputLabel}
                    value={f.value}
                    required={f.isRequired}
                    label={f.inputLabel}
                    type={f.inputType}
                  />
                </Grid>
              ))
            : null}
        </Grid>
      </FormDialog>
    </>
  );
}
