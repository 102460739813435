import {
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  styled,
  tableCellClasses,
} from "@mui/material";
import React, { useContext } from "react";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import ModeEditOutlineRoundedIcon from "@mui/icons-material/ModeEditOutlineRounded";
import { Link, useNavigate } from "react-router-dom";
import URLS from "../../services/urlConstants";
import { get } from "../../services/apiMethods";
import { downloadExcel } from "../../utils/utilities";
import ShowComponent from "../ShowComponent";
import WarehouseContext from "../../context/WarehouseContext";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: 13,
  },
  [`&.${tableCellClasses.head}`]: {
    fontSize: 14,
    fontWeight: "bold",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const DATA = {
  Assets: [
    { subFamilyId: "1", subFamilyName: "Laptop", count: 100 },
    { subFamilyId: "2", subFamilyName: "Desktop", count: 100 },
    { subFamilyId: "3", subFamilyName: "Mobile", count: 100 },
  ],

  Accessories: [
    { subFamilyId: "1", subFamilyName: "Mouse", count: 100 },
    { subFamilyId: "2", subFamilyName: "Keyboard", count: 100 },
    { subFamilyId: "3", subFamilyName: "Headphone", count: 100 },
  ],
};

function sumValues(data) {
  let sum = 0;

  for (const category in data) {
    data[category].forEach((item) => {
      sum += item.count;
    });
  }

  return sum;
}

export default function ShippedStock({ data, warehouseId }) {
  const { selectedOwnerCode } = useContext(WarehouseContext);
  const navigate = useNavigate();
  const handleEdit = async (id, k) => {
    try {
      navigate(`/edit/saved/stock/${id}`, { state: { itemData: k } });
    } catch (error) {
      console.log(error);
    }
  };

  const handleDownloadReport = async (key) => {
    try {
      let filter = {};
      if (key) {
        if (key.isBundle) {
          filter["bundleType"] = key.subFamilyId;
        }
        if (!key.isBundle) {
          filter["subFamily"] = key.subFamilyId;
        }
      }

      const { data } = await get(URLS.outbound.downloadShippedStock, {
        params: { ...filter, warehouseId, ownerCode: selectedOwnerCode },
        responseType: "blob",
      });
      downloadExcel(data, "Shipped_Stock.xlsx");
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead
            sx={{
              backgroundColor: (theme) =>
                theme.palette.mode === "dark"
                  ? theme.palette.primary.dark
                  : theme.palette.primary.light,
            }}
          >
            <StyledTableCell>Shipped Stock</StyledTableCell>
            <StyledTableCell></StyledTableCell>
          </TableHead>
          <TableBody>
            {Object.keys(data).map((key, index) => (
              <TableRow key={key}>
                <StyledTableCell
                  sx={{ borderRight: "1px solid lightgray" }}
                  align="center"
                >
                  {key}
                </StyledTableCell>
                <StyledTableCell>
                  <TableContainer>
                    <Table>
                      <TableHead
                        sx={{
                          backgroundColor: (theme) =>
                            theme.palette.mode === "dark"
                              ? theme.palette.primary.dark
                              : theme.palette.primary.light,
                        }}
                      >
                        <StyledTableCell align="center">
                          Description
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          Quantity
                        </StyledTableCell>
                        <StyledTableCell align="center">Action</StyledTableCell>
                      </TableHead>
                      <TableBody>
                        {data[key].map((k, i) => (
                          <StyledTableRow key={k.subFamilyId}>
                            <StyledTableCell
                              sx={{ border: "none" }}
                              align="center"
                            >
                              {k.subFamilyName}
                            </StyledTableCell>
                            <StyledTableCell
                              sx={{ border: "none" }}
                              align="center"
                            >
                              {k.count}
                            </StyledTableCell>
                            <StyledTableCell
                              sx={{ border: "none" }}
                              align="center"
                            >
                              <ShowComponent action={"view"} module="Inventory">
                                <IconButton
                                  onClick={() => handleDownloadReport(k)}
                                  disabled={!k.count}
                                >
                                  <DownloadRoundedIcon fontSize="small" />
                                </IconButton>
                              </ShowComponent>
                              {/* <ShowComponent
                                  action={"update"}
                                  module="Inventory"
                                >
                                  <IconButton
                                    disabled={!k.count}
                                    onClick={() => handleEdit(k.subFamilyId, k)}
                                  >
                                    <ModeEditOutlineRoundedIcon fontSize="small" />
                                  </IconButton>
                                </ShowComponent> */}
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <StyledTableRow>
              <StyledTableCell sx={{ fontWeight: "bold" }}>
                Total shipped stock
              </StyledTableCell>
              <StyledTableCell
                sx={{ fontWeight: "bold", paddingRight: 2 }}
                align="right"
              >
                {sumValues(data)}
              </StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell sx={{ fontWeight: "bold" }}>
                Download total shipped stock
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: "bold" }} align="right">
                <ShowComponent action={"view"} module="Inventory">
                  <IconButton
                    onClick={() => handleDownloadReport()}
                    disabled={!sumValues(data)}
                  >
                    <DownloadRoundedIcon fontSize="small" />
                  </IconButton>
                </ShowComponent>
              </StyledTableCell>
            </StyledTableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </div>
  );
}
