import { Box, Paper, Typography } from "@mui/material";
import { PieChart, Pie, Cell, Legend, Tooltip } from "recharts";
import React from "react";

const DEFAULT_DATA = [
  { name: "lap", value: 10 },
  { name: "phone", value: 10 },
  { name: "tv", value: 10 },
  { name: "mouse", value: 10 },
];
const COLORS = [
  "#0088FE",
  "#00C49F",
  "#FFBB28",
  "#FF8042",
  "#2aa87e",
  "#1a2f75",
  "#5a0775",
  "#752a07",
];

export default function CustomPieChart({ title = "", data = [] }) {
  return (
    <Box p={2}>
      <Typography component="h1" sx={{ fontWeight: "bold", textAlign: "left" }}>
        {title}
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",

          width: "100%",
        }}
      >
        {data.length ? (
          <PieChart width={250} height={250}>
            <Pie
              data={data.map((i) => ({ ...i, name: i.name.toUpperCase() }))}
              cx="50%"
              cy="50%"
              innerRadius={30}
              outerRadius={50}
              fill="#8884d8"
              dataKey="value"
              // label={({ name }) => name}
            >
              {data
                .map((i) => ({ ...i, name: i.name.toUpperCase() }))
                .map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
            </Pie>
            <Tooltip />

            <Legend
              wrapperStyle={{ textAlign: "center" }}
              formatter={(value) => value}
            />
          </PieChart>
        ) : (
          <Typography>No items found!</Typography>
        )}
      </Box>
    </Box>
  );
}
