import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import {
  Box,
  Grid,
  Table,
  styled,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Paper,
  TableRow,
  Button,
  Typography,
  IconButton,
  TextField,
  InputAdornment,
  TablePagination,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import dayjs from "dayjs";

// custom imports
import ViewOrUpdateItemFiles from "../../components/ViewOrUpdateItemFiles";
import ViewOrUpdateInboundEntryFiles from "../../components/ViewOrUpdateInboundEntryFiles";
import FormInput from "../../forms/FormInput";
import FormDatePicker from "../../forms/FormDatePicker";
import FormSelect from "../../forms/FormSelect";
import CustomInput from "../../components/SecondaryForms/CustomInput";
import CustomSelect from "../../components/SecondaryForms/CustomSelect";

// services
import { get, put } from "../../services/apiMethods";
import URLS from "../../services/urlConstants";

import { Search } from "@mui/icons-material";
import { convertToISOString, hasAllValues } from "../../utils/utilities";
import { toast } from "react-toastify";

const StyledTableCell = styled(TableCell)(() => ({
  minWidth: "200px",
}));

const MuiPaper = styled(Paper)(() => ({
  padding: "20px",
}));

export default function UpdateFaultyItem({
  dataToEdit,
  onUpdate = () => {},
  setSelectValue = () => {},
  setEntryToEdit = () => {},
  stockTypes = [],
  families = [],
  subFamilies = [],
  storageCodes = [],
  palletCodes = [],
  currentConditionCodes = [],
  ownerData = [],
  warehouseData = [],
  workFlowData = [],
  brandData = [],
  itemCategory = [],
  damageCode = [],
  activityCodes = [],
  packaging = [],
}) {
  const [addingEntry, setAddingEntry] = useState(false);
  const [addingSave, setAddingSave] = useState(false);
  const [formData, setFormData] = useState([]);
  const [formFields, setFormFields] = useState(null);
  const [itemFormFields, setItemFormFields] = useState(null);
  const [searchFilter, setSearchFilter] = useState([]);
  const [search, setSearch] = useState("");
  const [page, setPage] = React.useState(0);

  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getItemFormDetails = async () => {
    try {
      const { data } = await get(URLS.forms.read, {
        params: { collectionName: "item" },
      });
      setItemFormFields(data.result ? data.result.fields : null);
    } catch (error) {
      console.log(error);
    }
  };

  const getFormDetails = async () => {
    try {
      const { data } = await get(URLS.forms.read, {
        params: { collectionName: "inboundEntry" },
      });

      setFormFields(
        data.result
          ? data.result.fields.map((f) => ({
              ...f,
              value:
                dataToEdit.meta?.find((m) => m.inputLabel === f.inputLabel)
                  ?.value || "",
            }))
          : null
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (dataToEdit) {
      getItemFormDetails();
      getFormDetails();
    }
  }, [dataToEdit]);

  const handleSubmit = async (values) => {
    try {
      setAddingEntry(true);
      const receivedDate = dayjs(new Date(values.receivedDate)).toISOString();

      const date = new Date(receivedDate);
      if (!dataToEdit.receivedDate) {
        const currentTime = new Date();

        // Set the time part of the date to the current time
        date.setHours(currentTime.getHours());
        date.setMinutes(currentTime.getMinutes());
        date.setSeconds(currentTime.getSeconds());
      }
      const payload = {
        ...values,
        meta: formFields,
        receivedDate: values.receivedDate ? date : null,
      };

      delete payload.incomingDate;

      const { data } = await put(
        URLS.inboundEntry.update + "/" + dataToEdit._id,
        payload
      );

      setSelectValue(1);
      onUpdate();
      entryFormik.resetForm();
    } catch (error) {
      console.log(error);
    }
    setAddingEntry(false);
  };

  const handleUpdate = async (id, fd, updateHistory) => {
    for (let item of [fd]) {
      if (!hasAllValues(item, ["images", "meta", "remarks", "configurationOs"]))
        return;
    }

    if ([fd].some((fd) => fd.meta.some((f) => f.isRequired && !f.value)))
      return toast.error("Please fill required fields of this item");
    try {
      setAddingSave((prv) => ({
        ...prv,
        [id]: true,
      }));
      const payload = {
        ...fd,
        entryId: dataToEdit._id,
        updateHistory: updateHistory || null,
      };
      const { data } = await put(URLS.items.update + "/" + id, payload);
      let item = data.result;
      setFormData(
        formData.map((fd) =>
          fd._id !== item._id
            ? fd
            : {
                _id: item._id,
                itemCategory: item.itemCategory._id,
                stockType: item.stockType._id,
                msn: item.msn,
                subFamily: item.subFamily._id,
                itemDescription: item.itemDescription,
                itemCode: item.itemCode,
                manufacturerPartNumber: item.manufacturerPartNumber,
                brandName: item.brandName._id,
                storageCode: item.storageCode._id,
                palletCode: item.palletCode._id,
                conditionCode: item.conditionCode._id,
                damageCode: item.damageCode._id,
                activityCode: item.activityCode._id,
                isn: item.isn,
                packaging: item.packaging._id,
                configurationOs: item.configurationOs,
                family: item.family._id,

                meta: itemFormFields?.map((f) => ({
                  ...f,
                  value:
                    item.meta?.find((fi) => fi.inputLabel === f.inputLabel)
                      ?.value || "",
                })),
                remarks: item.remarks,
                images: item.images,
              }
        )
      );
      onUpdate();
    } catch (error) {
      console.log(error);
    }
    setAddingSave((prv) => ({
      ...prv,
      [id]: false,
    }));
  };

  const entryFormik = useFormik({
    initialValues: {
      gmsReferenceCode: dataToEdit.gmsReferenceCode,
      entryMenu: dataToEdit.entryMenu,
      receivedDate: dataToEdit
        ? dataToEdit.receivedDate
          ? dayjs(dataToEdit.receivedDate)
          : null
        : null,
      incomingDate: dataToEdit
        ? dataToEdit.incomingDate
          ? dayjs(dataToEdit.incomingDate)
          : null
        : null,
      warehouseCode: dataToEdit ? dataToEdit.warehouse._id : "",
      warehouseName: dataToEdit ? dataToEdit.warehouse.name : "",

      receivedDocketNumber: dataToEdit ? dataToEdit.receivedDocketNumber : "",
      eWayBillNumber: dataToEdit ? dataToEdit.eWayBillNumber : "",
      incomingInvoiceNumber: dataToEdit ? dataToEdit.incomingInvoiceNumber : "",
      dcNumber: dataToEdit.dcNumber,
      poNumber: dataToEdit.poNumber,
      numberOfBoxes: dataToEdit ? dataToEdit.numberOfBoxes : "",
      sku: dataToEdit ? dataToEdit.sku : formData.length,
      ownerGroup: dataToEdit ? dataToEdit.ownerGroup._id : "",
      ownerCode: dataToEdit ? dataToEdit.ownerCode._id : "",
      ownerCategory: dataToEdit ? dataToEdit.ownerCategory : "",
      workflowCode: dataToEdit ? dataToEdit.workflowCode._id : "",
      remarks: dataToEdit ? dataToEdit.remarks : "",
    },
    onSubmit: (values) => handleSubmit(values),
    enableReinitialize: true,
    validateOnBlur: false,
  });

  // modified  items table
  useEffect(() => {
    const formData = [];

    if (dataToEdit?.items?.length) {
      for (let item of dataToEdit.items) {
        formData.push({
          _id: item._id,
          itemCategory: item.itemCategory,
          stockType: item.stockType,
          msn: item.msn,
          subFamily: item.subFamily,
          itemDescription: item.itemDescription,
          itemCode: item.itemCode,
          manufacturerPartNumber: item.manufacturerPartNumber,
          brandName: item.brandName,
          storageCode: item.storageCode,
          palletCode: item.palletCode,
          conditionCode: item.conditionCode,
          damageCode: item.damageCode,
          activityCode: item.activityCode,
          isn: item.isn,
          packaging: item.packaging,
          configurationOs: item.configurationOs,
          family: item.family,
          remarks: item.remarks,
          images: item.images,

          meta: itemFormFields?.map((f) => ({
            ...f,
            value:
              item.meta?.find((fi) => fi.inputLabel === f.inputLabel)?.value ||
              "",
          })),
        });
      }
    }

    setFormData(formData);
  }, [dataToEdit, itemFormFields, formFields]);

  const handleChange = (e, index) => {
    const { name, value } = e.target;
    setFormData(
      formData.map((fd, i) => (i === index ? { ...fd, [name]: value } : fd))
    );
  };

  // edit data actions
  const handleCustomInputChange = (e) => {
    const { name, value } = e.target;
    setFormFields(
      formFields.map((f) => (f.inputLabel === name ? { ...f, value } : f))
    );
  };

  const handleCustomInputChangeItem = (e, i) => {
    const { name, value } = e.target;
    setFormData(
      formData.map((f, index) =>
        index === i
          ? {
              ...f,
              meta: f.meta.map((m) =>
                m.inputLabel === name ? { ...m, value } : m
              ),
            }
          : f
      )
    );
  };

  const onUpdateItem = (item) => {
    onUpdate();
    setFormData(
      formData.map((d) =>
        d._id === item._id
          ? {
              _id: item._id,
              itemCategory: item.itemCategory._id,
              stockType: item.stockType._id,
              msn: item.msn,
              subFamily: item.subFamily._id,
              itemDescription: item.itemDescription,
              itemCode: item.itemCode,
              manufacturerPartNumber: item.manufacturerPartNumber,
              brandName: item.brandName._id,
              storageCode: item.storageCode._id,
              palletCode: item.palletCode._id,
              conditionCode: item.conditionCode._id,
              damageCode: item.damageCode._id,
              activityCode: item.activityCode._id,
              isn: item.isn,
              packaging: item.packaging._id,
              configurationOs: item.configurationOs,
              family: item.family._id,

              meta: itemFormFields?.map((f) => ({
                ...f,
                value:
                  item.meta?.find((fi) => fi.inputLabel === f.inputLabel)
                    ?.value || "",
              })),
              remarks: item.remarks,
              images: item.images,
            }
          : d
      )
    );
  };

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearch(value.trim());
    if (value.trim() !== "") {
      formData.length > 0 &&
        setSearchFilter(
          formData.filter((ele) =>
            ele.msn.toLowerCase().includes(value.toLowerCase().trim())
          )
        );
    } else {
      setSearchFilter([]);
    }
  };
  return (
    <>
      <MuiPaper component="form">
        <Grid rowSpacing={1} columnSpacing={2} container>
          <Grid xs={12} sm={6} md={4} item>
            <FormInput
              type="text"
              formik={entryFormik}
              name="gmsReferenceCode"
              disabled={true}
              label="Gms Reference Code"
              required={true}
            />
          </Grid>
          <Grid xs={12} sm={6} md={4} item>
            <FormDatePicker
              formik={entryFormik}
              label="Received Date"
              name="receivedDate"
            />
          </Grid>

          <Grid xs={12} sm={6} md={4} item>
            <FormSelect
              formik={entryFormik}
              label="Warehouse code"
              name="warehouseCode"
              options={warehouseData.map((warehouse) => ({
                label: warehouse.code,
                value: warehouse._id,
              }))}
              required={true}
              onChange={(e) => {
                const selectedValue = e.target.value;
                const selectedWarehouse = warehouseData.find(
                  (warehouse) => warehouse._id === selectedValue
                );
                if (selectedWarehouse) {
                  entryFormik.setFieldValue(
                    "warehouseCode",
                    selectedWarehouse._id
                  );
                  entryFormik.setFieldValue(
                    "warehouseName",
                    selectedWarehouse.name
                  );
                }
              }}
            />
          </Grid>
          <Grid xs={12} sm={6} md={4} item>
            <FormInput
              type="text"
              formik={entryFormik}
              name="warehouseName"
              disabled={true}
              label="Warehouse name"
              required={true}
            />
          </Grid>
          <Grid xs={12} sm={6} md={4} item>
            <FormDatePicker
              required={true}
              formik={entryFormik}
              label="Incoming Date"
              name="incomingDate"
            />
          </Grid>
          <Grid xs={12} sm={6} md={4} item>
            <FormInput
              type="text"
              formik={entryFormik}
              name="receivedDocketNumber"
              label="Received docket number"
              required={true}
            />
          </Grid>
          <Grid xs={12} sm={6} md={4} item>
            <FormInput
              type="text"
              formik={entryFormik}
              name="eWayBillNumber"
              label="E-Way Bill number"
              required={true}
            />
          </Grid>
          <Grid xs={12} sm={6} md={4} item>
            <FormInput
              type="text"
              formik={entryFormik}
              name="incomingInvoiceNumber"
              label="Incoming invoice number"
              required={true}
            />
          </Grid>
          <Grid xs={12} sm={6} md={4} item>
            <FormInput
              type="text"
              formik={entryFormik}
              name="dcNumber"
              label="DC number"
              required={true}
            />
          </Grid>

          <Grid xs={12} sm={6} md={4} item>
            <FormInput
              type="text"
              formik={entryFormik}
              name="poNumber"
              label="PO number"
              required={true}
            />
          </Grid>

          <Grid xs={12} sm={6} md={4} item>
            <FormInput
              type="number"
              formik={entryFormik}
              name="numberOfBoxes"
              label="Number of boxes"
              required={true}
            />
          </Grid>

          <Grid xs={12} sm={6} md={4} item>
            <FormInput
              type="number"
              formik={entryFormik}
              name="sku"
              disabled={true}
              label="SKU"
              required={true}
            />
          </Grid>

          <Grid xs={12} sm={6} md={4} item>
            <FormSelect
              formik={entryFormik}
              name="ownerGroup"
              label="Owner Group"
              options={ownerData}
              required={true}
            />
          </Grid>
          <Grid xs={12} sm={6} md={4} item>
            <FormSelect
              type="text"
              formik={entryFormik}
              name="ownerCode"
              label="Owner code"
              options={
                ownerData.find(
                  (owner) => owner.value === entryFormik.values.ownerGroup
                )?.ownerCodes || []
              }
              required={true}
            />
          </Grid>
          <Grid xs={12} sm={6} md={4} item>
            <FormInput
              type="text"
              formik={entryFormik}
              name="ownerCategory"
              label="Owner category"
              required={true}
            />
          </Grid>
          <Grid xs={12} sm={6} md={4} item>
            <FormSelect
              type="text"
              formik={entryFormik}
              name="workflowCode"
              label="Workflow code"
              required={true}
              options={workFlowData}
            />
          </Grid>
          <Grid xs={12} sm={6} md={4} item>
            <FormInput
              type="text"
              formik={entryFormik}
              name="remarks"
              label="Remarks"
            />
          </Grid>

          {formFields
            ? formFields?.map((f, i) => (
                <Grid key={i} item xs={12} sm={6} md={4}>
                  <CustomInput
                    onChange={handleCustomInputChange}
                    name={f.inputLabel}
                    value={f.value}
                    required={f.isRequired}
                    label={f.inputLabel}
                    type={f.inputType}
                  />
                </Grid>
              ))
            : null}
          <Grid
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            xs={12}
            sm={6}
            md={4}
            item
          >
            <ViewOrUpdateInboundEntryFiles
              inboundEntry={dataToEdit}
              onUpdate={onUpdate}
            />
          </Grid>
        </Grid>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: 2,
            mt: 1,
          }}
        >
          <Button
            onClick={() => {
              setEntryToEdit(null);
              setSelectValue(1);
            }}
            size="small"
            variant="outlined"
          >
            Back
          </Button>
          <LoadingButton
            loading={addingEntry}
            size="small"
            variant="contained"
            onClick={entryFormik.handleSubmit}
          >
            Update Entry
          </LoadingButton>
        </Box>
      </MuiPaper>

      <Grid
        container
        spacing={2}
        my={2}
        sx={{
          display: "flex",

          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <Grid item xs="auto">
          <TextField
            size="small"
            value={search}
            onChange={handleSearch}
            label="Search by MSN"
            placeholder="enter msn"
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" type="submit">
                    <Search />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
      <TableContainer component={Paper}>
        <Table>
          <TableHead
            sx={{
              backgroundColor: (theme) =>
                theme.palette.mode === "dark"
                  ? theme.palette.primary.dark
                  : theme.palette.primary.light,
            }}
          >
            <TableRow>
              <TableCell align="center" sx={{ minWidth: "80px" }}>
                S.No
              </TableCell>
              <StyledTableCell align="center">Item Category</StyledTableCell>
              <StyledTableCell align="center">Stock Type</StyledTableCell>
              <StyledTableCell align="center">MSN</StyledTableCell>
              <StyledTableCell align="center">Sub-family name</StyledTableCell>
              <StyledTableCell align="center">Item description</StyledTableCell>
              <StyledTableCell align="center">Item code</StyledTableCell>
              <StyledTableCell align="center">
                Manufacturer part number
              </StyledTableCell>
              <StyledTableCell align="center">Brand name</StyledTableCell>
              <StyledTableCell align="center">Storage code</StyledTableCell>
              <StyledTableCell align="center">Pallet code</StyledTableCell>
              <StyledTableCell align="center">
                Current condition code
              </StyledTableCell>
              <StyledTableCell align="center">Damage code</StyledTableCell>
              <StyledTableCell align="center">
                Current activity code
              </StyledTableCell>
              <StyledTableCell align="center">ISN</StyledTableCell>
              <StyledTableCell align="center">Packaging</StyledTableCell>
              <StyledTableCell align="center">Configuration OS</StyledTableCell>
              <StyledTableCell align="center">Family name</StyledTableCell>
              {itemFormFields?.map((f, i) => (
                <StyledTableCell key={i} align="center">
                  {f.inputLabel}
                </StyledTableCell>
              ))}
              <StyledTableCell align="center">Remarks</StyledTableCell>
              {/* <TableCell align="center">Image</TableCell> */}
              {/* <StyledTableCell align="center">Action</StyledTableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {(search ? searchFilter : formData)
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((fd, i) => (
                <TableRow key={i}>
                  <TableCell align="center" sx={{ minWidth: "80px" }}>
                    {i + 1}
                  </TableCell>
                  <StyledTableCell align="center">
                    <CustomSelect
                      disabled={true}
                      options={itemCategory}
                      name="itemCategory"
                      label="Item Category"
                      value={fd.itemCategory}
                      required={true}
                      onChange={(e) => handleChange(e, i, "repeat")}
                    />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <CustomSelect
                      disabled={true}
                      options={stockTypes}
                      name="stockType"
                      label="Stock Type"
                      value={fd.stockType}
                      required={true}
                      onChange={(e) => handleChange(e, i, "repeat")}
                    />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <CustomInput
                      disabled={true}
                      name="msn"
                      value={fd.msn}
                      label="MSN"
                      required={true}
                      onChange={(e) => handleChange(e, i)}
                    />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <CustomSelect
                      disabled={true}
                      onChange={(e) => handleChange(e, i, "repeat")}
                      options={subFamilies}
                      name="subFamily"
                      label="Sub-Family Name"
                      value={fd.subFamily}
                      required={true}
                    />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <CustomInput
                      disabled={true}
                      name="itemDescription"
                      value={fd.itemDescription}
                      label="Item Description"
                      required={true}
                      onChange={(e) => handleChange(e, i, "repeat")}
                    />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <CustomInput
                      disabled={true}
                      name="itemCode"
                      value={fd.itemCode}
                      label="Item Code"
                      required={true}
                      onChange={(e) => handleChange(e, i, "repeat")}
                    />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <CustomInput
                      disabled={true}
                      name="manufacturerPartNumber"
                      value={fd.manufacturerPartNumber}
                      label="Manufacturer Part Number"
                      required={true}
                      onChange={(e) => handleChange(e, i, "repeat")}
                    />
                  </StyledTableCell>

                  <StyledTableCell align="center">
                    <CustomSelect
                      disabled={true}
                      options={brandData}
                      name="brandName"
                      label="Brand Name"
                      value={fd.brandName}
                      required={true}
                      onChange={(e) => handleChange(e, i, "repeat")}
                    />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <CustomSelect
                      options={storageCodes.filter(
                        (s) =>
                          s.warehouse?._id === entryFormik.values.warehouseCode
                      )}
                      disabled={true}
                      name="storageCode"
                      label="Storage Code"
                      value={fd.storageCode}
                      required={true}
                      onChange={(e) => handleChange(e, i, "repeat")}
                    />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <CustomSelect
                      disabled={true}
                      options={palletCodes.filter(
                        (s) =>
                          s.warehouse?._id === entryFormik.values.warehouseCode
                      )}
                      name="palletCode"
                      label="Pallet Code"
                      value={fd.palletCode}
                      required={true}
                      onChange={(e) => handleChange(e, i, "repeat")}
                    />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <CustomSelect
                      disabled={true}
                      options={currentConditionCodes}
                      name="conditionCode"
                      label="Current Condition Code"
                      value={fd.conditionCode}
                      required={true}
                      onChange={(e) => handleChange(e, i, "repeat")}
                    />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <CustomSelect
                      disabled={true}
                      options={damageCode}
                      name="damageCode"
                      label="Damage Code"
                      value={fd.damageCode}
                      required={true}
                      onChange={(e) => handleChange(e, i, "repeat")}
                    />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <CustomSelect
                      disabled={true}
                      options={activityCodes}
                      name="activityCode"
                      label="Activity Code"
                      value={fd.activityCode}
                      required={true}
                      onChange={(e) => handleChange(e, i, "repeat")}
                    />
                  </StyledTableCell>

                  <StyledTableCell align="center">
                    <CustomInput
                      disabled={true}
                      name="isn"
                      value={fd.isn}
                      label="ISN"
                      required={true}
                      onChange={(e) => handleChange(e, i, "repeat")}
                    />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <CustomSelect
                      disabled={true}
                      options={packaging}
                      name="packaging"
                      label="Packaging"
                      value={fd.packaging}
                      required={true}
                      onChange={(e) => handleChange(e, i, "repeat")}
                    />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <CustomInput
                      disabled={true}
                      name="configurationOs"
                      value={fd.configurationOs}
                      label="Configuration OS"
                      // required={true}
                      onChange={(e) => handleChange(e, i, "repeat")}
                    />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <CustomSelect
                      disabled={true}
                      options={families}
                      name="family"
                      label="Family Name"
                      value={fd.family}
                      required={true}
                      onChange={(e) => handleChange(e, i, "repeat")}
                    />
                  </StyledTableCell>
                  {itemFormFields
                    ? fd.meta?.map((f, index) => (
                        <StyledTableCell align="center" key={index}>
                          <CustomInput
                            disabled={true}
                            onChange={(e) => handleCustomInputChangeItem(e, i)}
                            name={f.inputLabel}
                            value={f.value}
                            required={f.isRequired}
                            label={f.inputLabel}
                            type={f.inputType}
                          />
                        </StyledTableCell>
                      ))
                    : null}
                  <StyledTableCell align="center">
                    <CustomInput
                      disabled={true}
                      name="remarks"
                      value={fd.remarks}
                      label="Remarks"
                      onChange={(e) => handleChange(e, i, "repeat")}
                    />
                  </StyledTableCell>
                  {/* <StyledTableCell sx={{ minWidth: "80px" }} align="center">
                  <ViewOrUpdateItemFiles item={fd} onUpdate={onUpdateItem} />
                </StyledTableCell>
                <StyledTableCell sx={{ minWidth: "80px" }} align="center">
                  <Box
                    display="flex"
                    sx={{ justifyContent: "center", alignItems: "center" }}
                  >
                    <LoadingButton
                      loading={addingSave[fd._id]}
                      size="small"
                      variant="contained"
                      onClick={() => handleUpdate(fd._id, fd)}
                      style={{ marginRight: "4px" }}
                    >
                      Save
                    </LoadingButton>

                    <LoadingButton
                      loading={addingSave[fd._id]}
                      size="small"
                      variant="contained"
                      sx={{ width: 200 }}
                      onClick={() => handleUpdate(fd._id, fd, "updateHistory")}
                    >
                      Save and Update History
                    </LoadingButton>
                  </Box>
                </StyledTableCell> */}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={formData ? (formData.length ? formData.length : 0) : 0}
        page={page}
        rowsPerPageOptions={[10]}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      {!formData.length && !search.trim() && (
        <Typography variant="h6" my={2} textAlign={"center"}>
          No data found
        </Typography>
      )}
      {search.trim() && !searchFilter.length && (
        <Typography variant="h6" my={2} textAlign={"center"}>
          No data found
        </Typography>
      )}
    </>
  );
}
