import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { Grid } from "@mui/material";

// custom imports
import PageHeader from "../../components/PageHeader";
import CustomTable from "../../components/Tables/CustomTable";
import FormDialog from "../../forms/FormDialog";
import FormInput from "../../forms/FormInput";
import FormSelect from "../../forms/FormSelect";
import CustomInput from "../../components/SecondaryForms/CustomInput";

// services
import { del, get, post, put } from "../../services/apiMethods";
import URLS from "../../services/urlConstants";

// data
import { ownerGroupKeys } from "../../data/TableData/ownerGroup";

export default function OwnerGroup() {
  const [isReady, setIsReady] = useState(false);
  const [open, setOpen] = useState(false);
  const [dataToEdit, setDataToEdit] = useState(null);
  const [data, setData] = useState([]);
  const [formFields, setFormFields] = useState(null);
  const [ownerCodeList, setOwnerCodeList] = useState(null);
  const [loading, setLoading] = useState(false);

  const options = ownerCodeList?.map((code) => ({
    label: code.name,
    value: code._id,
  }));

  // owner group list
  const getData = async () => {
    try {
      const { data } = await get(URLS.ownerGroup.list);
      setData(data.result);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
    setTimeout(() => {
      setIsReady(true);
    }, 3000);
  };

  // owner code list
  const getOwnerCodeData = async () => {
    try {
      const { data } = await get(URLS.ownerCode.list);
      console.log(data, "sss");
      setOwnerCodeList(data.result);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
    setTimeout(() => {
      setIsReady(true);
    }, 300);
  };

  const getFormDetails = async () => {
    try {
      const { data } = await get(URLS.forms.read, {
        params: { collectionName: "ownerGroup" },
      });
      setFormFields(data.result ? data.result.fields : null);
    } catch (error) {
      console.log(error);
    }
  };

  // get data on page load
  useEffect(() => {
    getData();
    getOwnerCodeData();
    getFormDetails();
  }, []);

  const handleOnFieldAdd = () => {
    getData();
    getFormDetails();
  };

  // create || update actions
  const handleCreateOrUpdate = async (values) => {
    try {
      const payload = {
        ...values,
        meta: formFields,
      };
      setLoading(true);

      if (dataToEdit) {
        const { data } = await put(
          URLS.ownerGroup.update + "/" + dataToEdit._id,
          payload
        );
      } else {
        const { data } = await post(URLS.ownerGroup.create, payload);
      }
      getData();
      handleModalClose();
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
    setLoading(false);
  };

  const formik = useFormik({
    initialValues: {
      name: dataToEdit?.name || "",
      description: dataToEdit?.description || "",
      ownerCodes: dataToEdit?.ownerCodes?.map((c) => c._id) || [],
    },
    onSubmit: (values) => handleCreateOrUpdate(values),
    enableReinitialize: true,
  });

  // modal actions
  const openDataAddOrUpdateModal = () => setOpen(true);

  const handleModalClose = () => {
    setOpen(false);
    setTimeout(() => {
      setDataToEdit(null);
      setFormFields(
        formFields?.map((f) => ({
          ...f,
          value: "",
        }))
      );
      formik.resetForm();
    }, 200);
  };

  // edit data actions
  const handleCustomInputChange = (e) => {
    const { name, value } = e.target;
    setFormFields(
      formFields.map((f) => (f.inputLabel === name ? { ...f, value } : f))
    );
  };

  const handleSetDataToEdit = (data) => {
    setDataToEdit(data);
    const fields = data?.meta;
    setFormFields(
      formFields?.map((f) => ({
        ...f,
        value:
          fields?.find((fi) => fi.inputLabel === f.inputLabel)?.value || "",
      }))
    );
    setOpen(true);
  };

  const handleDelete = async (id) => {
    try {
      const response = del(URLS.ownerGroup.delete + "/" + id);
      const filtered = data.filter((item) => item._id != id);
      setData(filtered);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <PageHeader
        collection="Owner Group"
        onAddClick={openDataAddOrUpdateModal}
        title="Owner Group"
        modelName="ownerGroup"
        onFieldAdd={handleOnFieldAdd}
        module="Owner group"
      />

      <CustomTable
        actions={["edit", "delete"]}
        bodyDataModal="owner group"
        isDataReady={isReady}
        bodyData={data}
        tableKeys={ownerGroupKeys}
        onEditClick={handleSetDataToEdit}
        onDeleteClick={handleDelete}
        module="Owner group"
        formFields={formFields}
      />

      <FormDialog
        open={open}
        onClose={handleModalClose}
        submitButtonTitle={dataToEdit ? "Update" : "Add"}
        formik={formik}
        adding={loading}
        formTitle={dataToEdit ? "Update owner group" : "Add owner group"}
      >
        <Grid container rowSpacing={1} columnSpacing={2}>
          <Grid item xs={12} sm={6}>
            <FormInput
              required={true}
              name="name"
              type="text"
              formik={formik}
              label="Name"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormInput
              name="description"
              type="text"
              formik={formik}
              label="Description"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormSelect
              formik={formik}
              label="Owner Codes"
              multiple={true}
              name="ownerCodes"
              options={options}
              required={true}
            />
          </Grid>
          {formFields
            ? formFields?.map((f, i) => (
                <Grid key={i} item xs={12} sm={6}>
                  <CustomInput
                    onChange={handleCustomInputChange}
                    name={f.inputLabel}
                    value={f.value}
                    required={f.isRequired}
                    label={f.inputLabel}
                    type={f.inputType}
                  />
                </Grid>
              ))
            : null}
        </Grid>
      </FormDialog>
    </>
  );
}
