import React, { useContext } from "react";
import { Box, Button, Grid, InputLabel, Paper, styled } from "@mui/material";

import { useFormik } from "formik";
import * as Yup from "yup";

// img
import ManBoxes from "../assets/images/login.Img/ManBoxes.png";
import logo from "../assets/images/gmslogo.png";
import FormInput from "../forms/FormInput";
import ThemeModeContext from "../context/ThemeModeContext";
import RenderInput from "../components/RenderInput";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const LeftComponent = styled(Grid)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const RightComponent = styled(Grid)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const Item = styled(Paper)(({ theme }) => ({
  // backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  display: "flex",
  // padding: theme.spacing(1),
  textAlign: "center",
  justifyContent: "center",
  alignItems: "center",
}));

const MuiForm = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
});

const MuiBox = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginTop: "20px",
  gap: "15px",
});

const MuiInputLabel = styled(InputLabel)({
  color: "black",
  marginTop: "20px",
  textAlign: "start",
});

const loginFormData = {
  fomdCode: "login01",
  ver: "1.0",
  data: {
    fields: {
      controls: [
        {
          name: "username",
          label: "Username",
          type: "text",
          component: "textfield",
          placeholder: "Username",
          multiselect: false,
          disabled: false,
          validators: {
            required: true,
            minLength: 10,
          },
        },
      ],
    },
  },
};

export default function ForgotPassword() {
  const { isDarkMode } = useContext(ThemeModeContext);

  const formik = useFormik({
    initialValues: {
      username: "",
    },
    validationSchema: Yup.object({
      username: Yup.string().required().label("Username"),
    }),
    onSubmit: async (values) => {},

    validateOnBlur: false,
  });

  return (
    <>
      <Grid container>
        <LeftComponent
          item
          xs={12}
          md={6}
          sx={{
            display: { xs: "none", md: "flex" },
            height: "100vh",
            backgroundColor: isDarkMode ? "white" : "black",
          }}
        >
          <img src={ManBoxes} alt="loading..." />
        </LeftComponent>

        <RightComponent
          item
          xs={12}
          md={6}
          sx={{
            height: "100vh",
            backgroundColor: isDarkMode ? "black" : "white",
          }}
        >
          <Item
            elevation={2}
            sx={{
              width: "100%",
              maxWidth: "500px",
              marginRight: "15px",
              marginLeft: "15px",
              justifyContent: "center",
            }}
          >
            <MuiForm
              component="form"
              onSubmit={formik.handleSubmit}
              sx={{
                maxWidth: { xs: "100%", sm: 450, md: 460, lg: 650 },
                width: "100%",
                padding: { xs: "50px", md: "60px" },
              }}
            >
              <img
                src={logo}
                alt="loading.."
                width={250}
                style={{ marginBottom: "20px", objectFit: "contain" }}
              />

              <FormInput
                required
                id="filled-hidden-label-small"
                name="username"
                label="Username"
                formik={formik}
                containerStyle={{ marginBottom: 2, width: "90%" }}
                type="text"
              />

              <MuiBox>
                <Button size="small" variant="contained">
                  Cancel
                </Button>
                <Button size="small" type="submi" variant="contained">
                  Send Link
                </Button>
              </MuiBox>
            </MuiForm>
          </Item>
        </RightComponent>
      </Grid>
      <ToastContainer />
    </>
  );
}
