import React, { useEffect } from "react";
import PageHeader from "../../components/PageHeader";
import { useState } from "react";
import { useFormik } from "formik";
import FormInput from "../../forms/FormInput";
import FormDialog from "../../forms/FormDialog";
import {
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { bundleTypeKeys } from "../../data/TableData/bundleType";
import CustomTable from "../../components/Tables/CustomTable";
import CustomSelect from "../../components/SecondaryForms/CustomSelect";
import CustomInput from "../../components/SecondaryForms/CustomInput";
import { Add, Delete } from "@mui/icons-material";
import URLS from "../../services/urlConstants";
import { del, get, post, put } from "../../services/apiMethods";

export default function BundleType() {
  const [isReady, setIsReady] = useState(false);
  const [open, setOpen] = useState(false);
  const [dataToEdit, setDataToEdit] = useState(null);
  const [data, setData] = useState([]);
  const [formFields, setFormFields] = useState(null);
  const [loading, setLoading] = useState(false);
  const [bundleData, setBundleData] = useState([
    { subFamily: "", count: 0 },
    { subFamily: "", count: 0 },
  ]);

  const [subFamilies, setSubFamilies] = useState([]);

  const getSubFamilies = async () => {
    try {
      const { data } = await get(URLS.subFamily.list);
      setSubFamilies(
        data.result.map((sf) => ({ ...sf, label: sf.name, value: sf._id }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  const getData = async () => {
    try {
      const { data } = await get(URLS.bundleType.list);
      setData(data.result);
    } catch (error) {
      console.log(error);
    }
    setTimeout(() => {
      setIsReady(true);
    }, 300);
  };

  const getFormDetails = async () => {
    try {
      const { data } = await get(URLS.forms.read, {
        params: { collectionName: "bundleType" },
      });
      setFormFields(data.result ? data.result.fields : null);
    } catch (error) {
      console.log(error);
    }
  };

  // get data on page load
  useEffect(() => {
    getData();
    getFormDetails();
    getSubFamilies();
  }, []);

  const handleOnFieldAdd = () => {
    getData();
    getFormDetails();
  };

  useEffect(() => {
    let strings = [];
    for (let bd of bundleData) {
      let newString = `${bd.count}-${
        subFamilies.find((s) => s._id == bd.subFamily)?.name || "Not selected"
      }`;
      strings.push(newString);
    }
    let description = strings.join(" and ");
    formik.setFieldValue("description", description);
  }, [bundleData]);

  // create || update actions
  const handleCreateOrUpdate = async (values) => {
    try {
      const payload = {
        ...values,
        meta: formFields,
        bundleData,
      };

      setLoading(true);
      if (dataToEdit) {
        const { data } = await put(
          URLS.bundleType.update + "/" + dataToEdit._id,
          payload
        );
      } else {
        const { data } = await post(URLS.bundleType.create, payload);
      }

      getData();
      handleModalClose();
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const formik = useFormik({
    initialValues: {
      code: dataToEdit?.code || "",
      description: dataToEdit?.description || "",
    },
    onSubmit: handleCreateOrUpdate,
    enableReinitialize: true,
  });

  // modal actions
  const openDataAddOrUpdateModal = () => setOpen(true);

  const handleModalClose = () => {
    setOpen(false);
    setTimeout(() => {
      setDataToEdit(null);
      setBundleData([
        { subFamily: "", count: 0 },
        { subFamily: "", count: 0 },
      ]);
      setFormFields(
        formFields?.map((f) => ({
          ...f,
          value: "",
        }))
      );
      formik.resetForm();
    }, 200);
  };

  const handleSetDataToEdit = (data) => {
    setDataToEdit(data);
    const fields = data?.meta;
    setFormFields(
      formFields?.map((f) => ({
        ...f,
        value:
          fields?.find((fi) => fi.inputLabel === f.inputLabel)?.value || "",
      }))
    );

    setBundleData(
      data.bundleData.map((s) => ({
        ...s,
        subFamily: s.subFamily._id,
        count: s.count,
      }))
    );

    setOpen(true);
  };

  const handleDelete = async (id) => {
    try {
      const res = await del(URLS.bundleType.delete + "/" + id);
      const filtered = data.filter((item) => item._id !== id);
      setData(filtered);
    } catch (error) {
      console.error(error);
    }
  };

  const handleAddBundleData = () => {
    setBundleData((prev) => [...prev, { subFamily: "", count: 0 }]);
  };

  const handleRemoveBundleData = (index) => {
    setBundleData(bundleData.filter((d, i) => i !== index));
  };

  const onChageBundleData = (e, index) => {
    const { name, value } = e.target;
    setBundleData(
      bundleData.map((d, i) => (i === index ? { ...d, [name]: value } : d))
    );
  };
  // edit data actions
  const handleCustomInputChange = (e) => {
    const { name, value } = e.target;
    setFormFields(
      formFields.map((f) => (f.inputLabel === name ? { ...f, value } : f))
    );
  };

  return (
    <>
      <PageHeader
        onAddClick={openDataAddOrUpdateModal}
        collection="Bundle Type"
        title="Bundle Type"
        modelName="bundleType"
        onFieldAdd={handleOnFieldAdd}
        module="Bundle Type"
      />
      <CustomTable
        actions={["edit", "delete"]}
        bodyDataModal="bundle type"
        bodyData={data}
        tableKeys={bundleTypeKeys}
        isDataReady={isReady}
        onDeleteClick={handleDelete}
        onEditClick={handleSetDataToEdit}
        module="Bundle Type"
        formFields={formFields}
      />
      <FormDialog
        open={open}
        adding={loading}
        onClose={handleModalClose}
        submitButtonTitle={dataToEdit ? "Update" : "Add"}
        formik={formik}
        formTitle={dataToEdit ? "Update Bundle Type" : "Add Bundle Type"}
      >
        <Grid container rowSpacing={1} columnSpacing={2}>
          <Grid item xs={12} sm={6}>
            <FormInput
              required={true}
              name="code"
              type="text"
              formik={formik}
              label="Bundle Code"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormInput
              disabled={true}
              required={true}
              name="description"
              type="text"
              formik={formik}
              label="Description"
            />
          </Grid>
          {formFields
            ? formFields?.map((f, i) => (
                <Grid key={i.toString() + f.inputLabel} item xs={12} sm={6}>
                  <CustomInput
                    onChange={handleCustomInputChange}
                    name={f.inputLabel}
                    value={f.value}
                    required={f.isRequired}
                    label={f.inputLabel}
                    type={f.inputType}
                  />
                </Grid>
              ))
            : null}

          <Grid item xs={12}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">S.No</TableCell>
                    <TableCell align="center">Sub-Family</TableCell>
                    <TableCell align="center">Count</TableCell>
                    <TableCell align="center">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {bundleData?.map((d, i) => (
                    <TableRow key={i}>
                      <TableCell align="center">{i + 1}</TableCell>
                      <TableCell align="center">
                        <CustomSelect
                          onChange={(e) => onChageBundleData(e, i)}
                          required={true}
                          placeholder="Select"
                          value={d.subFamily}
                          label="Sub-Family Name"
                          options={subFamilies}
                          name="subFamily"
                        />
                      </TableCell>
                      <TableCell align="center">
                        <CustomInput
                          onChange={(e) => onChageBundleData(e, i)}
                          name="count"
                          required={true}
                          value={d.count}
                          label="Count"
                        />
                      </TableCell>

                      <TableCell align="center">
                        {bundleData.length < 3 ? null : (
                          <IconButton onClick={() => handleRemoveBundleData(i)}>
                            <Delete />
                          </IconButton>
                        )}
                        {i !== bundleData.length - 1 ? null : (
                          <IconButton onClick={handleAddBundleData}>
                            <Add />
                          </IconButton>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </FormDialog>
    </>
  );
}
