import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";

// custom imports
import FormInput from "../forms/FormInput";
import FormSelect from "../forms/FormSelect";
import FormDialog from "../forms/FormDialog";

// services
import { get, post, put } from "../services/apiMethods";
import URLS from "../services/urlConstants";
import CustomInput from "./SecondaryForms/CustomInput";
import CustomSelect from "./SecondaryForms/CustomSelect";
import { LoadingButton } from "@mui/lab";
import { Delete } from "@mui/icons-material";
import NoTableData from "./Tables/NoTableData";
import ShowComponent from "./ShowComponent";
import { hasAllValues } from "../utils/utilities";

// styled components
const Wrapper = styled(Paper)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  borderRadius: theme.shape.borderRadius,
  padding: "10px",
  marginBottom: "20px",
}));

// default options
const INPUT_TYPE_OPTIONS = [
  { label: "String", value: "string" },
  { label: "Number", value: "number" },
];

const IS_REQUIREDOPTIONS = [
  { label: "Yes", value: "true" },
  { label: "No", value: "false" },
];

export default function PageHeader({
  title = "",
  onAddClick = () => {},
  collection = "",
  showAddField = true,
  showAdd = true,
  modelName = "",
  onFieldAdd = () => {},
  module = "",
}) {
  const theme = useTheme();
  let fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [open, setOpen] = useState(false);
  const [addingField, setAddingField] = useState(false);
  const [formFields, setFormFields] = useState([]);
  const [openFieldsModal, setOpenFieldsModal] = useState(false);
  const [updatingFields, setUpdatingFields] = useState(false);

  const getCustomFields = async () => {
    try {
      const { data } = await get(URLS.forms.read, {
        params: { collectionName: modelName },
      });
      setFormFields(
        data?.result?.fields?.map((f) => ({
          ...f,
          isRequired: f.isRequired == true ? "true" : "false",
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (modelName) {
      getCustomFields();
    }
  }, [modelName, openFieldsModal]);

  // adding new fields
  const handleAddField = async (values) => {
    let payload = {
      collectionName: modelName,
      field: {
        ...values,
        isRequired: values.isRequired === "true" ? true : false,
        value: values.inputType === "string" ? "" : 0,
      },
    };
    setAddingField(true);
    try {
      const { data } = await post(URLS.forms.create, payload);
      onFieldAdd();
    } catch (error) {
      console.log(error);
    }

    setAddingField(false);
    handleClose();
  };
  const formik = useFormik({
    initialValues: {
      inputType: "",
      isRequired: "",
      inputLabel: "",
    },
    onSubmit: (values) => handleAddField(values),
    enableReinitialize: true,
  });

  //add modal actions
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => {
      formik.resetForm();
    }, 200);
  };

  // update fields modal
  const handleCloseFieldsModal = () => setOpenFieldsModal(false);
  const handleOpenFieldsModal = () => setOpenFieldsModal(true);

  const handleCustomInputChange = (e, index) => {
    const { name, value } = e.target;
    setFormFields(
      formFields.map((f, i) => (i === index ? { ...f, [name]: value } : f))
    );
  };

  const handleRemoveCustomField = (index) => {
    setFormFields(formFields.filter((f, i) => i !== index));
  };

  const handleUpdateCustomFields = async () => {
    for (let form of formFields) {
      if (!hasAllValues(form, "value")) return;
    }
    let payload = {
      collectionName: modelName,
      fields: formFields.map((f) => ({
        ...f,
        isRequired: f.isRequired === "true" ? true : false,
      })),
    };
    setUpdatingFields(true);
    try {
      await put(URLS.forms.update, payload);
      getCustomFields();
      onFieldAdd();
      handleCloseFieldsModal();
    } catch (error) {
      console.log(error);
    }

    setUpdatingFields(false);
  };

  return (
    <>
      <Wrapper>
        <Typography component="h1" sx={{ fontWeight: "bold" }}>
          {title}
        </Typography>
        <Stack direction="row" spacing={2}>
          <ShowComponent module={module} action={"add"}>
            {" "}
            {showAdd ? (
              <Button size="small" variant="contained" onClick={onAddClick}>
                Add
              </Button>
            ) : null}
          </ShowComponent>
          <ShowComponent module="Custom Fields" action={"add"}>
            {" "}
            {showAddField ? (
              <Button onClick={handleOpen} size="small" variant="outlined">
                Add Custom Field
              </Button>
            ) : null}
          </ShowComponent>
          <ShowComponent module="Custom Fields" action={"update"}>
            {" "}
            {showAddField ? (
              <Button
                onClick={handleOpenFieldsModal}
                size="small"
                variant="outlined"
              >
                Update Custom Fields
              </Button>
            ) : null}
          </ShowComponent>
        </Stack>
      </Wrapper>
      <FormDialog
        open={open}
        formik={formik}
        formTitle={`Add field to ${collection}`}
        onClose={handleClose}
        submitButtonTitle="Add"
        adding={addingField}
      >
        <Grid rowSpacing={1} columnSpacing={2} container>
          <Grid item xs={12} sm={6}>
            <FormInput
              name="inputLabel"
              type="text"
              required={true}
              formik={formik}
              label="Input label"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormSelect
              options={INPUT_TYPE_OPTIONS}
              formik={formik}
              name="inputType"
              label="Input type"
              required={true}
              multiple={false}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormSelect
              options={IS_REQUIREDOPTIONS}
              formik={formik}
              name="isRequired"
              label="Required"
              required={true}
              multiple={false}
            />
          </Grid>
        </Grid>
      </FormDialog>

      <Dialog
        fullScreen={fullScreen}
        open={openFieldsModal}
        onClose={handleCloseFieldsModal}
        PaperProps={{
          sx: {
            width: "100%",
            maxWidth: 650,
          },
        }}
      >
        <DialogTitle>Update {collection} Custom Fields</DialogTitle>
        <Divider />
        <DialogContent
          sx={{
            padding: "10px",
          }}
        >
          <TableContainer>
            <Table>
              <TableHead
                sx={{
                  backgroundColor: (theme) =>
                    theme.palette.mode === "dark"
                      ? theme.palette.primary.dark
                      : theme.palette.primary.light,
                }}
              >
                <TableRow>
                  <TableCell align="center">S. No</TableCell>
                  <TableCell align="center">Input Label</TableCell>
                  <TableCell align="center">Input Type</TableCell>
                  <TableCell align="center">Required</TableCell>
                  <TableCell align="center">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {formFields?.length
                  ? formFields.map((f, index) => (
                      <TableRow key={index}>
                        <TableCell align="center">{index + 1}</TableCell>
                        <TableCell align="center">
                          <CustomInput
                            onChange={(e) => handleCustomInputChange(e, index)}
                            name="inputLabel"
                            value={f.inputLabel}
                            label="Input Label"
                          />
                        </TableCell>
                        <TableCell align="center">
                          <CustomSelect
                            onChange={(e) => handleCustomInputChange(e, index)}
                            name="inputType"
                            value={f.inputType}
                            options={INPUT_TYPE_OPTIONS}
                            label="Input Type"
                          />
                        </TableCell>

                        <TableCell align="center">
                          <CustomSelect
                            onChange={(e) => handleCustomInputChange(e, index)}
                            name="isRequired"
                            value={f.isRequired}
                            options={IS_REQUIREDOPTIONS}
                            label="Is Required"
                            required={true}
                          />
                        </TableCell>

                        <TableCell align="center">
                          <IconButton
                            onClick={() => handleRemoveCustomField(index)}
                            size="small"
                          >
                            {" "}
                            <Delete color="error" fontSize="small" />{" "}
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))
                  : null}
              </TableBody>
            </Table>
            <NoTableData
              dataPresent={formFields ? formFields.length : 0}
              title="custom fields"
            />
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button
            color="error"
            variant="contained"
            size="small"
            onClick={handleCloseFieldsModal}
          >
            Cancel
          </Button>
          <LoadingButton
            loading={updatingFields}
            onClick={handleUpdateCustomFields}
            variant="contained"
            size="small"
          >
            Update
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
