import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import CustomSelect from "../../components/SecondaryForms/CustomSelect";
import CustomInput from "../../components/SecondaryForms/CustomInput";
import ViewOrUpdateItemFiles from "../../components/ViewOrUpdateItemFiles";

//services
import URLS from "../../services/urlConstants";
import { get, put } from "../../services/apiMethods";
import { useLocation, useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { Search } from "@mui/icons-material";

import { hasAllValues } from "../../utils/utilities";
import WarehouseContext from "../../context/WarehouseContext";
import { toast } from "react-toastify";
import ShowComponent from "../ShowComponent";
const StyledTableCell = styled(TableCell)(() => ({
  minWidth: "200px",
}));

export default function UpdateUsedStock() {
  const { selectedWarehouse, selectedOwnerCode } = useContext(WarehouseContext);
  const [formData, setFormData] = useState([]);
  const [stockTypes, setStockTypes] = useState([]);
  const [families, setFamilies] = useState([]);
  const [subFamilies, setSubFamilies] = useState([]);
  const [storageCodes, setStorageCodes] = useState([]);
  const [palletCodes, setPalletCodes] = useState([]);
  const [currentConditionCodes, setCurrentConditionCodes] = useState([]);
  const [gradingScale, setGradingScale] = useState([]);
  const [packaging, setPackaging] = useState([]);
  const [brandData, setBrandData] = useState([]);
  const [itemCategoryData, setItemCategoryData] = useState([]);
  const [damageCodeData, setDamageCodeData] = useState([]);
  const [addingSave, setAddingSave] = useState(false);
  const [addingUpdate, setAddingUpdate] = useState(false);
  const [searchFilter, setSearchFilter] = useState([]);
  const [activityCodes, setActivityCodes] = useState([]);
  const [search, setSearch] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const usedStockData = location.state && location.state.itemData;

  const [itemFormFields, setItemFormFields] = useState(null);

  const [page, setPage] = React.useState(0);

  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getItemFormDetails = async () => {
    try {
      const { data } = await get(URLS.forms.read, {
        params: { collectionName: "item" },
      });
      setItemFormFields(data.result ? data.result.fields : null);
    } catch (error) {
      console.log(error);
    }
  };

  const getData = async () => {
    try {
      const stockPromise = get(URLS.stockType.list);
      const familyPromise = get(URLS.family.list);
      const subFamilyPromise = get(URLS.subFamily.list);
      const brandNamePromise = get(URLS.brand.list);
      const storageCodePromise = get(URLS.storageCode.list, {
        params: {
          warehouseId: selectedWarehouse,
          ownerCode: selectedOwnerCode,
        },
      });
      const palletCodeCodePromise = get(URLS.palletCode.list, {
        params: {
          warehouseId: selectedWarehouse,
          ownerCode: selectedOwnerCode,
        },
      });
      const currentConditionCodePromise = get(URLS.conditionCode.list);
      const gradingScallePromise = get(URLS.gradingScale.list);
      const packagingPromise = get(URLS.packaging.list);
      const itemCategoryPromise = get(URLS.itemCategory.list);
      const damageCodePromise = get(URLS.damageCode.list);
      const activityCodePromise = get(URLS.currentActivityCode.list);

      const [
        stockTypeData,
        familyData,
        subFamilyData,
        brandData,
        storageCodeData,
        palletCodeData,
        currentConditionCodeData,
        gradingScaleData,
        packagingData,
        itemCategoryData,
        damageCodeData,
        activityCodeData,
      ] = await Promise.all([
        stockPromise,
        familyPromise,
        subFamilyPromise,
        brandNamePromise,
        storageCodePromise,
        palletCodeCodePromise,
        currentConditionCodePromise,
        gradingScallePromise,
        packagingPromise,
        itemCategoryPromise,
        damageCodePromise,
        activityCodePromise,
      ]);
      setActivityCodes(
        activityCodeData.data.result.map((s) => ({
          ...s,
          label: `${s.code}-${s.description}`,
          value: s._id,
        }))
      );
      setStockTypes(
        stockTypeData?.data?.result?.map((s) => ({
          ...s,
          label: s.name,
          value: s._id,
        }))
      );
      setFamilies(
        familyData.data.result.map((s) => ({
          ...s,
          label: s.name,
          value: s._id,
        }))
      );
      setSubFamilies(
        subFamilyData.data.result.map((s) => ({
          ...s,
          label: s.name,
          value: s._id,
        }))
      );
      setBrandData(
        brandData.data.result.map((s) => ({
          ...s,
          label: s.name,
          value: s._id,
        }))
      );
      setStorageCodes(
        storageCodeData.data.result.map((s) => ({
          ...s,
          label: s.code,
          value: s._id,
        }))
      );
      setPalletCodes(
        palletCodeData.data.result.map((s) => ({
          ...s,
          label: s.code,
          value: s._id,
        }))
      );
      setCurrentConditionCodes(
        currentConditionCodeData.data.result.map((s) => ({
          ...s,
          label: `${s.code}-${s.description}`,
          value: s._id,
        }))
      );
      setGradingScale(
        gradingScaleData?.data?.result?.map((s) => ({
          ...s,
          label: `${s.name}-${s.description}`,
          value: s._id,
        }))
      );
      setPackaging(
        packagingData.data.result.map((s) => ({
          ...s,
          label: s.title,
          value: s._id,
        }))
      );
      setItemCategoryData(
        itemCategoryData.data.result.map((s) => ({
          ...s,
          label: s.name,
          value: s._id,
        }))
      );
      setDamageCodeData(
        damageCodeData.data.result.map((s) => ({
          ...s,
          label: `${s.code}-${s.description}`,
          value: s._id,
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getData();
    getItemFormDetails();
  }, []);

  //// const handleChange = (e, index) => {
  //   const { name, value } = e.target;

  //   setFormData(
  //     formData.map((fd, i) => (i === index ? { ...fd, [name]: value } : fd))
  //   );
  // };

  const handleChange = (e, index, repeat = false) => {
    const { name, value } = e.target;

    const faultyId = itemCategoryData.find(
      (i) => i.name.toLowerCase() === "faulty"
    )?._id;
    const usedId = itemCategoryData.find(
      (i) => i.name.toLowerCase() === "used"
    )?._id;

    if (name === "gradingScale") {
      const isDamaged = gradingScale.find((g) => g._id === value)?.isDamaged;
      if (isDamaged) {
        setFormData(
          formData.map((fd, i) =>
            i === index
              ? {
                  ...fd,
                  [name]: value,
                  itemCategory: faultyId,
                }
              : fd
          )
        );
      } else {
        if (repeat) {
          setFormData(
            formData.map((fd, i) =>
              i >= index
                ? {
                    ...fd,
                    [name]: value,
                    itemCategory: usedId,
                    activityCode: "",
                    damageCode: "",
                  }
                : fd
            )
          );
        } else {
          setFormData(
            formData.map((fd, i) =>
              i === index
                ? {
                    ...fd,
                    [name]: value,
                    itemCategory: usedId,
                    activityCode: "",
                    damageCode: "",
                  }
                : fd
            )
          );
        }
      }
    } else {
      const selectedIsDamaged = gradingScale.find(
        (g) => g._id === formData[index].gradingScale
      )?.isDamaged;

      if (selectedIsDamaged) {
        setFormData(
          formData.map((fd, i) =>
            i === index
              ? {
                  ...fd,
                  [name]: value,
                }
              : fd
          )
        );
      } else {
        if (repeat) {
          setFormData(
            formData.map((fd, i) =>
              i >= index
                ? {
                    ...fd,
                    [name]: value,
                  }
                : fd
            )
          );
        } else {
          setFormData(
            formData.map((fd, i) =>
              i === index
                ? {
                    ...fd,
                    [name]: value,
                  }
                : fd
            )
          );
        }
      }
    }
  };

  const getItems = async () => {
    try {
      const { data } = await get(URLS.items.list, {
        params: {
          warehouseId: selectedWarehouse,
          ownerCode: selectedOwnerCode,
          search: {
            inStock: true,
            inBundle: false,
            subFamily: usedStockData.subFamilyId,
          },
        },
      });

      if (data.result) {
        const newFormData = data.result
          .filter((l) => l.itemCategory.name.toLowerCase() == "used")
          .map((item) => ({
            _id: item._id,
            itemCategory: item.itemCategory._id,
            stockType: item.stockType._id,
            msn: item.msn,
            subFamily: item.subFamily._id,
            itemDescription: item.itemDescription,
            itemCode: item.itemCode,
            manufacturerPartNumber: item.manufacturerPartNumber,
            brandName: item.brandName._id,
            storageCode: item.storageCode._id,
            palletCode: item.palletCode._id,
            conditionCode: item.conditionCode._id,

            gradingScale: item.gradingScale ? item.gradingScale._id : null,

            damageCode: item.damageCode ? item.damageCode._id : null,
            isn: item.isn,
            packaging: item.packaging ? item.packaging._id : null,

            configurationOs: item.configurationOs,
            family: item.family._id,
            remarks: item.remarks,
            images: item.images,
            meta: itemFormFields?.map((f) => ({
              ...f,
              value:
                item.meta?.find((fi) => fi.inputLabel === f.inputLabel)
                  ?.value || "",
            })),
          }));
        setFormData(newFormData);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (usedStockData) {
      getItems();
    }
  }, [itemFormFields]);

  const handleUpdate = async (id, fd) => {
    try {
      for (let item of [fd]) {
        if (gradingScale.find((g) => g._id === item.gradingScale)?.isDamaged) {
          if (!hasAllValues(item, ["images", "remarks", "configurationOs"]))
            return;
        } else {
          if (
            !hasAllValues(item, [
              "images",
              "remarks",
              "damageCode",
              "activityCode",
              "configurationOs",
            ])
          )
            return;
        }
      }

      [fd].forEach((f) => {
        if (!gradingScale.find((g) => g._id === f.gradingScale)?.isDamaged) {
          delete f.activityCode;
          delete f.damageCode;
        }
      });

      if ([fd].some((fd) => fd.meta.some((f) => f.isRequired && !f.value)))
        return toast.error("Please fill required fields of this item");

      setAddingSave((prv) => ({
        ...prv,
        [id]: true,
      }));
      const payload = {
        ...fd,
      };
      const { data } = await put(URLS.items.update + "/" + id, payload);
      getItems();
    } catch (error) {
      console.log(error);
    }
    setAddingSave((prv) => ({
      ...prv,
      [id]: false,
    }));
  };

  const handleUpdateAll = async (formData) => {
    try {
      for (let item of formData) {
        if (gradingScale.find((g) => g._id === item.gradingScale)?.isDamaged) {
          if (!hasAllValues(item, ["images", "remarks"])) return;
        } else {
          if (
            !hasAllValues(item, [
              "images",
              "remarks",
              "damageCode",
              "activityCode",
            ])
          )
            return;
        }
      }
      setAddingUpdate(true);
      const payload = {
        items: formData,
      };
      const { data } = await put(URLS.items.updateAll, payload);
      getItems();
    } catch (error) {
      console.log(error);
      setAddingUpdate(false);
    }
    setAddingUpdate(false);
  };

  const onUpdateItem = (item) => {
    getItems();
    setFormData(
      formData.map((d) =>
        d._id === item._id
          ? {
              _id: item._id,
              itemCategory: item.itemCategory._id,
              stockType: item.stockType._id,
              msn: item.msn,
              subFamily: item.subFamily._id,
              itemDescription: item.itemDescription,
              itemCode: item.itemCode,
              manufacturerPartNumber: item.manufacturerPartNumber,
              brandName: item.brandName._id,
              storageCode: item.storageCode._id,
              palletCode: item.palletCode._id,
              conditionCode: item.conditionCode._id,

              gradingScale: item.gradingScale ? item.gradingScale._id : null,
              activityCode: item.activityCode ? item.activityCode._id : null,
              damageCode: item.damageCode ? item.damageCode._id : null,
              isn: item.isn,
              packaging: item.packaging ? item.packaging._id : null,

              configurationOs: item.configurationOs,
              family: item.family._id,
              remarks: item.remarks,
              images: item.images,
              meta: itemFormFields?.map((f) => ({
                ...f,
                value:
                  item.meta?.find((fi) => fi.inputLabel === f.inputLabel)
                    ?.value || "",
              })),
            }
          : d
      )
    );
  };

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearch(value.trim());
    if (value.trim() !== "") {
      formData.length > 0 &&
        setSearchFilter(
          formData.filter((ele) =>
            ele.msn.toLowerCase().includes(value.toLowerCase().trim())
          )
        );
    } else {
      setSearchFilter([]);
    }
  };

  const handleCustomInputChangeItem = (e, i) => {
    const { name, value } = e.target;

    setFormData(
      formData.map((f, index) =>
        index === i
          ? {
              ...f,
              meta: f.meta.map((m) =>
                m.inputLabel === name ? { ...m, value } : m
              ),
            }
          : f
      )
    );
  };

  return (
    <>
      <Grid
        container
        spacing={2}
        my={2}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <Grid item xs="auto">
          <TextField
            size="small"
            value={search}
            fullWidth
            onChange={handleSearch}
            label="Search by MSN"
            placeholder="enter msn"
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" type="submit">
                    <Search />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
      <TableContainer component={Paper}>
        <Table>
          <TableHead
            sx={{
              backgroundColor: (theme) =>
                theme.palette.mode === "dark"
                  ? theme.palette.primary.dark
                  : theme.palette.primary.light,
            }}
          >
            <TableRow>
              <TableCell sx={{ minWidth: "80px" }} align="center">
                S.No
              </TableCell>
              <StyledTableCell align="center">Item Category</StyledTableCell>

              <StyledTableCell align="center">Stock Type</StyledTableCell>
              <StyledTableCell align="center">MSN</StyledTableCell>
              <StyledTableCell align="center">Sub-family name</StyledTableCell>
              <StyledTableCell align="center">Item description</StyledTableCell>
              <StyledTableCell align="center">Item code</StyledTableCell>
              <StyledTableCell align="center">
                Manufacturer part number
              </StyledTableCell>
              <StyledTableCell align="center">Brand name</StyledTableCell>
              <StyledTableCell align="center">Storage code</StyledTableCell>
              <StyledTableCell align="center">Pallet code</StyledTableCell>
              <StyledTableCell align="center">
                Current condition code
              </StyledTableCell>
              <StyledTableCell align="center">Grading Scale</StyledTableCell>
              <StyledTableCell align="center">Activity Code</StyledTableCell>

              <StyledTableCell align="center">Damage Code</StyledTableCell>
              <StyledTableCell align="center">ISN</StyledTableCell>
              <StyledTableCell align="center">Packaging</StyledTableCell>
              <StyledTableCell align="center">Configuration OS</StyledTableCell>
              <StyledTableCell align="center">Family name</StyledTableCell>
              {itemFormFields?.map((i, index) => (
                <StyledTableCell align="center" key={index}>
                  {i.inputLabel}
                </StyledTableCell>
              ))}
              <StyledTableCell align="center">Remarks</StyledTableCell>
              <TableCell align="center" sx={{ minWidth: "80px" }}>
                Image
              </TableCell>
              <TableCell align="center">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(search ? searchFilter : formData)
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((fd, i) => (
                <TableRow key={i}>
                  <TableCell sx={{ minWidth: "80px" }} align="center">
                    {i + 1}
                  </TableCell>
                  <StyledTableCell align="center">
                    <CustomSelect
                      options={itemCategoryData}
                      name="itemCategory"
                      label="Item Category"
                      value={fd.itemCategory}
                      required={true}
                      disabled={true}
                      onChange={(e) => handleChange(e, i)}
                    />
                  </StyledTableCell>

                  <StyledTableCell align="center">
                    <CustomSelect
                      options={stockTypes}
                      name="stockType"
                      label="Stock Type"
                      value={fd.stockType}
                      required={true}
                      onChange={(e) => handleChange(e, i)}
                    />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <CustomInput
                      name="msn"
                      value={fd.msn}
                      label="MSN"
                      required={true}
                      onChange={(e) => handleChange(e, i)}
                    />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <CustomSelect
                      onChange={(e) => handleChange(e, i)}
                      options={subFamilies}
                      name="subFamily"
                      label="Sub-Family Name"
                      value={fd.subFamily}
                      required={true}
                    />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <CustomInput
                      name="itemDescription"
                      value={fd.itemDescription}
                      label="Item Description"
                      required={true}
                      onChange={(e) => handleChange(e, i)}
                    />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <CustomInput
                      name="itemCode"
                      value={fd.itemCode}
                      label="Item Code"
                      required={true}
                      onChange={(e) => handleChange(e, i)}
                    />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <CustomInput
                      name="manufacturerPartNumber"
                      value={fd.manufacturerPartNumber}
                      label="Manufacturer Part Number"
                      required={true}
                      onChange={(e) => handleChange(e, i)}
                    />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <CustomSelect
                      options={brandData}
                      name="brandName"
                      label="Brand Name"
                      value={fd.brandName}
                      required={true}
                      onChange={(e) => handleChange(e, i)}
                    />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <CustomSelect
                      options={storageCodes}
                      name="storageCode"
                      label="Storage Code"
                      value={fd.storageCode}
                      required={true}
                      onChange={(e) => handleChange(e, i)}
                    />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <CustomSelect
                      options={palletCodes}
                      name="palletCode"
                      label="Pallet Code"
                      value={fd.palletCode}
                      required={true}
                      onChange={(e) => handleChange(e, i)}
                    />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <CustomSelect
                      options={currentConditionCodes}
                      name="conditionCode"
                      label="Current Condition Code"
                      value={fd.conditionCode}
                      required={true}
                      onChange={(e) => handleChange(e, i)}
                    />
                  </StyledTableCell>

                  <StyledTableCell align="center">
                    <CustomSelect
                      options={gradingScale}
                      name="gradingScale"
                      label="Grading Scale"
                      value={fd.gradingScale}
                      required={true}
                      onChange={(e) => handleChange(e, i)}
                    />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <CustomSelect
                      name="activityCode"
                      value={fd.activityCode}
                      label="Activity Code"
                      required={true}
                      options={activityCodes}
                      disabled={
                        !gradingScale.find((g) => g._id === fd.gradingScale)
                          ?.isDamaged
                      }
                      onChange={(e) => handleChange(e, i)}
                    />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <CustomSelect
                      options={damageCodeData}
                      name="damageCode"
                      label="Damage Code"
                      value={fd.damageCode}
                      required={true}
                      disabled={
                        !gradingScale.find((g) => g._id === fd.gradingScale)
                          ?.isDamaged
                      }
                      onChange={(e) => handleChange(e, i)}
                    />
                  </StyledTableCell>

                  <StyledTableCell align="center">
                    <CustomInput
                      name="isn"
                      value={fd.isn}
                      label="ISN"
                      required={true}
                      onChange={(e) => handleChange(e, i)}
                    />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <CustomSelect
                      options={packaging}
                      name="packaging"
                      label="Packaging"
                      value={fd.packaging}
                      required={true}
                      onChange={(e) => handleChange(e, i)}
                    />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <CustomInput
                      name="configurationOs"
                      value={fd.configurationOs}
                      label="Configuration OS"
                      // required={true}
                      onChange={(e) => handleChange(e, i)}
                    />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <CustomSelect
                      options={families}
                      name="family"
                      label="Family Name"
                      value={fd.family}
                      required={true}
                      onChange={(e) => handleChange(e, i)}
                    />
                  </StyledTableCell>
                  {itemFormFields
                    ? fd.meta?.map((f, index) => (
                        <StyledTableCell align="center" key={index}>
                          <CustomInput
                            onChange={(e) => handleCustomInputChangeItem(e, i)}
                            name={f.inputLabel}
                            value={f.value}
                            required={f.isRequired}
                            label={f.inputLabel}
                            type={f.inputType}
                          />
                        </StyledTableCell>
                      ))
                    : null}
                  <StyledTableCell align="center">
                    <CustomInput
                      name="remarks"
                      value={fd.remarks}
                      label="Remarks"
                      required={true}
                      onChange={(e) => handleChange(e, i)}
                    />
                  </StyledTableCell>
                  <TableCell align="center" sx={{ minWidth: "80px" }}>
                    <ViewOrUpdateItemFiles item={fd} onUpdate={onUpdateItem} />
                  </TableCell>

                  <TableCell align="center">
                    <ShowComponent module={"Inventory"} action={"update"}>
                      <LoadingButton
                        loading={addingSave[fd._id]}
                        size="small"
                        variant="contained"
                        onClick={() => handleUpdate(fd._id, fd)}
                      >
                        Save
                      </LoadingButton>
                    </ShowComponent>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {!formData.length && !search.trim() && (
        <Typography variant="h6" my={2} textAlign={"center"}>
          No data found
        </Typography>
      )}
      {search.trim() && !searchFilter.length && (
        <Typography variant="h6" my={2} textAlign={"center"}>
          No data found
        </Typography>
      )}
      <TablePagination
        component="div"
        count={formData ? (formData.length ? formData.length : 0) : 0}
        page={page}
        rowsPerPageOptions={[10]}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <Box
        my={2}
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          gap: "20px",
        }}
      >
        <Button variant="outlined" size="small" onClick={() => navigate(-1)}>
          Back
        </Button>
        {/* <LoadingButton
          loading={addingUpdate}
          size="small"
          variant="contained"
          onClick={() => handleUpdateAll(formData)}
        >
          Update
        </LoadingButton> */}
      </Box>
    </>
  );
}
