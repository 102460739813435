import React, { useContext, useRef, useState } from "react";
import PageHeader from "../../components/PageHeader";
import TabList from "../../components/Tabs/TabList";
import { useFormik } from "formik";
import {
  Box,
  Button,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
} from "@mui/material";
import { generateRandomNumber, hasAllValues } from "../../utils/utilities";
import TabPanel from "../../components/Tabs/TabPanel";
import dayjs from "dayjs";
import FormInput from "../../forms/FormInput";
import FormDatePicker from "../../forms/FormDatePicker";
import FormSelect from "../../forms/FormSelect";
import { useEffect } from "react";
import CustomTable from "../../components/Tables/CustomTable";
import { pickListProcessKeys } from "../../data/TableData/pickListProcess";
import CustomInput from "../../components/SecondaryForms/CustomInput";
import { del, get, post } from "../../services/apiMethods";
import URLS from "../../services/urlConstants";
import FileSelect from "../../forms/FileSelect";
import { LoadingButton } from "@mui/lab";
import UpdatePickList from "./UpdatePickList";
import CustomSelect from "../../components/SecondaryForms/CustomSelect";
import CustomAutoComplete from "../../components/SecondaryForms/CustomAutoComplete";
import { toast } from "react-toastify";
import DashBoardPageHeaderPage from "../../components/DashBoard/DashBoardHeaderPage";
import WarehouseContext from "../../context/WarehouseContext";
import { ArrowDropDown, Refresh } from "@mui/icons-material";
import { debounce } from "lodash";

const StyledTableCell = styled(TableCell)(() => ({
  minWidth: "200px",
}));

const categoryType = [
  { label: "New Only", value: "new" },
  {
    label: "Used Only",
    value: "used",
  },
  {
    label: "Bundled",
    value: "bundled",
  },
];

export default function PickList() {
  const { selectedWarehouse, selectedOwnerCode } = useContext(WarehouseContext);
  const [value, setSelectValue] = useState(0);
  const handleTabChange = (e, newValue) => setSelectValue(newValue);
  const [gmsPickListCode, setGmsPickListCode] = useState(
    `GMS${generateRandomNumber(10)}`
  );

  const [entryDone, setEntryDone] = useState(false);
  const [formData, setFormData] = useState([]);
  const [formFields, setFormFields] = useState(null);
  const [ownerData, setOwnerData] = useState([]);
  const [warehouseData, setWarehouseData] = useState([]);
  const [stockTypes, setStockTypes] = useState([]);
  const [subFamilies, setSubFamilies] = useState([]);
  const [storageCodes, setStorageCodes] = useState([]);
  const [palletCodes, setPalletCodes] = useState([]);
  const [addingPickist, setAddingPickList] = useState(false);
  const [entryFiles, setEntryFiles] = useState([]);
  const [pickLists, setPickLists] = useState([]);
  const [entryToEdit, setEntryToEdit] = useState([]);
  const [brands, setBrands] = useState([]);
  const [inStockItems, setInStockItems] = useState([]);
  const [inStockBundles, setInStockBundles] = useState([]);

  const getAllPickList = async () => {
    try {
      const { data } = await get(URLS.pickList.list, {
        params: {
          warehouseId: selectedWarehouse,
          ownerCode: selectedOwnerCode,
        },
      });
      setPickLists(
        data.result.map((d) => ({
          ...d,
          createdAt: new Date(d.createdAt).toLocaleString(),
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (value === 1) {
      getAllPickList();
    }
  }, [value, selectedWarehouse, selectedOwnerCode]);

  const handleSubmit = async (values) => {
    if (!values.bundle) {
      if (!hasAllValues(values, ["bundle"])) return;
    } else if (!values.sku) {
      if (!hasAllValues(values, ["sku"])) return;
    }

    for (let item of formData) {
      if (
        (item.category !== "Bundled" &&
          !hasAllValues(item, ["bundleReferenceNumber"])) ||
        (item.category === "Bundled" && !hasAllValues(item, []))
      )
        return;
    }

    let payload = {
      ...values,
      individualItems: formData
        .filter((f) => f.category !== "Bundled")
        .map((v) => v._id),
      bundledItems: formData
        .filter((f) => f.category === "Bundled")
        .map((v) => v._id),
      meta: formFields || [],
    };

    setAddingPickList(true);

    try {
      const newFormData = new FormData();
      newFormData.append("pickListData", JSON.stringify(payload));

      entryFiles.forEach((file) => newFormData.append("files", file));

      const { data } = await post(URLS.pickList.create, newFormData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      getAllPickList();
      entryFormik.resetForm();
      entryFormik.setFieldValue(
        "gmsPickListCode",
        `GMS${generateRandomNumber(10)}`
      );
      setSelectValue(1);
      setFormData([]);
      setAddingPickList(false);
      setEntryDone(false);
    } catch (error) {
      console.log(error);
      setAddingPickList(false);
    }
  };

  const entryFormik = useFormik({
    initialValues: {
      gmsPickListCode: `GMS${generateRandomNumber(10)}`,
      pickListDate: dayjs(new Date()),
      warehouseCode: "",
      warehouseName: "",
      bundle: "",
      sku: "",
      ownerGroup: "",
      ownerCode: "",
      ownerCategory: "NA",
      remarks: "NA",
    },
    onSubmit: handleSubmit,
    validateOnBlur: false,
  });

  const debouceSubmit = debounce(entryFormik.handleSubmit, 1000);

  useEffect(() => {
    let formData = [];
    getItems();
    getBundles();
    if (entryFormik.values.sku || entryFormik.values.bundle) {
      if (Number(entryFormik.values.sku) > inStockItems.length) {
        toast.info(
          "Selected number of sku is greater than number of sku in stock"
        );
      } else if (Number(entryFormik.values.bundle) > inStockBundles.length) {
        toast.info(
          "Selected number of bundles is greater than number of bundles in stock"
        );
      } else {
        for (let i = 0; i < entryFormik.values.sku; i++) {
          let newForm = {
            _id: "",
            category: "",
            stockType: "",
            msn: "",
            bundleReferenceNumber: "",
            subFamilyName: "",
            itemDescription: "",
            itemCode: "",
            manufacturerPartNumber: "",
            brandName: "",
            storageCode: "",
            palletCode: "",
          };
          formData.push(newForm);
        }

        for (let i = 0; i < entryFormik.values.bundle; i++) {
          let newForm = {
            category: "Bundled",
            stockType: "",
            msn: "",
            bundleReferenceNumber: "",
            subFamilyName: "",
            itemDescription: "",
            itemCode: "",
            manufacturerPartNumber: "",
            brandName: "",
            storageCode: "",
            palletCode: "",
          };
          formData.push(newForm);
        }
      }
    }

    setFormData(formData);
  }, [entryFormik.values.sku, entryFormik.values.bundle]);

  const getItems = async () => {
    try {
      const { data } = await get(URLS.items.list, {
        params: {
          search: { inStock: true, inBundle: false },
          warehouseId: entryFormik.values.warehouseCode,
          ownerCode: entryFormik.values.ownerCode,
        },
      });

      setInStockItems(data.result.map((i) => ({ label: i.msn, value: i.msn })));
    } catch (error) {
      console.log(error);
    }
  };

  const getBundles = async () => {
    try {
      const { data } = await get(URLS.bundle.list, {
        params: {
          search: { inStock: true },
          warehouseId: entryFormik.values.warehouseCode,
          ownerCode: entryFormik.values.ownerCode,
        },
      });
      setInStockBundles(
        data.result
          ?.filter(
            (b) => b.items.length > 0 && !b.items.some((i) => !i.inStock)
          )
          .map((d) => ({
            label: d.bundleReferenceNumber,
            value: d.bundleReferenceNumber,
          }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (entryFormik.values.warehouseCode) {
      getItems();
      getBundles();
    }
  }, [entryFormik.values.warehouseCode]);

  //customform
  const getFormDetails = async () => {
    try {
      const { data } = await get(URLS.forms.read, {
        params: { collectionName: "pickList" },
      });
      setFormFields(data.result ? data.result.fields : null);
    } catch (error) {
      console.log(error);
    }
  };
  // ownerData
  const getOwnerData = async () => {
    try {
      const { data } = await get(URLS.ownerGroup.list);
      setOwnerData(
        data.result.map((l) => ({
          label: l.name,
          value: l._id,
          ownerCodes: l.ownerCodes.map((code) => ({
            label: code.name,
            value: code._id,
          })),
        }))
      );
    } catch (error) {
      console.error(error);
    }
  };

  const getData = async () => {
    try {
      const stockPromise = get(URLS.stockType.list);
      const subFamilyPromise = get(URLS.subFamily.list);
      const storageCodePromise = get(URLS.storageCode.list);
      const palletCodeCodePromise = get(URLS.palletCode.list);
      const brandPromise = get(URLS.brand.list);

      const [
        stockTypeData,
        subFamilyData,
        storageCodeData,
        palletCodeData,
        brandData,
      ] = await Promise.all([
        stockPromise,
        subFamilyPromise,
        storageCodePromise,
        palletCodeCodePromise,
        brandPromise,
      ]);
      setStockTypes(
        stockTypeData?.data?.result?.map((s) => ({
          ...s,
          label: s.name,
          value: s._id,
        }))
      );

      setBrands(
        brandData?.data?.result?.map((b) => ({
          ...b,
          label: b.name,
          value: b._id,
        }))
      );

      setSubFamilies(
        subFamilyData.data.result.map((s) => ({
          ...s,
          label: s.name,
          value: s._id,
        }))
      );
      setStorageCodes(
        storageCodeData.data.result.map((s) => ({
          ...s,
          label: s.code,
          value: s._id,
        }))
      );
      setPalletCodes(
        palletCodeData.data.result.map((s) => ({
          ...s,
          label: s.code,
          value: s._id,
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  //warehouseData
  const getWarehouseData = async () => {
    try {
      const { data } = await get(URLS.warehouse.list);
      setWarehouseData(data.result);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getFormDetails();
    getOwnerData();
    getWarehouseData();
    getData();
  }, []);

  const handleOnFieldAdd = () => {
    getFormDetails();
  };

  const handleChange = async (e, index) => {
    const { name, value } = e.target;

    if (formData.filter((fd) => fd[name] === value).length)
      return toast.error("Cannot add items multiple times");

    setFormData(
      formData.map((f, i) => (i == index ? { ...f, [name]: value } : f))
    );

    if (name === "msn") {
      try {
        const { data } = await get(URLS.items.list, {
          params: {
            search: {
              inStock: true,
              inBundle: false,
              msn: value,
            },
          },
        });

        if (data.result.length) {
          let item = data.result[0];
          setInStockItems(inStockItems.filter((i) => i.value !== item.msn));
          setFormData(
            formData.map((f, i) =>
              i == index
                ? {
                    ...f,
                    _id: item._id,
                    category: item.itemCategory.name,
                    stockType: item.stockType.name,
                    msn: item.msn,
                    bundleReferenceNumber: "",
                    subFamilyName: item.subFamily.name,
                    itemDescription: item.itemDescription,
                    itemCode: item.itemCode,
                    manufacturerPartNumber: item.manufacturerPartNumber,
                    brandName: item.brandName.name,
                    storageCode: item.storageCode.code,
                    palletCode: item.palletCode.code,
                  }
                : f
            )
          );
        } else {
          setFormData(
            formData.map((f, i) =>
              i == index
                ? {
                    _id: "",
                    category: "",
                    stockType: "",
                    msn: value,
                    bundleReferenceNumber: "",
                    subFamilyName: "",
                    itemDescription: "",
                    itemCode: "",
                    manufacturerPartNumber: "",
                    brandName: "",
                    storageCode: "",
                    palletCode: "",
                  }
                : f
            )
          );
        }
      } catch (error) {
        console.log(error);
      }
    } else if (name === "bundleReferenceNumber" && value) {
      try {
        const { data } = await get(URLS.bundle.list, {
          params: { search: { bundleReferenceNumber: value } },
        });
        if (data.result.length) {
          let bundle = data.result[0];
          setInStockBundles(
            inStockBundles.filter(
              (i) => i.value !== bundle.bundleReferenceNumber
            )
          );
          setFormData(
            formData.map((f, i) =>
              i == index
                ? {
                    ...f,
                    _id: bundle._id,
                    stockType: bundle.stockType.name,
                    msn: bundle.items.map((i) => i.msn).join(","),
                    bundleReferenceNumber: bundle.bundleReferenceNumber,
                    subFamilyName: bundle.items
                      .map(
                        (i) =>
                          subFamilies.find((s) => s._id == i.subFamily._id)
                            ?.name
                      )
                      .join(","),
                    itemDescription: bundle.items
                      .map((i) => i.itemDescription)
                      .join(","),
                    itemCode: bundle.items.map((i) => i.itemCode).join(","),
                    manufacturerPartNumber: bundle.items
                      .map((i) => i.manufacturerPartNumber)
                      .join(","),
                    brandName: bundle.items
                      .map(
                        (i) =>
                          brands.find((b) => b._id == i.brandName._id)?.name
                      )
                      .join(","),
                    storageCode: bundle.storageCode.code,
                    palletCode: bundle.palletCode.code,
                  }
                : f
            )
          );
        } else {
          setFormData(
            formData.map((f, i) =>
              i == index
                ? {
                    category: "Bundled",
                    stockType: "",
                    msn: "",
                    bundleReferenceNumber: value,
                    subFamilyName: "",
                    itemDescription: "",
                    itemCode: "",
                    manufacturerPartNumber: "",
                    brandName: "",
                    storageCode: "",
                    palletCode: "",
                  }
                : f
            )
          );
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleRefreshValue = async (e, index) => {
    const { name, value } = e.target;

    setFormData(
      formData.map((f, i) => (i === index ? { ...f, [name]: "" } : f))
    );

    if (name === "msn") {
      setInStockItems([...inStockItems, { label: value, value }]);
    }

    if (name === "bundleReferenceNumber") {
      setInStockBundles([...inStockBundles, { label: value, value }]);
    }
  };

  const handleCustomInputChange = (e) => {
    const { name, value } = e.target;
    setFormFields(
      formFields.map((f) => (f.inputLabel === name ? { ...f, value } : f))
    );
  };

  const handleEditClick = (data) => {
    setEntryToEdit(data);
    setSelectValue(2);
    console.log(data);
  };

  const handleDelete = async (id) => {
    try {
      const res = await del(URLS.pickList.delete + "/" + id);
      getAllPickList();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      {/* <PageHeader
        title="Pick List"
        showAdd={false}
        collection="Pick List"
        modelName="pickList"
        onFieldAdd={handleOnFieldAdd}
        // showAddField={!entryDone}
        showAddField={false}
        module="Pick-list"
      /> */}
      <DashBoardPageHeaderPage title="Pick List" />
      <TabList
        onChange={handleTabChange}
        value={value}
        labels={
          entryToEdit && value === 2
            ? ["Add", "Entries", "View"]
            : ["Add", "Entries"]
        }
      />
      <TabPanel index={0} value={value}>
        {!entryDone ? (
          <>
            <Box component="form">
              <Grid rowSpacing={1} columnSpacing={2} container>
                <Grid xs={12} sm={6} md={4} item>
                  <FormInput
                    type="text"
                    formik={entryFormik}
                    name="gmsPickListCode"
                    label="Gms Picklist code"
                    required={true}
                  />
                </Grid>
                <Grid xs={12} sm={6} md={4} item>
                  <FormDatePicker
                    formik={entryFormik}
                    label="Pick List Date"
                    name="pickListDate"
                  />
                </Grid>
                <Grid xs={12} sm={6} md={4} item>
                  <FormSelect
                    formik={entryFormik}
                    label="Warehouse code"
                    name="warehouseCode"
                    options={warehouseData.map((warehouse) => ({
                      label: warehouse.code,
                      value: warehouse._id,
                    }))}
                    required={true}
                    onChange={(e) => {
                      const selectedValue = e.target.value;
                      const selectedWarehouse = warehouseData.find(
                        (warehouse) => warehouse._id === selectedValue
                      );
                      if (selectedWarehouse) {
                        entryFormik.setFieldValue(
                          "warehouseCode",
                          selectedWarehouse._id
                        );
                        entryFormik.setFieldValue(
                          "warehouseName",
                          selectedWarehouse.name
                        );
                      }
                    }}
                  />
                </Grid>
                <Grid xs={12} sm={6} md={4} item>
                  <FormInput
                    type="text"
                    formik={entryFormik}
                    name="warehouseName"
                    label="Warehouse name"
                    required={true}
                  />
                </Grid>
                <Grid xs={12} sm={6} md={4} item>
                  <FormInput
                    type="text"
                    formik={entryFormik}
                    name="bundle"
                    label="Bundle"
                  />
                </Grid>
                <Grid xs={12} sm={6} md={4} item>
                  <FormInput
                    type="text"
                    formik={entryFormik}
                    name="sku"
                    label="SKU"
                  />
                </Grid>
                <Grid xs={12} sm={6} md={4} item>
                  <FormSelect
                    formik={entryFormik}
                    name="ownerGroup"
                    label="Owner Group"
                    options={ownerData}
                    required={true}
                  />
                </Grid>
                <Grid xs={12} sm={6} md={4} item>
                  <FormSelect
                    type="text"
                    formik={entryFormik}
                    name="ownerCode"
                    label="Owner code"
                    options={
                      ownerData.find(
                        (owner) => owner.value === entryFormik.values.ownerGroup
                      )?.ownerCodes || []
                    }
                    required={true}
                  />
                </Grid>
                <Grid xs={12} sm={6} md={4} item>
                  <FormInput
                    type="text"
                    formik={entryFormik}
                    name="ownerCategory"
                    label="Owner category"
                    required={true}
                  />
                </Grid>
                <Grid xs={12} sm={6} md={4} item>
                  <FormInput
                    type="text"
                    formik={entryFormik}
                    name="remarks"
                    label="Pick List Remarks"
                    required={true}
                  />
                </Grid>

                {formFields
                  ? formFields?.map((f, i) => (
                      <Grid key={i} item xs={12} sm={6} md={4}>
                        <CustomInput
                          onChange={handleCustomInputChange}
                          name={f.inputLabel}
                          value={f.value}
                          required={f.isRequired}
                          label={f.inputLabel}
                          type={f.inputType}
                        />
                      </Grid>
                    ))
                  : null}
                <Grid xs={12} sm={6} md={4} item>
                  <FileSelect
                    selectedFiles={entryFiles}
                    setSelectedFiles={setEntryFiles}
                    name="entryFiles"
                  />
                </Grid>
              </Grid>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  margin: "20px 0",
                }}
              >
                <Button
                  onClick={() => {
                    if (!entryFormik.values.bundle) {
                      if (!hasAllValues(entryFormik.values, ["bundle"])) return;
                    } else if (!entryFormik.values.sku) {
                      if (!hasAllValues(entryFormik.values, ["sku"])) return;
                    }

                    setEntryDone(true);
                  }}
                  variant="contained"
                  size="small"
                >
                  Next
                </Button>
              </Box>
            </Box>
          </>
        ) : (
          <>
            <TableContainer component={Paper}>
              <Table>
                <TableHead
                  sx={{
                    backgroundColor: (theme) =>
                      theme.palette.mode === "dark"
                        ? theme.palette.primary.dark
                        : theme.palette.primary.light,
                  }}
                >
                  <TableRow>
                    <TableCell align="center" sx={{ minWidth: "80px" }}>
                      S.No
                    </TableCell>
                    <StyledTableCell align="center">Category</StyledTableCell>
                    <StyledTableCell align="center">MSN</StyledTableCell>

                    {
                      <StyledTableCell align="center">
                        Bundle code refrence no
                      </StyledTableCell>
                    }
                    <StyledTableCell align="center">Stock Type</StyledTableCell>

                    <StyledTableCell align="center">
                      Sub-family name
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      Item description
                    </StyledTableCell>
                    <StyledTableCell align="center">Item code</StyledTableCell>
                    <StyledTableCell align="center">
                      Manufacturer part number
                    </StyledTableCell>
                    <StyledTableCell align="center">Brand name</StyledTableCell>
                    <StyledTableCell align="center">
                      Storage code
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      Pallet code
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {formData.map((v, i) => (
                    <TableRow key={i}>
                      <TableCell align="center" sx={{ minWidth: "80px" }}>
                        {i + 1}
                      </TableCell>
                      <StyledTableCell align="center">
                        <Typography>{v.category} </Typography>
                      </StyledTableCell>
                      {v.category !== "Bundled" ? (
                        <StyledTableCell align="center">
                          {v.msn ? (
                            <Button
                              onClick={(e) =>
                                handleRefreshValue(
                                  { target: { name: "msn", value: v.msn } },
                                  i
                                )
                              }
                              endIcon={<Refresh fontSize="large" />}
                              variant="outlined"
                              size="small"
                              fullWidth
                              sx={{
                                textAlign: "center",
                                textTransform: "none",
                                height: "40px",
                              }}
                            >
                              {v.msn}
                            </Button>
                          ) : (
                            <CustomSelect
                              options={inStockItems}
                              disabled={
                                v.category && v.category === "Bundled"
                                  ? true
                                  : false
                              }
                              name="msn"
                              value={v.msn}
                              label="MSN"
                              required={true}
                              onChange={(e) => handleChange(e, i)}
                            />
                          )}
                        </StyledTableCell>
                      ) : (
                        <StyledTableCell align="center">
                          {v.msn}
                        </StyledTableCell>
                      )}
                      {v.category === "Bundled" ? (
                        <StyledTableCell>
                          {v.bundleReferenceNumber ? (
                            <Button
                              onClick={(e) =>
                                handleRefreshValue(
                                  {
                                    target: {
                                      name: "bundleReferenceNumber",
                                      value: v.bundleReferenceNumber,
                                    },
                                  },
                                  i
                                )
                              }
                              endIcon={<Refresh fontSize="large" />}
                              variant="outlined"
                              size="small"
                              fullWidth
                              sx={{
                                textAlign: "center",
                                textTransform: "none",
                                height: "40px",
                              }}
                            >
                              {v.bundleReferenceNumber}
                            </Button>
                          ) : (
                            <CustomSelect
                              options={inStockBundles}
                              disabled={v.category !== "Bundled" ? true : false}
                              name="bundleReferenceNumber"
                              value={v.bundleReferenceNumber}
                              label="Bundle code refrence no"
                              required={true}
                              onChange={(e) => handleChange(e, i)}
                            />
                          )}
                        </StyledTableCell>
                      ) : (
                        <StyledTableCell></StyledTableCell>
                      )}
                      <StyledTableCell align="center">
                        <Typography>{v.stockType} </Typography>
                      </StyledTableCell>

                      <StyledTableCell align="center">
                        <Typography>{v.subFamilyName} </Typography>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Typography>{v.itemDescription} </Typography>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Typography>{v.itemCode} </Typography>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Typography>{v.manufacturerPartNumber} </Typography>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Typography>{v.brandName} </Typography>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Typography>{v.storageCode} </Typography>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Typography>{v.palletCode} </Typography>
                      </StyledTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <Box
              sx={{
                width: "100%",
                display: "flex",
                my: 2,
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Stack sx={{ alignSelf: "flex-end" }} direction="row" spacing={2}>
                <Button
                  size="small"
                  variant="outlined"
                  onClick={() => setEntryDone(false)}
                >
                  Back
                </Button>
                <LoadingButton
                  loading={addingPickist}
                  size="small"
                  variant="contained"
                  onClick={debouceSubmit}
                >
                  Save
                </LoadingButton>
              </Stack>
            </Box>
          </>
        )}
      </TabPanel>
      <TabPanel index={1} value={value}>
        <CustomTable
          actions={["delete", "view"]}
          bodyDataModal="entries"
          tableKeys={pickListProcessKeys}
          bodyData={pickLists}
          isDataReady={true}
          onDeleteClick={handleDelete}
          onViewClick={handleEditClick}
          module="Pick-list"
        />
      </TabPanel>
      <TabPanel index={2} value={value}>
        <UpdatePickList
          dataToEdit={entryToEdit}
          setSelectValue={setSelectValue}
        />
      </TabPanel>
    </>
  );
}
