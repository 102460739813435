export const pickListProcessKeys = [
  {
    name: "Gms PickList Code",
    key: "gmsPickListCode",
  },
  {
    name: "PickList Date",
    key: "createdAt",
  },

  {
    name: "Used for Outbound",
    key: "used",
  },

  {
    name: "Warehouse code",
    path: "warehouseCode",
    key: "code",
  },
];
