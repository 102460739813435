export const subFamilyKeys = [
  {
    name: "Name",
    key: "name",
  },
  {
    name: "Description",
    key: "description",
  },
  {
    name: "Type",
    key: "category",
  },
];
