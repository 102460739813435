export const courierModeKeys = [
  {
    name: "Name",
    key: "name",
  },
  {
    name: "Divison Factor",
    key: "divisionFactor",
  },
  {
    name: "Description",
    key: "description",
  },
];
