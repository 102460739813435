import React, { useContext, useEffect, useState } from "react";
import { get } from "../services/apiMethods";
import URLS from "../services/urlConstants";
import { Box, Grid } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ShowComponent from "./ShowComponent";
import { LoadingButton } from "@mui/lab";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import { downloadExcel, separateAtCapital } from "../utils/utilities";
import WarehouseContext from "../context/WarehouseContext";
import CustomInput from "./SecondaryForms/CustomInput";
import FormDatePicker from "../forms/FormDatePicker";
import { useFormik } from "formik";
import dayjs from "dayjs";

export default function ShipmentReport() {
  const { selectedWarehouse, selectedOwnerCode } = useContext(WarehouseContext);
  const [awbNo, setAwbNo] = useState("");
  const [keys, setKeys] = useState([]);

  const getKeys = async () => {
    try {
      const { data } = await get(URLS.outbound.getAllKeys);
      const itemKeys = data.result.itemKeys.map((i) => ({
        key: i,
        checked: true,
        keyOf: "item",
      }));
      const inboundKeys = data.result.inboundKeys.map((i) => ({
        key: i,
        checked: true,
        keyOf: "inbound",
      }));
      const outboundKeys = data.result.outboundKeys.map((i) => ({
        key: i,
        checked: true,
        keyOf: "outbound",
      }));
      setKeys([...itemKeys, ...inboundKeys, ...outboundKeys]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getKeys();
  }, []);

  const handleToggle = (key) => {
    setKeys(
      keys.map((i) => (i.key === key ? { ...i, checked: !i.checked } : i))
    );
  };

  const handleDownloadReport = async (values) => {
    try {
      const { data } = await get(URLS.outbound.downloadShipmentReport, {
        params: {
          ...keys,
          warehouseId: selectedWarehouse,
          ownerCode: selectedOwnerCode,
          awbNo,
          ...values,
        },
        responseType: "blob",
      });
      downloadExcel(data, "Shipping_Report");
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangeAwbNo = (e) => {
    setAwbNo(e.target.value);
  };

  const entryFormik = useFormik({
    initialValues: {
      name: "",
      fromDate: dayjs(new Date()),
      toDate: dayjs(new Date()),
    },
    onSubmit: handleDownloadReport,
  });

  return (
    <Box>
      <Grid rowSpacing={1} columnSpacing={2} container>
        <Grid xs={12} sm={6} md={3} item>
          <FormDatePicker
            formik={entryFormik}
            label="From Date"
            name="fromDate"
          />
        </Grid>
        <Grid xs={12} sm={6} md={3} item>
          <FormDatePicker formik={entryFormik} label="To Date" name="toDate" />
        </Grid>
        <Grid
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
          xs={12}
          sm={6}
          md={3}
          item
        >
          {keys.length ? (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                alignSelf: "center",
              }}
            >
              <ShowComponent module={"Reports"} action={"view"}>
                {" "}
                <LoadingButton
                  onClick={entryFormik.handleSubmit}
                  variant="contained"
                  size="small"
                  sx={{ top: 6 }}
                >
                  Download Excel
                </LoadingButton>
              </ShowComponent>
            </Box>
          ) : null}
        </Grid>
      </Grid>
      <List
        sx={{
          width: "100%",
          bgcolor: "background.paper",
        }}
      >
        <Grid container>
          {keys.map((value, index) => {
            const labelId = `checkbox-list-label-${value.key}`;

            return (
              <Grid key={value.key + index.toString()} item xs={12} sm={6}>
                <ListItem disablePadding>
                  <ListItemButton
                    role={undefined}
                    onClick={() => handleToggle(value.key)}
                    // dense
                  >
                    {/* <ListItemIcon> */}
                    <Checkbox
                      edge="start"
                      checked={value.checked}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ "aria-labelledby": labelId }}
                    />
                    {/* </ListItemIcon> */}
                    <ListItemText
                      id={labelId}
                      primary={`${separateAtCapital(value.key || "")
                        .toUpperCase()
                        .replace(" ", "_")}`}
                    />
                  </ListItemButton>
                </ListItem>
              </Grid>
            );
          })}
        </Grid>
      </List>
    </Box>
  );
}
