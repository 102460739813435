import React, { useState } from "react";
import { Box, Button, Modal, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";

// icons
// services
import { put } from "../services/apiMethods";
import URLS from "../services/urlConstants";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  p: 2,
};

export default function ConfirmationModal({
  open,
  setOpen,
  title,
  description,
  item,
  onAddToStock = () => {},
}) {
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (id) => {
    setLoading(true);
    try {
      const payload = {
        ...item,
        inStock: true,
      };
      const { data } = await put(URLS.items.addToStock + "/" + id, payload);
      onAddToStock();
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <Typography
            textAlign="center"
            sx={{ fontSize: "18px", fontWeight: 700 }}
          >
            Add Item to Stock
          </Typography>
          <Typography sx={{ mt: 2, textAlign: "center" }}>
            Are you sure want to add this item to stock. This action will only
            add this item to stock and not change any modified property in this
            row item.
          </Typography>
          <Box
            my={3}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "15px",
            }}
          >
            <Button
              size="small"
              color="error"
              variant="contained"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <LoadingButton
              loading={loading}
              color="primary"
              size="small"
              variant="contained"
              onClick={() => handleSubmit(item?._id)}
            >
              Yes, I am Sure
            </LoadingButton>
          </Box>
        </Box>
      </Modal>
    </>
  );
}
