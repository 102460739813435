export const employeeTableKeys = [
  {
    name: "Name",
    key: "name",
  },
  {
    name: "Contact Number",
    key: "contactNumber",
  },
  {
    name: "Email",
    key: "email",
  },
  {
    name: "Role",
    key: "name",
    path: "role",
  },
];
