import React, { useContext, useEffect, useState } from "react";
import { useFormik } from "formik";
import dayjs from "dayjs";
import {
  Box,
  Button,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  styled,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { toast } from "react-toastify";

// custom components
import PageHeader from "../../components/PageHeader";
import TabList from "../../components/Tabs/TabList";
import FormInput from "../../forms/FormInput";
import FormDatePicker from "../../forms/FormDatePicker";
import FormSelect from "../../forms/FormSelect";
import TabPanel from "../../components/Tabs/TabPanel";
import CustomTable from "../../components/Tables/CustomTable";
import CustomSelect from "../../components/SecondaryForms/CustomSelect";
import CustomInput from "../../components/SecondaryForms/CustomInput";
import FileSelect from "../../forms/FileSelect";
import UpdateNewItem from "./UpdateNewItem";

// services
import { get, post, del } from "../../services/apiMethods";
import URLS from "../../services/urlConstants";

//data
import { inboundEntriesTableKeys } from "../../data/TableData/inboundEntries";

import WarehouseContext from "../../context/WarehouseContext";

// utilitie
import {
  generateRandomNumber,
  hasAllValues,
  hasDuplicates,
} from "../../utils/utilities";
import DashBoardPageHeaderPage from "../../components/DashBoard/DashBoardHeaderPage";

const StyledTableCell = styled(TableCell)(() => ({
  minWidth: "200px",
}));

export default function New() {
  const { selectedWarehouse, selectedOwnerCode } = useContext(WarehouseContext);
  const [value, setSelectValue] = useState(0);
  const [formData, setFormData] = useState([]);
  const [inboundEntries, setInboundEntries] = useState([]);
  const [addingEntry, setAddingEntry] = useState(false);
  const [entryToEdit, setEntryToEdit] = useState(null);
  const [entryDone, setEntryDone] = useState(false);
  const [stockTypes, setStockTypes] = useState([]);
  const [families, setFamilies] = useState([]);
  const [subFamilies, setSubFamilies] = useState([]);
  const [storageCodes, setStorageCodes] = useState([]);
  const [palletCodes, setPalletCodes] = useState([]);
  const [currentConditionCodes, setCurrentConditionCodes] = useState([]);

  const [ownerData, setOwnerData] = useState([]);
  const [warehouseData, setWarehouseData] = useState([]);
  const [workFlowData, setWorkFlowData] = useState([]);
  const [brandData, setBrandData] = useState([]);
  const [itemCategoryData, setItemCategoryData] = useState([]);
  const [entryFiles, setEntryFiles] = useState([]);

  // custom fields
  const [formFields, setFormFields] = useState(null);
  const [itemFormFields, setItemFormFields] = useState(null);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getInboundEntries = async () => {
    try {
      const { data } = await get(URLS.inboundEntry.list, {
        params: {
          search: { entryMenu: "New" },
          warehouseId: selectedWarehouse,
          ownerCode: selectedOwnerCode,
        },
      });
      setInboundEntries(data.result);
    } catch (error) {
      console.log(error);
    }
  };

  const handleNextClick = () => {
    if (!hasAllValues(entryFormik.values, ["receivedDate", "images"])) return;
    if (entryFormik.values)
      if (formFields) {
        for (let field of formFields) {
          if (field.isRequired && !field.value) {
            return toast.error(
              `Please fill the required field : ${field.inputLabel}`
            );
          }
        }
      }
    setEntryDone(true);
  };

  // form subbmission
  const handleSubmit = async (values) => {
    for (let item of formData) {
      if (!hasAllValues(item, ["images", "meta", "remarks", "configurationOs"]))
        return;
    }

    if (formData.some((fd) => fd.meta.some((f) => f.isRequired && !f.value)))
      return toast.error("Please fill required fields of this item");

    const msnList = formData.map((fd) => fd.msn);
    if (hasDuplicates(msnList))
      return toast.error("No two items can have the same MSN value!");

    setAddingEntry(true);
    const newFormData = new FormData();

    const receivedDate = dayjs(new Date(values.receivedDate)).toISOString();
    const incomingDate = dayjs(new Date(values.incomingDate)).toISOString();

    // Parse the date string into a Date object
    const date = new Date(receivedDate);
    const date2 = new Date(incomingDate);

    // Get the current time
    const currentTime = new Date();

    // Set the time part of the date to the current time
    date.setHours(currentTime.getHours());
    date.setMinutes(currentTime.getMinutes());
    date.setSeconds(currentTime.getSeconds());

    date2.setHours(currentTime.getHours());
    date2.setMinutes(currentTime.getMinutes());
    date2.setSeconds(currentTime.getSeconds());

    newFormData.append(
      "inboundEntry",
      JSON.stringify({
        ...values,
        receivedDate: values.receivedDate ? date : null,
        incomingDate: date2,
        meta: formFields,
      })
    );

    newFormData.append("items", JSON.stringify(formData));
    formData.forEach((item) => {
      item.images.forEach((file, index) => {
        newFormData.append(`${item.msn}`, file);
      });
    });

    // Append each image file
    entryFiles.forEach((file, index) => {
      newFormData.append(`entryFiles`, file);
    });

    try {
      const { data } = await post(URLS.inboundEntry.create, newFormData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      setSelectValue(1);
      setEntryFiles([]);
      setEntryDone(false);
      entryFormik.resetForm();
      getInboundEntries();
    } catch (error) {
      console.log(error);
    }

    setAddingEntry(false);
  };

  const entryFormik = useFormik({
    initialValues: {
      gmsReferenceCode: `GMS${generateRandomNumber(10)}`,
      entryMenu: "New", // . Used ||  Faulty
      receivedDate: null,
      warehouseCode: "",
      warehouseName: "",
      incomingDate: dayjs(new Date(Date.now())),
      receivedDocketNumber: "NA",
      eWayBillNumber: "NA",
      incomingInvoiceNumber: "NA",
      dcNumber: "NA",
      poNumber: "NA",
      numberOfBoxes: "",
      sku: "",
      ownerGroup: "",
      ownerCode: "",
      ownerCategory: "NA",
      workflowCode: "",
      remarks: "NA",
    },
    onSubmit: handleSubmit,
    validateOnBlur: false,
  });

  // modified  items table
  useEffect(() => {
    const formData = [];
    const newCategoryId = itemCategoryData.find(
      (i) => i.name.toLowerCase() == "new"
    )?._id;

    let id = 1;
    for (let index = 0; index < entryFormik.values.sku; index++) {
      formData.push({
        id: id,
        itemCategory: newCategoryId,
        stockType: "",
        msn: "",
        subFamily: "",
        itemDescription: "",
        itemCode: "",
        manufacturerPartNumber: "",
        brandName: "",
        storageCode: "",
        palletCode: "",
        conditionCode: "",
        configurationOs: "",
        family: "",
        remarks: "",
        images: [],
        meta: itemFormFields || [],
      });

      id += 1;
    }
    setPage(0);
    setFormData(formData);
  }, [entryFormik.values.sku, itemFormFields, itemCategoryData]);

  //customform
  const getFormDetails = async () => {
    try {
      const { data } = await get(URLS.forms.read, {
        params: { collectionName: "inboundEntry" },
      });
      // console.log(data.result, "kkkk");
      setFormFields(data.result ? data.result.fields : null);
    } catch (error) {
      console.log(error);
    }
  };

  const getItemFormDetails = async () => {
    try {
      const { data } = await get(URLS.forms.read, {
        params: { collectionName: "item" },
      });
      setItemFormFields(data.result ? data.result.fields : null);
    } catch (error) {
      console.log(error);
    }
  };

  const getData = async () => {
    try {
      const stockPromise = get(URLS.stockType.list);
      const familyPromise = get(URLS.family.list);
      const subFamilyPromise = get(URLS.subFamily.list);
      const storageCodePromise = get(URLS.storageCode.list);
      const palletCodeCodePromise = get(URLS.palletCode.list);
      const currentConditionCodePromise = get(URLS.conditionCode.list);
      const itemCategoryPromise = get(URLS.itemCategory.list);
      const ownerDataPromise = get(URLS.ownerGroup.list);
      const brandDataPromise = get(URLS.brand.list);
      const warehouseDataPromise = get(URLS.warehouse.list);
      const workflowDataPromise = get(URLS.workFlowCode.list);

      const [
        stockTypeData,
        familyData,
        subFamilyData,
        storageCodeData,
        palletCodeData,
        currentConditionCodeData,
        itemCategoryData,
        ownerData,
        brandData,
        warehouseData,
        workflowData,
      ] = await Promise.all([
        stockPromise,
        familyPromise,
        subFamilyPromise,
        storageCodePromise,
        palletCodeCodePromise,
        currentConditionCodePromise,
        itemCategoryPromise,
        ownerDataPromise,
        brandDataPromise,
        warehouseDataPromise,
        workflowDataPromise,
      ]);

      setWorkFlowData(
        workflowData?.data?.result.map((w) => ({
          ...w,
          label: `${w.code}-${w.description}`,
          value: w._id,
        }))
      );

      setWarehouseData(
        warehouseData?.data?.result.map((w) => ({
          ...w,
          label: w.name,
          value: w._id,
        }))
      );

      setBrandData(
        brandData?.data?.result.map((w) => ({
          ...w,
          label: w.name,
          value: w._id,
        }))
      );

      setOwnerData(
        ownerData?.data?.result.map((l) => ({
          label: l.name,
          value: l._id,
          ownerCodes: l.ownerCodes.map((code) => ({
            label: code.name,
            value: code._id,
          })),
        }))
      );
      setStockTypes(
        stockTypeData?.data?.result?.map((s) => ({
          ...s,
          label: s.name,
          value: s._id,
        }))
      );
      setFamilies(
        familyData.data.result.map((s) => ({
          ...s,
          label: s.name,
          value: s._id,
        }))
      );
      setSubFamilies(
        subFamilyData.data.result.map((s) => ({
          ...s,
          label: s.name,
          value: s._id,
        }))
      );
      setStorageCodes(
        storageCodeData.data.result.map((s) => ({
          ...s,
          label: s.code,
          value: s._id,
        }))
      );
      setPalletCodes(
        palletCodeData.data.result.map((s) => ({
          ...s,
          label: s.code,
          value: s._id,
        }))
      );
      setCurrentConditionCodes(
        currentConditionCodeData.data.result.map((s) => ({
          ...s,
          label: `${s.code}-${s.description}`,
          value: s._id,
        }))
      );

      setItemCategoryData(
        itemCategoryData.data.result.map((s) => ({
          ...s,
          label: s.name,
          value: s._id,
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getData();
    getFormDetails();
    getInboundEntries();
    getItemFormDetails();
  }, [selectedOwnerCode, selectedWarehouse]);

  const handleOnFieldAdd = () => {
    getData();
    getFormDetails();
  };

  const handleOnFieldAddItem = () => {
    getItemFormDetails();
  };

  const handleTabChange = (e, newValue) => setSelectValue(newValue);

  const handleChange = (e, id, repeat) => {
    const { name, value } = e.target;
    if (repeat) {
      setFormData(
        formData.map((fd, i) => (fd.id >= id ? { ...fd, [name]: value } : fd))
      );
    } else {
      setFormData(
        formData.map((fd, i) => (fd.id === id ? { ...fd, [name]: value } : fd))
      );
    }
  };

  // added new function
  const handleRemoveFile = (fileName, id) => {
    let index = formData.indexOf(formData.find((fdc) => fdc.id === id));
    let files = formData[index].images.filter((f) => f.name !== fileName);
    setFormData(
      formData.map((fd, i) => (fd.id === id ? { ...fd, images: files } : fd))
    );
  };

  const handleChangeFiles = (e, id) => {
    const { files } = e.target;

    let fileList = [];

    if (files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];

        fileList.push(file);
      }

      setFormData(
        formData.map((fd, i) =>
          fd.id === id ? { ...fd, images: fileList } : fd
        )
      );
    } else {
      console.log("No files selected");
    }
  };

  // edit data actions
  const handleCustomInputChange = (e) => {
    const { name, value } = e.target;

    setFormFields(
      formFields.map((f) => (f.inputLabel === name ? { ...f, value } : f))
    );
  };

  const handleCustomInputChangeItem = (e, id) => {
    const { name, value } = e.target;
    setFormData(
      formData.map((f, index) =>
        f.id >= id
          ? {
              ...f,
              meta: f.meta.map((m) =>
                m.inputLabel === name ? { ...m, value } : m
              ),
            }
          : f
      )
    );
  };

  // table edit actions
  const handleEditClick = (data) => {
    setEntryToEdit(data);
    setSelectValue(2);
  };
  const handleDelete = async (id) => {
    try {
      const res = await del(URLS.inboundEntry.delete + "/" + id);
      const filtered = inboundEntries.filter((item) => item._id !== id);
      setInboundEntries(filtered);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <DashBoardPageHeaderPage title="Inbound Entry" />
      {entryDone ? (
        <PageHeader
          title="Add Items"
          showAdd={false}
          collection="New item"
          modelName="item"
          onFieldAdd={handleOnFieldAddItem}
          module="Inbound process"
          showAddField={value != 2}
        />
      ) : (
        <PageHeader
          title="New Entry"
          showAdd={false}
          collection="New item"
          modelName="inboundEntry"
          onFieldAdd={handleOnFieldAdd}
          module="Inbound process"
          showAddField={value != 2}
        />
      )}

      <TabList
        onChange={handleTabChange}
        value={value}
        labels={
          entryToEdit && value === 2
            ? ["Add", "Entries", "Edit"]
            : ["Add", "Entries"]
        }
      />
      <TabPanel index={0} value={value}>
        {!entryDone ? (
          <>
            <Box component="form">
              <Grid rowSpacing={1} columnSpacing={2} container>
                <Grid xs={12} sm={6} md={4} item>
                  <FormInput
                    type="text"
                    formik={entryFormik}
                    name="gmsReferenceCode"
                    label="Gms Reference Code"
                    required={true}
                  />
                </Grid>
                <Grid xs={12} sm={6} md={4} item>
                  <FormDatePicker
                    formik={entryFormik}
                    label="Received Date"
                    name="receivedDate"
                  />
                </Grid>

                <Grid xs={12} sm={6} md={4} item>
                  <FormSelect
                    formik={entryFormik}
                    label="Warehouse code"
                    name="warehouseCode"
                    options={warehouseData.map((warehouse) => ({
                      label: warehouse.code,
                      value: warehouse._id,
                    }))}
                    required={true}
                    onChange={(e) => {
                      const selectedValue = e.target.value;
                      const selectedWarehouse = warehouseData.find(
                        (warehouse) => warehouse._id === selectedValue
                      );
                      if (selectedWarehouse) {
                        entryFormik.setFieldValue(
                          "warehouseCode",
                          selectedWarehouse._id
                        );
                        entryFormik.setFieldValue(
                          "warehouseName",
                          selectedWarehouse.name
                        );
                      }
                    }}
                  />
                </Grid>
                <Grid xs={12} sm={6} md={4} item>
                  <FormInput
                    type="text"
                    formik={entryFormik}
                    name="warehouseName"
                    disabled={true}
                    label="Warehouse name"
                    required={true}
                  />
                </Grid>
                <Grid xs={12} sm={6} md={4} item>
                  <FormDatePicker
                    required={true}
                    formik={entryFormik}
                    label="Incoming Date"
                    name="incomingDate"
                  />
                </Grid>
                <Grid xs={12} sm={6} md={4} item>
                  <FormInput
                    type="text"
                    formik={entryFormik}
                    name="receivedDocketNumber"
                    label="Received docket number"
                    required={true}
                  />
                </Grid>
                <Grid xs={12} sm={6} md={4} item>
                  <FormInput
                    type="text"
                    formik={entryFormik}
                    name="eWayBillNumber"
                    label="E-Way Bill number"
                    required={true}
                  />
                </Grid>
                <Grid xs={12} sm={6} md={4} item>
                  <FormInput
                    type="text"
                    formik={entryFormik}
                    name="incomingInvoiceNumber"
                    label="Incoming invoice number"
                    required={true}
                  />
                </Grid>
                <Grid xs={12} sm={6} md={4} item>
                  <FormInput
                    type="text"
                    formik={entryFormik}
                    name="dcNumber"
                    label="DC number"
                    required={true}
                  />
                </Grid>

                <Grid xs={12} sm={6} md={4} item>
                  <FormInput
                    type="text"
                    formik={entryFormik}
                    name="poNumber"
                    label="PO number"
                    required={true}
                  />
                </Grid>

                <Grid xs={12} sm={6} md={4} item>
                  <FormInput
                    type="number"
                    formik={entryFormik}
                    name="numberOfBoxes"
                    label="Number of boxes"
                    required={true}
                  />
                </Grid>

                <Grid xs={12} sm={6} md={4} item>
                  <FormInput
                    type="number"
                    formik={entryFormik}
                    name="sku"
                    label="SKU"
                    required={true}
                  />
                </Grid>

                <Grid xs={12} sm={6} md={4} item>
                  <FormSelect
                    formik={entryFormik}
                    name="ownerGroup"
                    label="Owner Group"
                    options={ownerData}
                    required={true}
                  />
                </Grid>
                <Grid xs={12} sm={6} md={4} item>
                  <FormSelect
                    type="text"
                    formik={entryFormik}
                    name="ownerCode"
                    label="Owner code"
                    options={
                      ownerData.find(
                        (owner) => owner.value === entryFormik.values.ownerGroup
                      )?.ownerCodes || []
                    }
                    required={true}
                  />
                </Grid>
                <Grid xs={12} sm={6} md={4} item>
                  <FormInput
                    type="text"
                    formik={entryFormik}
                    name="ownerCategory"
                    label="Owner category"
                    required={true}
                  />
                </Grid>
                <Grid xs={12} sm={6} md={4} item>
                  <FormSelect
                    type="text"
                    formik={entryFormik}
                    name="workflowCode"
                    label="Workflow code"
                    required={true}
                    options={workFlowData}
                  />
                </Grid>
                <Grid xs={12} sm={6} md={4} item>
                  <FormInput
                    type="text"
                    formik={entryFormik}
                    name="remarks"
                    label="Remarks"
                  />
                </Grid>
                {formFields
                  ? formFields?.map((f, i) => (
                      <Grid key={i} item xs={12} sm={6} md={4}>
                        <CustomInput
                          onChange={handleCustomInputChange}
                          name={f.inputLabel}
                          value={f.value}
                          required={f.isRequired}
                          label={f.inputLabel}
                          type={f.inputType}
                        />
                      </Grid>
                    ))
                  : null}
                <Grid xs={12} sm={6} md={4} item>
                  <FileSelect
                    selectedFiles={entryFiles}
                    setSelectedFiles={setEntryFiles}
                    name="entryFiles"
                  />
                </Grid>
              </Grid>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  margin: "20px 0",
                }}
              >
                <Button
                  onClick={handleNextClick}
                  variant="contained"
                  size="small"
                >
                  Next
                </Button>
              </Box>
            </Box>
          </>
        ) : (
          <>
            <TableContainer component={Paper}>
              <Table>
                <TableHead
                  sx={{
                    backgroundColor: (theme) =>
                      theme.palette.mode === "dark"
                        ? theme.palette.primary.dark
                        : theme.palette.primary.light,
                  }}
                >
                  <TableRow>
                    <TableCell sx={{ minWidth: "80px" }} align="center">
                      S.No
                    </TableCell>
                    <StyledTableCell align="center">
                      Item Category
                    </StyledTableCell>
                    <StyledTableCell align="center">Stock Type</StyledTableCell>
                    <StyledTableCell align="center">MSN</StyledTableCell>
                    <StyledTableCell align="center">
                      Sub-family name
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      Item description
                    </StyledTableCell>
                    <StyledTableCell align="center">Item code</StyledTableCell>
                    <StyledTableCell align="center">
                      Manufacturer part number
                    </StyledTableCell>
                    <StyledTableCell align="center">Brand name</StyledTableCell>
                    <StyledTableCell align="center">
                      Storage code
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      Pallet code
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      Current condition code
                    </StyledTableCell>

                    <StyledTableCell align="center">
                      Configuration OS
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      Family name
                    </StyledTableCell>
                    {itemFormFields?.map((d, i) => (
                      <StyledTableCell key={i} align="center">
                        {d?.inputLabel}
                      </StyledTableCell>
                    ))}
                    <StyledTableCell align="center">Remarks</StyledTableCell>
                    <StyledTableCell sx={{ minWidth: "200px" }} align="center">
                      Image
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {formData
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((fd, i) => (
                      <TableRow key={fd.id}>
                        <TableCell sx={{ minWidth: "80px" }} align="center">
                          {fd.id}
                        </TableCell>
                        <StyledTableCell align="center">
                          <CustomSelect
                            disabled={true}
                            options={itemCategoryData}
                            name="itemCategory"
                            label="Item Category"
                            value={fd.itemCategory}
                            required={true}
                            onChange={(e) => handleChange(e, fd.id, "repeat")}
                          />
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <CustomSelect
                            options={stockTypes}
                            name="stockType"
                            label="Stock Type"
                            value={fd.stockType}
                            required={true}
                            onChange={(e) => handleChange(e, fd.id, "repeat")}
                          />
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <CustomInput
                            name="msn"
                            value={fd.msn}
                            label="MSN"
                            required={true}
                            onChange={(e) => handleChange(e, fd.id)}
                          />
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <CustomSelect
                            onChange={(e) => handleChange(e, fd.id, "repeat")}
                            options={subFamilies}
                            name="subFamily"
                            label="Sub-Family Name"
                            value={fd.subFamily}
                            required={true}
                          />
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <CustomInput
                            name="itemDescription"
                            value={fd.itemDescription}
                            label="Item Description"
                            required={true}
                            onChange={(e) => handleChange(e, fd.id, "repeat")}
                          />
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <CustomInput
                            name="itemCode"
                            value={fd.itemCode}
                            label="Item Code"
                            required={true}
                            onChange={(e) => handleChange(e, fd.id, "repeat")}
                          />
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <CustomInput
                            name="manufacturerPartNumber"
                            value={fd.manufacturerPartNumber}
                            label="Manufacturer Part Number"
                            required={true}
                            onChange={(e) => handleChange(e, fd.id, "repeat")}
                          />
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <CustomSelect
                            options={brandData}
                            name="brandName"
                            label="Brand Name"
                            value={fd.brandName}
                            required={true}
                            onChange={(e) => handleChange(e, fd.id, "repeat")}
                          />
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <CustomSelect
                            options={storageCodes.filter(
                              (s) =>
                                s.warehouse?._id ===
                                entryFormik.values.warehouseCode
                            )}
                            name="storageCode"
                            label="Storage Code"
                            value={fd.storageCode}
                            required={true}
                            onChange={(e) => handleChange(e, fd.id, "repeat")}
                          />
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <CustomSelect
                            options={palletCodes.filter(
                              (s) =>
                                s.warehouse?._id ===
                                entryFormik.values.warehouseCode
                            )}
                            name="palletCode"
                            label="Pallet Code"
                            value={fd.palletCode}
                            required={true}
                            onChange={(e) => handleChange(e, fd.id, "repeat")}
                          />
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <CustomSelect
                            options={currentConditionCodes}
                            name="conditionCode"
                            label="Current Condition Code"
                            value={fd.conditionCode}
                            required={true}
                            onChange={(e) => handleChange(e, fd.id, "repeat")}
                          />
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <CustomInput
                            name="configurationOs"
                            value={fd.configurationOs}
                            label="Configuration OS"
                            // required={true}
                            onChange={(e) => handleChange(e, fd.id, "repeat")}
                          />
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <CustomSelect
                            options={families}
                            name="family"
                            label="Family Name"
                            value={fd.family}
                            required={true}
                            onChange={(e) => handleChange(e, fd.id, "repeat")}
                          />
                        </StyledTableCell>
                        {itemFormFields
                          ? fd.meta?.map((f, index) => (
                              <StyledTableCell align="center" key={index}>
                                <CustomInput
                                  onChange={(e) =>
                                    handleCustomInputChangeItem(e, fd.id)
                                  }
                                  name={f.inputLabel}
                                  value={f.value}
                                  required={f.isRequired}
                                  label={f.inputLabel}
                                  type={f.inputType}
                                />
                              </StyledTableCell>
                            ))
                          : null}
                        <StyledTableCell align="center">
                          <CustomInput
                            name="remarks"
                            value={fd.remarks}
                            label="Remarks"
                            onChange={(e) => handleChange(e, fd.id, "repeat")}
                          />
                        </StyledTableCell>

                        <StyledTableCell align="center">
                          <FileSelect
                            name={`images${i}`}
                            onChange={(e) => handleChangeFiles(e, fd.id)}
                            customOnChange={true}
                            selectedFiles={fd.images}
                            onRemove={(fileName) =>
                              handleRemoveFile(fileName, fd.id)
                            }
                          />
                        </StyledTableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              count={formData ? (formData.length ? formData.length : 0) : 0}
              page={page}
              rowsPerPageOptions={[10]}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />

            <Box
              sx={{
                width: "100%",
                display: "flex",
                my: 2,
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Stack sx={{ alignSelf: "flex-end" }} direction="row" spacing={2}>
                <Button
                  size="small"
                  variant="outlined"
                  onClick={() => setEntryDone(false)}
                >
                  Back
                </Button>
                <LoadingButton
                  loading={addingEntry}
                  size="small"
                  variant="contained"
                  onClick={entryFormik.handleSubmit}
                >
                  Save
                </LoadingButton>
              </Stack>
            </Box>
          </>
        )}
      </TabPanel>
      <TabPanel index={1} value={value}>
        <CustomTable
          actions={["edit", "delete"]}
          bodyDataModal="entries"
          tableKeys={inboundEntriesTableKeys}
          isDataReady={true}
          bodyData={inboundEntries}
          onEditClick={handleEditClick}
          onDeleteClick={handleDelete}
          module="Inbound process"
        />
      </TabPanel>
      <TabPanel index={2} value={value}>
        <UpdateNewItem
          dataToEdit={entryToEdit}
          setSelectValue={setSelectValue}
          setEntryToEdit={setEntryToEdit}
          onUpdate={getInboundEntries}
          brandData={brandData}
          currentConditionCodes={currentConditionCodes}
          families={families}
          itemCategoryData={itemCategoryData}
          ownerData={ownerData}
          palletCodes={palletCodes}
          stockTypes={stockTypes}
          storageCodes={storageCodes}
          subFamilies={subFamilies}
          warehouseData={warehouseData}
          workFlowData={workFlowData}
        />
      </TabPanel>
    </>
  );
}
