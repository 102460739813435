export const bundleTypeKeys = [
  {
    name: "Code",
    key: "code",
  },
  {
    name: "Description",
    key: "description",
  },
];
