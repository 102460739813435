import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { Grid } from "@mui/material";

// custom imports
import CustomInput from "../../components/SecondaryForms/CustomInput";
import PageHeader from "../../components/PageHeader";
import FormDialog from "../../forms/FormDialog";
import CustomTable from "../../components/Tables/CustomTable";
import FormInput from "../../forms/FormInput";

// services
import { del, get, post, put } from "../../services/apiMethods";
import URLS from "../../services/urlConstants";

//data
import { updateStatusKeys } from "../../data/TableData/UpdateStatus";

export default function UpdateStatus() {
  const [isReady, setIsReady] = useState(false);
  const [open, setOpen] = useState(false);
  const [dataToEdit, setDataToEdit] = useState(null);
  const [data, setData] = useState([]);
  const [formFields, setFormFields] = useState(null);
  const [loading, setLoading] = useState(false);

  const getData = async () => {
    try {
      const { data } = await get(URLS.updateStatus.list);
      setData(data.result);
    } catch (error) {
      console.log(error);
    }
    setTimeout(() => {
      setIsReady(true);
    }, 300);
  };

  const getFormDetails = async () => {
    try {
      const { data } = await get(URLS.forms.read, {
        params: { collectionName: "updateStatus" },
      });
      setFormFields(data.result ? data.result.fields : null);
    } catch (error) {
      console.log(error);
    }
  };

  // get data on page load
  useEffect(() => {
    getData();
    getFormDetails();
  }, []);

  const handleOnFieldAdd = () => {
    getData();
    getFormDetails();
  };

  // create || update actions
  const handleCreateOrUpdate = async (values) => {
    try {
      const payload = {
        ...values,
        meta: formFields,
      };

      setLoading(true);
      if (dataToEdit) {
        const { data } = await put(
          URLS.updateStatus.update + "/" + dataToEdit._id,
          payload
        );
      } else {
        const { data } = await post(URLS.updateStatus.create, payload);
      }

      getData();
      handleModalClose();
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const formik = useFormik({
    initialValues: {
      name: dataToEdit?.name || "",
      description: dataToEdit?.description || "",
    },
    onSubmit: handleCreateOrUpdate,
    enableReinitialize: true,
  });

  // modal actions
  const openDataAddOrUpdateModal = () => setOpen(true);

  const handleModalClose = () => {
    setOpen(false);
    setTimeout(() => {
      setDataToEdit(null);
      setFormFields(
        formFields?.map((f) => ({
          ...f,
          value: "",
        }))
      );
      formik.resetForm();
    }, 200);
  };

  // edit data actions
  const handleCustomInputChange = (e) => {
    const { name, value } = e.target;
    setFormFields(
      formFields.map((f) => (f.inputLabel === name ? { ...f, value } : f))
    );
  };

  const handleSetDataToEdit = (data) => {
    setDataToEdit(data);
    const fields = data?.meta;
    setFormFields(
      formFields?.map((f) => ({
        ...f,
        value:
          fields?.find((fi) => fi.inputLabel === f.inputLabel)?.value || "",
      }))
    );

    setOpen(true);
  };

  const handleDelete = async (id) => {
    try {
      const res = await del(URLS.updateStatus.delete + "/" + id);
      getData();
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <PageHeader
        collection="Update Status"
        onAddClick={openDataAddOrUpdateModal}
        title="Update Status"
        modelName="updateStatus"
        onFieldAdd={handleOnFieldAdd}
        module="Update status"
      />

      <CustomTable
        actions={["edit", "delete"]}
        bodyDataModal="update status"
        collection="Update Status"
        bodyData={data}
        tableKeys={updateStatusKeys}
        onDeleteClick={handleDelete}
        onEditClick={handleSetDataToEdit}
        module="Update status"
        formFields={formFields}
      />

      <FormDialog
        open={open}
        onClose={handleModalClose}
        submitButtonTitle={dataToEdit ? "Update" : "Add"}
        formik={formik}
        formTitle={dataToEdit ? "Update update status" : "Add update status"}
        adding={loading}
      >
        <Grid container rowSpacing={1} columnSpacing={2}>
          <Grid item xs={12} sm={6}>
            <FormInput
              required={true}
              name="name"
              type="text"
              formik={formik}
              label="Name"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormInput
              name="description"
              type="text"
              formik={formik}
              label="Description"
            />
          </Grid>
          {formFields
            ? formFields?.map((f, i) => (
                <Grid key={i} item xs={12} sm={6}>
                  <CustomInput
                    onChange={handleCustomInputChange}
                    name={f.inputLabel}
                    value={f.value}
                    required={f.isRequired}
                    label={f.inputLabel}
                    type={f.inputType}
                  />
                </Grid>
              ))
            : null}
        </Grid>
      </FormDialog>
    </>
  );
}
