import React from "react";
import { Box } from "@mui/material";
import CustomTable from "../../components/Tables/CustomTable";
import PageHeader from "../../components/PageHeader";

export default function ProcessPickList() {
  return (
    <>
      <PageHeader
        module="Pick-list"
        title="New Items"
        showAdd={false}
        collection="New item"
      />
    </>
  );
}
